import { Injectable } from '@angular/core';
import { ApiCallService } from './api-call.service';
import { Observable, forkJoin } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr'; 
@Injectable({
  providedIn: 'root'
})
export class CvServiceService {

  constructor(
    private _apiCall: ApiCallService,
    private toastr: ToastrService 
  ) { }

  // Method to fetch all the details in one call
  public getAllCvDetails(): Observable<any> {
    return forkJoin({
      personalDetails: this._apiCall.GetCallWithToken("AdmissionLeloUsers/GetTeacherPersonalDetails").pipe(
        catchError((error) => this.handleError(error, 'Personal Details'))
      ),
      languageDetails: this._apiCall.GetCallWithToken("AdmissionLeloUsers/GetTeacherLanguages").pipe(
        catchError((error) => this.handleError(error, 'Languages'))
      ),
      experienceDetails: this._apiCall.GetCallWithToken("AdmissionLeloUsers/GetTeacherExperience").pipe(
        catchError((error) => this.handleError(error, 'Experience'))
      ),
      educationDetails: this._apiCall.GetCallWithToken("AdmissionLeloUsers/GetTeacherEducation").pipe(
        catchError((error) => this.handleError(error, 'Education'))
      ),
      certificateDetails: this._apiCall.GetCallWithToken("AdmissionLeloUsers/GetTeacherCertificate").pipe(
        catchError((error) => this.handleError(error, 'Certificates'))
      ),
      socialLinksDetails: this._apiCall.GetCallWithToken("AdmissionLeloUsers/GetTeacherSocialLinks").pipe(
        catchError((error) => this.handleError(error, 'Social Links'))
      ),
      awardsDetails: this._apiCall.GetCallWithToken("AdmissionLeloUsers/GetTeacherAwards").pipe(
        catchError((error) => this.handleError(error, 'Awards'))
      ),
      subjectDetails: this._apiCall.GetCallWithToken("AdmissionLeloUsers/GetTeacherSubject").pipe(
        catchError((error) => this.handleError(error, 'Subjects'))
      ),
      skillsDetails: this._apiCall.GetCallWithToken("AdmissionLeloUsers/GetTeacherSkill").pipe(
        catchError((error) => this.handleError(error, 'Skills'))
      )
    });
  }

  // Handle error and show toast message
  private handleError(error: any, apiName: string) {
    this.toastr.error(`Error in ${apiName} API`, 'API Error');
    return [];
  }
}
