import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-manage-cv',
  templateUrl: './manage-cv.component.html',
  styleUrl: './manage-cv.component.scss'
})
export class ManageCvComponent {
  public themeLogo = 'assets/images/logo/2.png';
  public footerLogo = 'assets/images/logo/footer-logo.png';
  public bgImage = 'assets/images/banner/banner.webp';
  public title = 'Dashboard';
  public parent = 'Cv';
  public child = 'Add Cv';
  public isSpinnerShown = false;
  gridImages: boolean = false;
  listView: boolean = false;
  thumbnail: boolean = false;
  thumbnail_video: boolean = false;
  tagClass: string;
  total: number;
  public selectedImage: string;
  isDataLoading: boolean = true;
  selectedCvId: string;

  templates = [
    {
      templateId: 1,
      image_url: "../../../../../assets/images/cv-images/cv1.png",
      isPaid: false

    },
    {
      templateId: 2,
      image_url: "../../../../../assets/images/cv-images/cv2.png",
      isPaid: false
    },
    {
      templateId: 3,
      image_url: "../../../../../assets/images/cv-images/cv3.png",
      isPaid: false
    },
    {
      templateId: 4,
      image_url: "../../../../../assets/images/cv-images/pic2.jpg",
      isPaid: false
    },
    // {
    //   templateId: 5,
    //   image_url: "../../../../../assets/images/cv-images/pic3.jpg",
    //   isPaid: false
    // },

  ]

  constructor(private sanitizer: DomSanitizer, private router: Router) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.isDataLoading = false;
    }, 1500);
  }

  sanitizeImageUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  // This method will navigate to the CV template with the selected templateId
  useTemplate(templateId: number) {
    this.router.navigate([`/cv-templates/cv/${templateId}`]);
  }


  selectCv(cvId: string) {
    this.selectedCvId = cvId;
  }

}
