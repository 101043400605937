<!-- <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="var(--mainColor)" type="ball-spin-clockwise"
  [fullScreen]="true">
  <p style="font: size 20px; color: var(--mainColor)">Loading...</p>
</ngx-spinner> -->
<app-loader-two [isLoading]="isSpinnerShown"></app-loader-two>
<app-header-one></app-header-one>
<app-breadcrumb [bgImage]="bgImage" [title]="title" [parent]="parent" [child]="child"></app-breadcrumb>

<section class="user-dashboard small-section">
  <div class="container">
    <div class="row">
      <div class="col-lg-3">
        <div class="sidebar-user sticky-cls">
          <div class="user-profile">
            <app-user-info></app-user-info>
          </div>
          <div class="dashboard-list">
            <app-user-panel-side-menu [activeTab]="'add-books'"></app-user-panel-side-menu>
          </div>
        </div>
      </div>
      <div class="col-lg-9">
        <div class="dashboard-content">
          <div class="payment-user" id="payment">
            <div class="common-card">
              <div class="common-header">
                <h5>Library</h5>
              </div>
              <div class="row card-payment">
                @if (isDataLoading) {
                <div class="item">
                  <ngx-skeleton-loader count="3" [theme]="{
                      height: '22.5rem',
                      width: '30%',
                      'border-radius': '10px',
                      'margin-right': '0.5rem',
                      'margin-left': '0.5rem'
                    }" />
                </div>
                }@else{ @for(data of booksData; track data){
                <div class="col-xl-4 col-sm-6">
                  <!-- //ultra new cards  -->

                  <div class="property-box payment-card">
                    <div class="property-image">
                      <!-- <owl-carousel-o [options]="Options">
                                        @for(images of data?.img; track images){
                                        @if(images?.fileType === 'image'){
                                        <ng-template carouselSlide>
                                            <div class="property-slider" [class]="tagClass">
                                                <a href="javascript:void(0)" class="bg-size"
                                                    [style.background-image]="'url('+images?.url+')'">
                                                    <img [src]="images?.url" class="bg-img" alt="property-image"
                                                        style="display: none;" />
                                                </a>
                                            </div>
                                        </ng-template>
                                        }
                                        }
                                    </owl-carousel-o> -->

                      <div class="property-slider" [class]="tagClass">
                        <div href="javascript:void(0)" class="bg-size" [style.background-image]="
                            'url(' + data?.imageBase64 + ')'
                          ">
                          <img [src]="booksData?.imageBase64" class="bg-img" alt="property-image"
                            style="display: none" />
                        </div>
                      </div>
                    </div>
                    <div class="property-details">
                      <a href="javascript:void(0)">
                        <h3 class="line-clamp">{{ data?.title }}</h3>
                        <p class="clamped-text">{{ data?.bookDescription }}</p>
                      </a>
                      <ul>
                        <li>
                          <app-feather-icons class="color" [icon]="'user'"></app-feather-icons>{{ data?.authorName }}
                        </li>
                      </ul>
                    </div>
                    <div class="edit-card">
                      <a href="javascript:void(0)" (click)="editCard(AddOrEditModal, data?.id)">edit</a>
                      <a href="javascript:void(0)" (click)="deleteCard(deleteModal,data?.id)">delete</a>
                    </div>
                  </div>
                </div>
                } }
                <div class="col-xl-4 col-sm-6">
                  <div class="payment-card add-card">
                    <div class="card-details" (click)="addCard(AddOrEditModal)">
                      <div>
                        <i class="fas fa-plus-circle"></i>
                        <h5>Add new Book</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-adly-footer></app-adly-footer>

<ng-template #AddOrEditModal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">
        @if(FormName == "Add"){ Add New Book }@else{ Update Book }
      </h5>
      <button type="button" class="btn-close" (click)="modal.dismissAll()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="addBookForm">
        <div class="row gx-3">
          <div class="form-group col-6 col-sm-4">
            <label for="a-na">Book Title<span class="text-danger">*</span></label>
            <input type="text" class="form-control" id="a-na" placeholder="Enter Book Title"
              formControlName="book_title" />
            @if(addBookForm.get('book_title')?.hasError('required') &&
            addBookForm.get('book_title')?.touched){
            <small class="text-danger">Title is Required</small>
            }
          </div>
          <div class="form-group col-6 col-sm-4">
            <label for="a-na">Book Edition<span class="text-danger">*</span></label>
            <input type="text" class="form-control" id="a-na" placeholder="Enter Book Edition"
              formControlName="book_edition" />
            @if(addBookForm.get('book_edition')?.hasError('required') &&
            addBookForm.get('book_edition')?.touched){
            <small class="text-danger">Edition is Required</small>
            }
          </div>
          <div class="form-group col-6 col-sm-4">
            <label for="a-na">Publisher<span class="text-danger">*</span></label>
            <input type="text" class="form-control" id="a-na" placeholder="Enter Publisher Name"
              formControlName="publisher" />
            @if(addBookForm.get('publisher')?.hasError('required') &&
            addBookForm.get('publisher')?.touched){
            <small class="text-danger">Publisher is Required</small>
            }
          </div>
          <div class="form-group col-6 col-sm-4">
            <label for="application-deadline">Publish Date<span class="text-danger">*</span></label>
            <input type="date" class="form-control" id="application-deadline" formControlName="publish_date"
              max="{{ getMaxDate() }}" />
            @if(addBookForm.get('publish_date')?.hasError('required') &&
            addBookForm.get('publish_date')?.touched) {
            <small class="text-danger">Publish Date is required</small>
            }
          </div>
          <div class="form-group col-6 col-sm-4">
            <label for="a-na">Language<span class="text-danger">*</span></label>
            <app-common-dropdowns class="first-dropdown" [defaultText]="'Enter Language Type'" [dataList]="languageList"
              [selectedOption]="Language" [clearThevalue]="Language" (optionSelected)="onLanguageSelected($event)"
              (sendTheValue)="onLanguageValueChange($event)"></app-common-dropdowns>
            @if(Language == "" && languageError){
            <small class="text-danger">Language is Required</small>
            }
          </div>
          <!-- </div>
        <div class="row gx-3"> -->

          <div class="form-group col-6 col-sm-4">
            <label for="a-na">Book Category<span class="text-danger">*</span></label>
            <app-common-dropdowns class="second-dropdown" [defaultText]="'Enter Book Category'"
              [dataList]="CategoryList" [selectedOption]="bookCategory" [clearThevalue]="bookCategory"
              (optionSelected)="onCategorySelected($event)"
              (sendTheValue)="SearchTermCategory($event)"></app-common-dropdowns>
            @if(bookCategory == "" && categoryError){
            <small class="text-danger"> Category is Required</small>
            }
          </div>



          <!-- </div>
        <div class="row gx-3"> -->
          <div class="form-group col-6 col-sm-4">
            <label for="a-na">ISBN Number<span class="text-danger">*</span></label>
            <input type="text" class="form-control" id="a-na" placeholder="Enter ISBN Number"
              formControlName="ISBN_Number" />
            @if(addBookForm.get('ISBN_Number')?.hasError('required') &&
            addBookForm.get('ISBN_Number')?.touched){
            <small class="text-danger">ISBN Number is Required</small>
            }
          </div>
          <div class="form-group col-12 col-sm-4">
            <label for="a-n">Authors<span class="text-danger">*</span></label>
            <input type="text" class="form-control" id="a-n" placeholder="Add Authors Name"
              formControlName="authors_name" (keyup.enter)="onEnterAuthor($event)" />
            @if(authorTags.length == 0 &&
            addBookForm.get('authors_name')?.touched){
            <small class="text-danger">Add atleast one Authors Name</small>
            } @for(authorTag of authorTags; track authorTag){
            <div class="tag" (click)="removeAuthorTags(authorTag)">
              {{ authorTag.author }}
              <span class="close-icon">&times;</span>
            </div>
            }
          </div>
          <div class="form-group col-12 col-sm-4">
            <label for="a-n">Keywords or Tags<span class="text-danger">*</span></label>
            <input type="text" class="form-control" id="a-n" placeholder="Add Keywords" formControlName="keywords"
              (keyup.enter)="onEnterKeywords($event)" />
            @if(keywordTags.length == 0 &&
            addBookForm.get('keywords')?.touched){
            <small class="text-danger">Add atleast one Keywords</small>
            } @for(keywordTag of keywordTags; track keywordTag){
            <div class="tag" (click)="removeKeywordsTags(keywordTag)">
              {{ keywordTag.keyWord }}
              <span class="close-icon">&times;</span>
            </div>
            }
          </div>
        </div>

        <div class="row gx-3">
          <div class="form-group col-12">
            <label for="a-na">Description<span class="text-danger">*</span></label>

            <textarea rows="4" name="" id="" class="form-control" placeholder="Enter Description"
              formControlName="description" (input)="updateCharCount()"></textarea>
            <small class="">{{ charCount }}/400</small>
            @if(addBookForm.get('description')?.hasError('required') &&
            addBookForm.get('description')?.touched){
            <small class="text-danger">Description is Required</small>
            }
          </div>
        </div>
      </form>
      <!-- PDF Upload Section -->
      <div class="dropzone-admin mb-0" *ngIf="FormName === 'Add'">
        <label>Media (PDF) <span class="font-danger">*</span></label>
        <form class="dropzone" id="pdfUpload">
          <ngx-dropzone (change)="onPdfSelect($event)" accept=".pdf" [multiple]="false">
            <ngx-dropzone-label>
              <div class="dz-message needsclick">
                <i class="fas fa-cloud-upload-alt"></i>
                <h6 class="f-w-700 mb-0">Drop PDF here or click to upload.</h6>
              </div>
            </ngx-dropzone-label>

            <!-- PDF Preview Section with Custom Class -->
            <ngx-dropzone-image-preview *ngIf="pdfFile" ngProjectAs="ngx-dropzone-preview" [removable]="true"
              (removed)="onRemovePdf()" [file]="pdfFile" class="filePreview pdf-preview-container">
              <ngx-dropzone-label>
                <span id="fileName">{{ pdfFile.name }}</span>
                <app-feather-icons [icon]="'file-text'"></app-feather-icons>
                <br />
                <span>PDF</span>
              </ngx-dropzone-label>
            </ngx-dropzone-image-preview>
          </ngx-dropzone>
        </form>
      </div>

      <!-- Image (Cover Photo) Upload Section -->
      <div class="dropzone-admin mb-0" *ngIf="FormName === 'Add'">
        <label>Media (Cover Photo) <span class="text-danger">*</span></label>
        <form class="dropzone" id="imageUpload">
          <ngx-dropzone (change)="onImageSelect($event)" accept="image/jpeg,image/jpg,image/png" [multiple]="false">
            <ngx-dropzone-label>
              <div class="dz-message needsclick">
                <i class="fas fa-cloud-upload-alt"></i>
                <h6 class="f-w-700 mb-0">
                  Drop files here or click to upload an image.
                </h6>
              </div>
            </ngx-dropzone-label>
            <ngx-dropzone-image-preview *ngIf="coverImageFile" ngProjectAs="ngx-dropzone-preview" [removable]="true"
              (removed)="onRemoveImage()" [file]="coverImageFile">
              <ngx-dropzone-label>{{ coverImageFile.name }} ({{
                coverImageFile.type
                }})</ngx-dropzone-label>
            </ngx-dropzone-image-preview>
          </ngx-dropzone>
        </form>

        <!-- For Edit Case -->

      </div>




      <!-- update case bk image -->

      <div class="dropzone-admin mb-0" *ngIf="FormName === 'Edit'">
        <label>Media (Cover Photo)<span class="text-danger">*</span></label>
        @if(coverImageBase64 != null){
        <div class="image-gallery">
          <div class="image-container">
            <app-feather-icons [icon]="'x'" class="closeImageIcon" (click)="removeImageFile()"></app-feather-icons>
            <img [src]="coverImageBase64" alt="image" />
          </div>
        </div>
        }
        @if(coverImageBase64 == null){
        <div class="image-gallery">
          <div class="upload-container" (click)="fileInput.click()">
            <app-feather-icons [icon]="'upload'"></app-feather-icons>
            <input #fileInput type="file" style="display: none" accept="image/jpeg,image/jpg,image/png"
              (change)="onImageFileSelected($event)" />
            Upload Cover Image
          </div>
        </div>
        }
      </div>


      <!-- update case bk file -->

      <div class="dropzone-admin mb-0" *ngIf="FormName === 'Edit'">
        <label>Media (PDF) <span class="text-danger">*</span></label>
        @if(pdfFileBase64 != null){
        <div class="pdf-preview">
          <!-- <div class="image-container"> -->
          <!-- <app-feather-icons [icon]="'x'" class="closeImageIcon" (click)="removeFile()"></app-feather-icons> -->
          <!-- <img [src]="coverImageBase64" alt="image" /> -->
          <!-- <iframe [src]="pdfFileBase64" class="FilePreview" frameborder="0"
          height="500px"
          width="400px"
          ></iframe> -->
          <!-- </div> -->
           <div class="tag" (click)="getPdfbyid()">
            Preview PDF
          </div>
        </div>
        }
        @if(pdfFileBase64 == null){
        <div class="image-gallery">
          <div class="upload-container" (click)="fileInput.click()">
            <app-feather-icons [icon]="'upload'"></app-feather-icons>
            <input #fileInput type="file" style="display: none" accept=".pdf" (change)="onPDFFileSelected($event)" />
            Upload More Book
          </div>
        </div>
        }
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-dashed color-2 btn-pill" (click)="modal.dismissAll()">
        Cancel
      </button>
      <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="onSubmit()">
        @if(FormName == "Add"){ Add Book }@else{ Update Book }
      </button>
    </div>
  </div>
</ng-template>

<ng-template #deleteModal>
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="btn-close" (click)="modal.dismissAll()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="text">
        Are You Sure you want to delete this Book ?
      </div>
      <div class="deleteButtons">
        <button type="button" class="btn btn-dashed color-2 btn-pill" (click)="modal.dismissAll()">Cancel</button>
        <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="deleteBook()">
          Delete
        </button>
      </div>
    </div>
  </div>
</ng-template>