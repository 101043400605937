import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';

@Component({
  selector: 'app-social-links',
  templateUrl: './social-links.component.html',
  styleUrl: './social-links.component.scss',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class SocialLinksComponent implements OnInit, OnDestroy {
  // private destroy$ = new Subject<void>();

  socialLinksForm: FormGroup;
  public isSpinnerShown = false;
  private _apiCall = inject(ApiCallService);
  private _toaster = inject(ToastrService);
  private readonly _spinner = inject(NgxSpinnerService);
  private readonly commonUtilsService = inject(CommonUtilsServiceService);
  private readonly errorHandlingService = inject(ErrorHandlingService);
  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef) {
    this.socialLinksForm = this.fb.group({
      facebook: ['', [Validators.pattern(/^https?:\/\/(?:www\.)?facebook\.com\/.*$/)]],
      twitter: ['', [Validators.pattern(/^https?:\/\/(?:www\.)?twitter\.com\/.*$/)]],
      linkedin: ['', [Validators.pattern(/^https?:\/\/(?:www\.)?linkedin\.com\/.*$/)]],
      instagram: ['', [Validators.pattern(/^https?:\/\/(?:www\.)?instagram\.com\/.*$/)]]
    });
  }




  ngOnInit(): void {
    this.getTeachearSocialLinks();
  }

  ngOnDestroy(): void {
    // this.destroy$.next();
    // this.destroy$.complete();
  }


  public saveSocialLinks(): void {
    const isdataMatch = this.matchPayload();
    if (isdataMatch) {
        this._toaster.success("Already Uptodate");
        return;
    }
    if (this.socialLinksForm.invalid) {
        this._toaster.error("Invalid Form");
        return;
    }

    this.isSpinnerShown = true;
    const payload = this.createApiPayload();
    this._apiCall.PostCallWithToken(payload, 'AdmissionLeloUsers/SaveTeacherSocialLinks')
        .subscribe(
            (response) => {
                if (response.responseCode === 200) {
                    const userDetailsString = localStorage.getItem("userDetails");
                    if (userDetailsString) {
                        const userDetails = JSON.parse(userDetailsString);
                        userDetails.socialLinks = this.socialLinksDetails;
                        localStorage.setItem("userDetails", JSON.stringify(userDetails));
                    }
                    this.commonUtilsService.notifyUserDetailsUpdated();
                    this._toaster.success(response.responseMessage);
                    this.socialLinksForm.reset();
                    this.getTeachearSocialLinks();
                } else {
                    this.errorHandlingService.handleResponseError(response);
                }
                this.isSpinnerShown = false;
            },
            (error) => {
                this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
                this.isSpinnerShown = false;
            }
        );
}

  createApiPayload() {
    return {
      id: this.socailLinkId == 0 ? 0 : this.socailLinkId,
      // id: 0,
      facebook: this.socialLinksForm.get('facebook')?.value,
      twitter: this.socialLinksForm.get('twitter')?.value,
      linkedin: this.socialLinksForm.get('linkedin')?.value,
      instagram: this.socialLinksForm.get('instagram')?.value
    };
  }


  matchPayload() {
    const oldPayload = this.socialLinksDetails;
    const newPayload = this.createApiPayload();
    ;
    const apipayload = {
      id: oldPayload?.id,
      facebook: oldPayload?.facebook,
      twitter: oldPayload?.twitter,
      linkedin: oldPayload?.linkedin,
      instagram: oldPayload?.instagram
    };

    return JSON.stringify(apipayload) === JSON.stringify(newPayload);
  }

  socailLinkId: number = 0;
  socialLinksDetails: any;

 public getTeachearSocialLinks(): void {
    this._apiCall.GetCallWithToken("AdmissionLeloUsers/GetTeacherSocialLinks")
        .subscribe(
            (res) => {
                if (res.responseCode === 200) {
                    this.socialLinksDetails = res.data;
                    const socialLinks = res.data;
                    if (socialLinks?.id) {
                        this.socailLinkId = socialLinks?.id;
                    }
                    this.socialLinksForm.get('facebook')?.setValue(socialLinks?.facebook);
                    this.socialLinksForm.get('twitter')?.setValue(socialLinks?.twitter);
                    this.socialLinksForm.get('linkedin')?.setValue(socialLinks?.linkedin);
                    this.socialLinksForm.get('instagram')?.setValue(socialLinks?.instagram);

                    const userDetailsString = localStorage.getItem("userDetails");
                    if (userDetailsString) {
                        const userDetails = JSON.parse(userDetailsString);
                        userDetails.socialLinks = this.socialLinksDetails;
                        localStorage.setItem("userDetails", JSON.stringify(userDetails));
                    }

                    this.commonUtilsService.notifyUserDetailsUpdated();
                }
            },
            (error) => {
                this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
            }
        );
}
}
