import { Component, Input } from '@angular/core';
import { addPropertyStepsData } from 'src/app/shared/data/add-property';

@Component({
  selector: 'app-cv-data',
  templateUrl: './cv-data.component.html',
  styleUrl: './cv-data.component.scss'
})
export class CvDataComponent {
  public noRecordFound = false;
  public isDataLoading = true;
  isErrorOccurred: boolean = false;
  public isSpinnerShown = false;
  gridType: string = 'grid-3';

  @Input() totalData: number;
  public title = 'slider_filter_search';
  bannerImage: string = 'assets/images/banner/all-institute-banner.webp';


  public theme_default3 = '#ff5c41';
  public theme_default4 = '#ff8c41';

  ngOnInit() {
    document.documentElement.style.setProperty('--theme-default', this.theme_default3);
    document.documentElement.style.setProperty('--theme-default3', this.theme_default3);
    document.documentElement.style.setProperty('--theme-default4', this.theme_default4);
  }

  ngOnDestroy(): void {
    document.documentElement.style.removeProperty('--theme-default');
    document.documentElement.style.removeProperty('--theme-default3');
    document.documentElement.style.removeProperty('--theme-default4');
  }
}
