<div class="mainContainer">
    <div class="headers">
      <h6>Experience</h6>
      <div></div>
      <div class="addHeadings" (click)="addCard(addExperience)">
        <app-feather-icons class="color" [icon]="'plus'"></app-feather-icons>
  
        <span> Experience </span>
      </div>
    </div>
    @defer {
    <div>
      @if(isLoading){
      <div class="item">
        <ngx-skeleton-loader count="6" [theme]="{
            height: '40px',
            width: '150px',
            'border-radius': '10px',
            'margin-right': '1rem'
          }" />
      </div>
      } @else if(experienceDetails?.length > 0){
      <!-- @for(experience of experienceDetails;track experience){
          <div class="tag">
              <div class="tagContent">
                  {{experience?.role}}
                  <span class="icons">
                      <i class="fas fa-edit edit-icon" (click)="editCard(addExperience,experience)"></i>
                      <i class="fas fa-trash delete-icon" (click)="deleteCard(deleteModal,experience?.id)"></i>
                  </span>
              </div>
          </div>
          } -->
  
      <div class="row">
        <div class="col-md-12">
          <div class="educational-quality">
            @for(experience of experienceDetails;track experience){
            <div class="detail-tags">
              <div>
                <div class="m-circle clr">E</div>
                <div class="wrapper mb40">
                  @if(!experience?.isPresent){
                  <span class="tag">{{
                    experience?.startDate?.substring(0, 4) +
                    " - " +
                    experience?.endDate?.substring(0, 4)
                    }}</span>
                  }@else{
                  <span class="tag">{{ experience?.startDate?.substring(0, 4) }} - Present</span>
                  }
                  <h5 class="mt15">{{ experience?.role }}</h5>
                  <h4 class="clr2">{{ experience?.company }}</h4>
                  <p>
                    {{ experience?.description?.substring(0, 60) }}
                    @if(experience?.description?.length > 60){ ... }
                  </p>
                </div>
              </div>
  
              <div class="control-buttons">
                <i class="fas fa-edit edit-icon" (click)="editCard(addExperience, experience)"></i>
                <i class="fas fa-trash delete-icon" (click)="deleteCard(deleteModal, experience?.id)"></i>
              </div>
            </div>
            }
          </div>
        </div>
      </div>
      }
    </div>
    }
  </div>
  
  <ng-template #addExperience>
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          @if(FormName == "Add"){
          Add Experience
          }@else{
          Update Experience
          }
        </h5>
        <button type="button" class="btn-close" (click)="modal.dismissAll()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="row gx-2 gx-sm-3" [formGroup]="experienceForm">
  
          <div class="form-group col-sm-6">
            <label>Role<span class="text-danger">*</span></label>
            <input type="text" class="form-control" formControlName="role" placeholder="Enter role">
            @if(experienceForm.get('role')?.hasError('required') &&
            experienceForm.get('role')?.touched){
            <small class="text-danger">Role is Required</small>
            }
          </div>
  
          <div class="form-group col-sm-6">
            <label>Company<span class="text-danger">*</span></label>
            <input type="text" class="form-control" formControlName="company" placeholder="Enter company name">
            @if(experienceForm.get('company')?.hasError('required') &&
            experienceForm.get('company')?.touched){
            <small class="text-danger">Company name is Required</small>
            }
          </div>
  
          <div class="form-group col-sm-6">
            <label>Start Date<span class="text-danger">*</span></label>
            <input type="date" class="form-control" formControlName="startDate"  max="{{ getMaxDate() }}" (change)="getMinDate()">
            @if(experienceForm.get('startDate')?.hasError('required') &&
            experienceForm.get('startDate')?.touched){
            <small class="text-danger">Start date is Required</small>
            }
          </div>
  
          <div class="form-group col-sm-6">
            <div class="last-date">
              <label>End Date* </label>
              <!-- <label>
                <input class="checkbox_animated color-4" type="checkbox" [(ngModel)]="isPresent"
                  [ngModelOptions]="{standalone: true}" (change)="getCheckboxValue()" />
                Present
              </label> -->
            </div>
            <input type="date" class="form-control" formControlName="endDate" [min]="minmumDate">
            @if(experienceForm.get('endDate')?.hasError('required') &&
            experienceForm.get('endDate')?.touched){
            <small class="text-danger">End date is Required</small>
            }
          </div>
  
          <div class="form-group col-sm-12">
            <label>Description<span class="text-danger">*</span></label>
            <textarea class="form-control" formControlName="description" placeholder="Enter job description" rows="7"
              (input)="updateCharCount()"></textarea>
            <small class="">{{ charCount }}/200</small>
            @if(experienceForm.get('description')?.hasError('required') &&
            experienceForm.get('description')?.touched){
            <small class="text-danger">Description is Required</small>
            }@else if (experienceForm.get('description')?.touched && experienceForm.get('description')?.value?.length <
              200) { <small class="text-danger">Description should be more than 200 characters</small>
              }
          </div>
  
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-dashed color-2 btn-pill" (click)="modal.dismissAll()">Cancel</button>
        <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="saveExperienceDetails()">
          @if(FormName == "Add"){
          Add
          }@else{
          Update
          }
        </button>
      </div>
      <!-- <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          @if(FormName == "Add"){ Add Experience }@else{ Update Experience }
        </h5>
        <button
          type="button"
          class="btn-close"
          (click)="modal.dismissAll()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button> -->
    </div>
    <!-- <div class="modal-body">
      <form class="row gx-2 gx-sm-3" [formGroup]="experienceForm">
        <div class="form-group col-sm-6">
          <label>Role<span class="text-danger">*</span></label>
          <input type="text" class="form-control" formControlName="role" placeholder="Enter role" />
          @if(experienceForm.get('role')?.hasError('required') &&
          experienceForm.get('role')?.touched){
          <small class="text-danger">Role is Required</small>
          }
        </div>
  
        <div class="form-group col-sm-6">
          <label>Company<span class="text-danger">*</span></label>
          <input type="text" class="form-control" formControlName="company" placeholder="Enter company name" />
          @if(experienceForm.get('company')?.hasError('required') &&
          experienceForm.get('company')?.touched){
          <small class="text-danger">Company name is Required</small>
          }
        </div>
  
        <div class="form-group col-sm-6">
          <label>Start Date<span class="text-danger">*</span></label>
          <input type="date" class="form-control" formControlName="startDate" max="{{ getMaxDate() }}" />
          @if(experienceForm.get('startDate')?.hasError('required') &&
          experienceForm.get('startDate')?.touched){
          <small class="text-danger">Start date is Required</small>
          }
        </div>
  
        <div class="form-group col-sm-6">
          <div class="last-date">
            <label>End Date* </label>
            <label>
              <input class="checkbox_animated color-4" type="checkbox" [(ngModel)]="isPresent"
                [ngModelOptions]="{ standalone: true }" (change)="getCheckboxValue()" />
              Present
            </label>
          </div>
          <input type="date" class="form-control" formControlName="endDate" [min]="minmumDate" />
          @if(experienceForm.get('endDate')?.hasError('required') &&
          experienceForm.get('endDate')?.touched){
          <small class="text-danger">End date is Required</small>
          }
        </div>
  
        <div class="form-group col-sm-12">
          <label>Description<span class="text-danger">*</span></label>
          <textarea class="form-control" formControlName="description" placeholder="Enter job description"
            rows="7"></textarea>
          @if(experienceForm.get('description')?.hasError('required') &&
          experienceForm.get('description')?.touched){
          <small class="text-danger">Description is Required</small>
          }
        </div>
      </form>
    </div> -->
    <!-- <div class="modal-footer">
      <button type="button" class="btn btn-dashed color-2 btn-pill" (click)="modal.dismissAll()" aria-label="Cancel">
        Cancel
      </button>
      <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="saveExperienceDetails()" aria-label="Save">
        @if(FormName == "Add"){ Add }@else{ Update }
      </button>
    </div> -->
    <!-- </div> -->
  </ng-template>
  
  <ng-template #deleteModal>
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close" (click)="modal.dismissAll()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="text">Are You Sure you want to delete this experience ?</div>
        <div class="deleteButtons">
          <button type="button" class="btn btn-dashed color-2 btn-pill" (click)="modal.dismissAll()" aria-label="Close">
            Cancel
          </button>
          <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="deleteExperience()"
            aria-label="Delete">
            Delete
          </button>
        </div>
      </div>
    </div>
  </ng-template>