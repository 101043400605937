<!-- <app-navbar></app-navbar> -->
<app-header-one></app-header-one>
<app-breadcrumb [bgImage]="bgImage" [title]="title" [parent]="parent" [child]="child"></app-breadcrumb>

<section class="user-dashboard small-section">
  <div class="container">
    <div class="row">
      <div class="col-lg-3">
        <div class="sidebar-user sticky-cls">
          <div class="user-profile">
            <app-user-info></app-user-info>
          </div>
          <div class="dashboard-list">
            <app-user-panel-side-menu [activeTab]="'profile'"></app-user-panel-side-menu>
          </div>
        </div>
      </div>
      <div class="col-lg-9">
        <app-user-details></app-user-details>
      </div>
    </div>
  </div>
</section>

<app-adly-footer></app-adly-footer>
