import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, inject, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';

@Component({
  selector: 'app-cv-skill-details',
  templateUrl: './cv-skill-details.component.html',
  styleUrl: './cv-skill-details.component.scss'
})
export class CvSkillDetailsComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  skillForm: FormGroup;
  public FormName: string = "Add";
  isLoading: boolean = true;
  skillsDetails: any = [
    // {
    //   skillTitle: 'Angular',
    //   percentage: '80'
    // },
    // {
    //   skillTitle: 'React',
    //   percentage: '70'
    // },
    // {
    //   skillTitle: '.Net Core',
    //   percentage: '60'
    // }
  ];
  delId: number;
  editId: number = 0;
  private readonly _apiCall = inject(ApiCallService);
  private readonly _toaster = inject(ToastrService);
  private readonly _spinner = inject(NgxSpinnerService);
  private readonly errorHandlingService = inject(ErrorHandlingService);
  constructor(private fb: FormBuilder, public modal: NgbModal, private cdr: ChangeDetectorRef) {
    this.skillForm = this.fb.group({
      skillTitle: ['', Validators.required],
      percentage: ['', [Validators.required, Validators.min(0), Validators.max(100)]]
    });
  }

  ngOnInit(): void {
    this.getSKillsDetails();
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }


  addCard(content: TemplateRef<any>) {
    this.skillForm.reset();
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Add";
  }

  editCard(content: TemplateRef<any>, tag: any) {
    this.skillForm.reset();
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Edit";
    this.editId = tag?.id;
    this.skillForm.get("skillTitle")?.setValue(tag?.skillTitle);
    this.skillForm.get("percentage")?.setValue(tag?.percentage);

  }
  deleteCard(content: TemplateRef<any>, id: number) {
    this.modal.open(content, { centered: true, size: 'sm' });
    this.delId = id;
  }


  checkSkillExsists(): boolean {
    const payload = this.createApiPayload();
    if (this.skillsDetails?.length > 0) {
      if (this.FormName == "Add") {
        const isExsist = this.skillsDetails.some((tag: any) => tag.skillTitle == payload.skillTitle);
        if (isExsist) {
          this._toaster.error("This Skill Already Exist");
          return true;
        }
      } else {
        let newSkills = this.skillsDetails;
        newSkills = newSkills.filter((item: any) => item.id != this.editId);
        const isExsist = newSkills.some((tag: any) => tag.skillTitle == payload.skillTitle && tag.id != this.editId);
        if (isExsist) {
          this._toaster.error("This Skill Already Exist");
          return true;
        }
      }

      return false;
    }
    return false;
  }
  public async saveSkillDetails(): Promise<void> {
    this.skillForm.markAllAsTouched();

    const isAlreadyExsists = this.checkSkillExsists();
    if (!isAlreadyExsists) {
      const payload = this.createApiPayload();
      if (this.skillForm.invalid) {
        this._toaster.error("Invalid Form");
      }
      else {
        this._spinner.show();
        try {
          const response = await this._apiCall.PostCallWithToken(payload, 'AdmissionLeloUsers/SaveTeacherSkill').pipe(takeUntil(this.destroy$))
            .toPromise();
          if (response.responseCode == 200) {
            this._toaster.success(response.responseMessage);
            this.editId = 0;
            this.skillForm.reset();
            this._spinner.hide();
            this.modal.dismissAll();
            this.getSKillsDetails();
          }
          else {
            this._toaster.error(response.responseMessage);
            this._spinner.hide();
          }
        } catch (error) {
          this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
        } finally {
          this._spinner.hide();
          this.cdr.detectChanges();
        }
      }
    }
  }

  createApiPayload() {
    this.editId = this.FormName == "Add" ? 0 : this.editId;
    return {
      id: this.editId,
      skillTitle: this.skillForm.get('skillTitle')?.value,
      percentage: this.skillForm.get('percentage')?.value
    };
  }


  public async deleteSkill(): Promise<void> {
    this._spinner.show();
    try {
      const res = await this._apiCall.PostCallWithToken(null, `AdmissionLeloUsers/DeleteTeacherSkill?SkillId=${this.delId}`).pipe(takeUntil(this.destroy$))
        .toPromise();
      if (res.responseCode == 200) {
        this._toaster.success(res.responseMessage);
        this.getSKillsDetails();
        this._spinner.hide();
        this.modal.dismissAll();
      } else {
        this.errorHandlingService.handleResponseError(res);
      }
    } catch (error) {
      this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
    } finally {
      this._spinner.hide();
      this.cdr.detectChanges();
    }
  }


  // FUNCTION TO GET Experience DETAILS

  public async getSKillsDetails(): Promise<void> {
    this.isLoading = true;;
    try {
      const response = await this._apiCall.GetCallWithToken("AdmissionLeloUsers/GetTeacherSkill").pipe(takeUntil(this.destroy$))
        .toPromise();
      if (response.responseCode == 200) {
        this.skillsDetails = response.data;
      } else {
        this.skillsDetails = [];
        this.errorHandlingService.handleResponseError(response);
      }
    }catch (error) {
      this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
    } finally {
      this._spinner.hide();
      this.isLoading = false;

      this.cdr.detectChanges();
    }
  }


}
