<ul class="nav nav-tabs right-line-tab">
  @for(data of userPanelSideMenu; track data){
  <li class="nav-item">
    <a class="nav-link" href="javascript:void(0)" [ngClass]="{ 'active' : activeTab == data.value }" aria-label="clickable-text"
      (click)="getPage(data)">{{ data.title }}</a>
    @if(data?.subMenu){
    <ul *ngIf="data.subMenu" class="sub-menu">
      <li class="nav-item" *ngFor="let subItem of data.subMenu">
        <a class="nav-link" [ngClass]="{ 'active' : activeTab == subItem.value }" (click)="getPage(subItem)" aria-label="clickable-text">
          {{ subItem.title }}
        </a>
      </li>
    </ul>
    }
  </li>
  }
</ul>