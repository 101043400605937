import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { homeSectionSlider } from '../../../../../shared/interface/property';
import { interval } from 'rxjs';
import { ApiCallService } from 'src/app/shared/services/api-call.service';

@Component({
  selector: 'app-slider-two',
  templateUrl: './slider-two.component.html',
  styleUrls: ['./slider-two.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderTwoComponent {

  @Input() homeSectionSliderData: homeSectionSlider[] = [];

  private _apiSerice = inject(ApiCallService);

  public Options = {
    loop: true,
    nav: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 5000,
    navText: [
      '<i class="fa fa-chevron-left"></i>',
      '<i class="fa fa-chevron-right"></i>'
    ],
    responsive: {
      0: {
        items: 1
      }
    }
  }

  // generateSrcSet(images: any): string {
  //   return images
  //     ? `${images.w_20} 200w, ${images.w_547} 547w, ${images.w_777} 777w, ${images.w_979} 979w, ${images.w_1187} 1187w, ${images.w_1360} 1360w, ${images.w_1400} 1400w`
  //     : '';
  // }
  public view: { type: string; } = { type: '' };
  public wordList: string[] = ['Academic Excellence', 'Building Skills', 'Unlocking Opportunities'];


  ngOnInit() {

    const images = this.homeSectionSliderData
      .map(slider => slider.img)
      .filter((img): img is string => img !== undefined);

    this.preloadImages(images);
    let idx = 0;
    let n = 0;
    let up = true;

    interval(200).subscribe(() => {
      const word = this.wordList[idx];
      const ln = word.length;

      if (up) {
        this.view.type = word.slice(0, n);
        n++;
      }
      if (n === ln + 1) {
        up = false;
      }
      if (!up) {
        this.view.type = word.slice(0, n);
        n--;
      }
      if (n === 0) {
        up = true;
        idx++;
      }
      if (idx === this.wordList.length) {
        idx = 0;
      }
    });

    this.getTotalRecords();
  }

  animatedRecords: any = {
    institutes: 0,
    admisisons: 0,
    jobs: 0,
    scholarships: 0,
    books: 0,
    teachear: 0
  };

  totalRecords: any = {
    institutes: 0,
    admissions: 0,
    jobs: 0,
    scholarships: 0,
    books: 0,
    teachear: 0
  };

  // Function to animate a counter from 0 to target
  animateCount(field: string, target: number, duration: number): void {
    const increment = target / (duration / 10);
    let currentValue = 0;

    const interval = setInterval(() => {
      currentValue += increment;
      if (currentValue >= target) {
        currentValue = target;
        clearInterval(interval);
      }
      this.animatedRecords[field] = this.formatCount(Math.floor(currentValue));
    }, 10);
  }

  formatCount(count: number): string {
    if (count >= 1000) {
      return (count / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
      // e.g., "2k" ya "1.5k"
    } else {
      return count.toString();
    }
  }

  getTotalRecords() {
    this._apiSerice.GetCallWithoutToken("HomePage/GetBannerTotalCounts").subscribe((res: any) => {
      if (res && res.data && res.data.length > 0) {
        const data = res.data[0];
        this.totalRecords.institutes = data.totalInstitutes || 0;
        this.totalRecords.admissions = data.totalAdmissions || 0;
        this.totalRecords.scholarships = data.totalScholarships || 0;
        this.totalRecords.jobs = data.totalJobs || 0;
        this.totalRecords.books = data.totalBooks || 0;
        this.totalRecords.teachear = data.totalTeachers || 0;


        this.animateCount('institutes', this.totalRecords.institutes, 2000);
        this.animateCount('admisisons', this.totalRecords.admissions, 2000);
        this.animateCount('jobs', this.totalRecords.jobs, 2000);
        this.animateCount('scholarships', this.totalRecords.scholarships, 2000);
        this.animateCount('books', this.totalRecords.books, 2000);
        this.animateCount('teachear', this.totalRecords.teachear, 2000);
      }
    });
  }


  preloadImages(images: string[]) {
    images.forEach((image) => {
      const img = new Image();
      img.src = image;
    });
  }

  lookingForData = [
    {
      title: 'Institutes',
      icon: 'assets/images/icon/Institute-1-2.svg',
      path: '/institutes/all-institutes',
    },
    {
      title: 'Teachers',
      icon: 'assets/images/icon/newlogos/teacher.svg',
      path: '/jobs/all-jobs',
    },
    {
      title: 'Admissions',
      icon: 'assets/images/icon/Admission-1.svg',
      path: '/admissions/all-admissions',
    },
    {
      title: 'Scholarships',
      icon: 'assets/images/icon/newlogos/Scholarship.svg',
      path: '/scholarships/all-scholarships',
    },
    {
      title: 'Jobs',
      icon: 'assets/images/icon/Job-1.svg',
      path: '/jobs/all-jobs',
    },
    {
      title: 'Library',
      icon: 'assets/images/icon/library-2.svg',
      path: '/books/categories',
    },
    {
      title: 'CV Builder',
      icon: 'assets/images/icon/Job-1.svg',
      path: '/cv-builder/cv-templates',
    },
  ]
}
