<div class="body">
    <div class="resume_wrapper">
        <div class="resume_left">
            <div class="resume_image">
                <img [src]="userData?.personalDetails?.data?.profileBase64 || 'https://default-image-url.com/default-profile.png'"
                    class="img-fluid bg-img" alt="profile-image" />
            </div>
            <div class="resume_bottom">
                <div class="resume_item resume_namerole">
                    <div class="name">{{ userData?.personalDetails?.data?.firstName + ' ' +
                        userData?.personalDetails?.data?.lastName }}</div>
                    <div class="role">{{ userData?.personalDetails?.data?.gender }}</div>
                </div>
                <div class="resume_item resume_profile">
                    <div class="resume_title">Profile</div>
                    <div class="resume_info">{{ userData?.personalDetails.data.description || 'Lorem ipsum dolor sit amet consectetur
                        adipisicing elit.' }}</div>
                </div>
                <div class="resume_item resume_address">
                    <div class="resume_title">Address</div>
                    <div class="resume_info">
                        {{ userData?.personalDetails.data.address }}<br />
                        {{ userData?.personalDetails.data.city }}<br />
                        {{ userData?.personalDetails.data.state }}<br />
                        {{ userData?.personalDetails.data.country }}
                    </div>
                </div>
                <div class="resume_item resume_contact">
                    <div class="resume_title">Contact</div>
                    <div class="resume_info">
                        <div class="resume_subtitle">Phone</div>
                        <div class="resume_subinfo">{{ userData?.personalDetails.data.phoneNumber || '+92 123 4567890' }}</div>
                    </div>
                    <div class="resume_info">
                        <div class="resume_subtitle">Email</div>
                        <div class="resume_subinfo">{{ userData?.personalDetails.data.email || 'MYEMAIL@gmail.com' }}</div>
                    </div>
                </div>
                <div class="resume_item resume_skills">
                    <div class="resume_title">Skills</div>
                    <div class="resume_info">
                        <div class="skills_list" *ngFor="let skill of userData?.skillsDetails.data">
                            <div class="skills_left">{{ skill?.skillTitle || 'HTML' }}</div>
                            <div class="skills_bar">
                                <p><span [style.width.%]="skill?.percentage"></span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="resume_right">
            <div class="resume_item resume_namerole">
                <div class="name">{{ userData?.personalDetails?.data?.firstName + ' ' +
                    userData?.personalDetails?.data?.lastName || 'Muhammad Ahmad' }}</div>
                <div class="role">{{ userData?.personalDetails?.data.gender || 'UI Designer' }}</div>
            </div>
            <div class="resume_item resume_education">
                <div class="resume_title">Education</div>
                <div class="resume_info">
                    <div *ngFor="let education of userData?.educationDetails?.data">
                        <div class="resume_data">
                            <div class="year">{{ education?.startDate?.substring(0, 4) + ' - ' +
                                education?.endDate?.substring(0, 4) }}</div>
                            <div class="content">
                                <p>{{ education?.degreeTitle || 'Bachelor of Science in Computer Science' }}</p>
                                <p>{{ education?.institute || 'UNIVERSITY OF EDUCATION' }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="resume_item resume_experience">
                <div class="resume_title">Experience</div>
                <div class="resume_info">
                    <div *ngFor="let experience of userData?.experienceDetails?.data" class="resume_data">
                        <div class="year">{{ experience?.startDate?.substring(0, 4) + ' - ' +
                            experience?.endDate?.substring(0, 4) }}</div>
                        <div class="content">
                            <p>{{ experience?.role || 'SENIOR UI AND UX DESIGNER' }}</p>
                            <p>{{ experience?.description || 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
                                }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="resume_item resume_interests">
                <div class="resume_title">Languages</div>
                <div class="resume_info">
                    <div *ngFor="let language of userData?.languageDetails?.data" class="interests">
                        <div class="int_data">{{ language?.language + ' - ' + language?.languageLevel }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>