<!-- <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="var(--mainColor)" type="ball-spin-clockwise"
    [fullScreen]="true">
    <p style="font: size 20px; color: var(--mainColor)">Loading...</p>
</ngx-spinner> -->
<app-loader-two [isLoading]="isLoading"></app-loader-two>
<app-header-one></app-header-one>
<!-- @if(TeacherData){ -->
<!-- <app-agent-profile   [showBreadcrumb]="false" [showAdvanceFilter]="false" [showProjectyListing]="false" ></app-agent-profile> -->
<section class="agent-section property-section agent-profile-wrap">
    <div class="container">
        <div class="row ratio_65">
            <div class="col-xl-9 col-lg-8 property-grid-2">
                <div class="our-agent theme-card">
                    <div class="row">
                        <div class="col-sm-6 ratio_landscape">
                            <div class="agent-image bg-size" [style.background-image]="'url(' + imageUrl + ')'">
                                <img [src]="imageUrl" class="img-fluid bg-img" alt="agents-image" />


                                <!-- <span class="label label-shadow">{{ TeacherData?.property }} Properties</span> -->
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="our-agent-details">
                                <h3>{{ TeacherData?.firstName + ' ' + TeacherData?.lastName }} </h3>
                                <!-- <h3>{{ TeacherData?.lastName }}</h3> -->
                                <ul>
                                    <li>
                                        <div class="media">
                                            <div class="icons-square">
                                                <app-feather-icons [icon]="'user'"></app-feather-icons>
                                            </div>
                                            <div class="media-body">
                                                <h6>{{ TeacherData?.gender }}</h6>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="media">
                                            <div class="icons-square">
                                                <app-feather-icons [icon]="'map-pin'"></app-feather-icons>
                                            </div>
                                            <div class="media-body">
                                                <h6>{{ TeacherData?.address }}, {{ TeacherData?.city }}, {{
                                                    TeacherData?.state }}, {{ TeacherData?.country }}</h6>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="media">
                                            <div class="icons-square">
                                                <app-feather-icons [icon]="'phone-call'"></app-feather-icons>
                                            </div>
                                            <div class="media-body">
                                                <h6>{{ TeacherData?.phoneNumber }}</h6>
                                            </div>
                                        </div>

                                    </li>
                                    <li>
                                        <div class="media">
                                            <div class="icons-square">
                                                <app-feather-icons [icon]="'mail'"></app-feather-icons>
                                            </div>
                                            <div class="media-body">
                                                <h6>{{ TeacherData?.email }}</h6>
                                            </div>
                                        </div>
                                    </li>
                                    <!-- <li class="with-link">
                              <div class="media">
                                <div class="icons-square">
                                  <app-feather-icons [icon]="'link'"></app-feather-icons>
                                </div>
                                <div class="media-body">
                                  <h6><a href="javascript:void(0)">{{ TeacherData.link }}</a></h6>
                                </div>
                              </div>
                            </li> -->
                                </ul>
                            </div>
                            @if(TeacherData.socialLinks){
                            <ul class="agent-social">
                                <!-- @for(social of TeacherData?.socialLinks; track social ){ -->
                                @if(TeacherData.socialLinks.facebook){

                                <li>
                                    <a [href]="TeacherData?.socialLinks?.facebook" class="facebook">
                                        <i class="fab fa-facebook-f"></i>
                                    </a>
                                </li>
                                }
                                @if(TeacherData.socialLinks.twitter){

                                <li>
                                    <a class="twitter" [href]="TeacherData?.socialLinks?.twitter">
                                        <i class="fab fa-twitter"></i>
                                    </a>
                                </li>
                                }
                                @if(TeacherData.socialLinks.instagram){

                                <li>
                                    <a class="google" [href]="TeacherData?.socialLinks?.instagram">
                                        <i class="fab fa-instagram"></i>
                                    </a>
                                </li>
                                }
                                @if(TeacherData.socialLinks.linkedin){

                                <li>
                                    <a class="linkedin" [href]="TeacherData?.socialLinks?.linkedin">
                                        <i class="fab fa-linkedin-in"></i>
                                    </a>
                                </li>
                                }
                                <!-- } -->
                            </ul>
                            }
                        </div>
                    </div>
                </div>

                <!-- '/////////////////Descreption///////////////////' -->
                @if(TeacherData?.description && TeacherData?.description.length > 0){
                <div class="about-agent theme-card">
                    <h3>About Teacher</h3>
                    <div class="row">

                        <p class="font-roboto"> {{ TeacherData?.description }} </p>

                    </div>
                </div>
                }

                <!-- '/////////////////Education///////////////////' -->
                @if(TeacherData?.educations && TeacherData?.educations.length > 0){

                <div class="about-agent theme-card deatilsCards">
                    <div class="mainContainer">
                        <div class="headers">
                            <h6>
                                Educations
                            </h6>

                        </div>
                        <div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="educational-quality">
                                        @for(education of TeacherData?.educations;track education){


                                        <div class="detail-tags">
                                            <div>
                                                <div class="m-circle clr">E</div>
                                                <div class="wrapper mb40">
                                                    @if(!education?.isPresent){
                                                    <span class="tag">{{education?.startDate?.substring(0,4) +" - " +
                                                        education?.endDate?.substring(0,4)}}</span>
                                                    }@else{
                                                    <span class="tag">{{education?.startDate?.substring(0,4) }} -
                                                        Present</span>
                                                    }
                                                    <h5 class="mt15">{{education?.degreeTitle}}</h5>
                                                    <h4 class="clr2">{{education?.institute}}</h4>
                                                    <p>CGPA : {{education?.cgpa}}</p>

                                                </div>
                                            </div>


                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>

                </div>
                }




                <!-- '/////////////////Subjects///////////////////' -->
                @if(TeacherData?.subjects && TeacherData?.subjects.length > 0){

                <div class="about-agent theme-card deatilsCards">
                    <div class="mainContainer">
                        <div class="headers">
                            <h6>
                                Subjects
                            </h6>
                        </div>
                        <div class="tagsContainer">

                            @for(subject of TeacherData?.subjects;track subject){
                            <div class="tag">
                                <div class="tagContent">
                                    {{subject?.subject + ' - ' + "$" + subject?.fee}}
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                }

                <!-- '/////////////////Language///////////////////' -->
                @if(TeacherData?.languages && TeacherData?.languages.length > 0){

                <div class="about-agent theme-card deatilsCards">
                    <div class="mainContainer">
                        <div class="headers">
                            <h6>
                                Languages
                            </h6>
                            <div>
                            </div>
                        </div>
                        <div class="tagsContainer">
                            @for(language of TeacherData?.languages;track language){
                            <div class="tag">
                                <div class="tagContent">
                                    {{language?.language + " - " + language?.languageLevel}}
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>

                }
                <!-- '/////////////////Skills///////////////////' -->
                @if(TeacherData?.skills && TeacherData?.skills.length > 0){

                <div class="about-agent theme-card deatilsCards">
                    <div class="mainContainer">
                        <div class="headers">
                            <h6>
                                Skills
                            </h6>
                        </div>
                        <div class="tagsContainer">

                            @for(skill of TeacherData?.skills;track skill){
                            <div class="tag">
                                <div class="tagContent">
                                    {{skill?.skillTitle + " - " + skill?.percentage + "%"}}

                                </div>
                            </div>

                            }
                        </div>
                    </div>

                </div>
                }



                <!-- '/////////////////Experience///////////////////' -->

                @if(TeacherData?.experiences && TeacherData?.experiences.length > 0){



                <div class="about-agent theme-card deatilsCards">
                    <div class="mainContainer">
                        <div class="headers">
                            <h6>
                                Experience
                            </h6>
                            <div>
                            </div>

                        </div>
                        <div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="educational-quality">
                                        @for(experience of TeacherData?.experiences;track experience){
                                        <div class="detail-tags">
                                            <div>
                                                <div class="m-circle clr">WE</div>
                                                <div class="wrapper mb40">
                                                    @if(!experience?.isPresent){
                                                    <span class="tag">{{experience?.startDate?.substring(0,4) +" - " +
                                                        experience?.endDate?.substring(0,4)}}</span>
                                                    }@else{
                                                    <span class="tag">{{experience?.startDate?.substring(0,4) }} -
                                                        Present</span>
                                                    }

                                                    <h5 class="mt15"> {{experience?.role}}</h5>
                                                    <h4 class="clr2">{{experience?.company}}</h4>
                                                    <p>{{experience?.description}}
                                                    </p>
                                                </div>
                                            </div>


                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                }

                <!-- '/////////////////Awards///////////////////' -->

                @if(TeacherData?.awards && TeacherData?.awards.length > 0){

                <div class="about-agent theme-card deatilsCards">
                    <div class="mainContainer">
                        <div class="headers">
                            <h6>
                                Awards
                            </h6>
                            <div>
                            </div>
                        </div>
                        <div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="educational-quality">
                                        @for(Awards of TeacherData?.awards;track Awards){
                                        <div class="detail-tags">
                                            <div>
                                                <div class="m-circle clr">A</div>
                                                <div class="wrapper mb40">
                                                    @if(!Awards?.isPresent){
                                                    <span class="tag">{{Awards?.startDate?.substring(0,4) +" - " +
                                                        Awards?.endDate?.substring(0,4)}}</span>
                                                    }@else{
                                                    <span class="tag">{{Awards?.startDate?.substring(0,4) }} -
                                                        Present</span>
                                                    }

                                                    <h5 class="mt15"> {{Awards?.position}}</h5>
                                                    <h4 class="clr2">{{Awards?.company}}</h4>
                                                    <p>{{Awards?.description}}
                                                    </p>
                                                </div>
                                            </div>


                                        </div>

                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>



                }


                <!-- '/////////////////Certificates///////////////////' -->

                @if(TeacherData?.certificates && TeacherData.certificates.length > 0) {

                <div class="about-agent theme-card deatilsCards">
                    <div class="mainContainer">
                        <div class="headers">
                            <h6>
                                Certificates
                            </h6>
                            <div>

                            </div>

                        </div>
                        <div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="educational-quality">
                                        @for(certificate of TeacherData?.certificates;track certificate){
                                        <div class="detail-tags">
                                            <div>
                                                <div class="m-circle clr">C</div>
                                                <div class="wrapper mb40">
                                                    @if(!certificate?.isPresent){
                                                    <span class="tag">{{certificate?.startDate?.substring(0,4) +" - " +
                                                        certificate?.endDate?.substring(0,4)}}</span>
                                                    }
                                                    @else{
                                                    <span class="tag">{{certificate?.startDate?.substring(0,4) }} -
                                                        Present</span>
                                                    }
                                                    <h5 class="mt15">{{certificate?.title}}</h5>
                                                    <h4 class="clr2">{{certificate?.institute}}</h4>
                                                </div>
                                            </div>


                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                }

            </div>


            <div class="col-xl-3 col-lg-4">
                <app-advance-filter [teacherData]="TeacherData">
                </app-advance-filter>
            </div>
        </div>
    </div>


</section>




<!-- Relatd books section  -->
<section class="wrapper">
    <div class="row">
        <div class="title-3 text-start inner-title">
            <h2>Related Teachers</h2>
        </div>
        @if(isRelatedTeachersLoading){
        <div class="item">
            <ngx-skeleton-loader count="3   " [theme]="{
                height: '25rem',
                width: '25%',
                'border-radius': '10px',
                'margin-right': '0.5rem',
                'margin-left':'0.5rem'
                                }" />
        </div>
        }
        @else{
        <section class="agent-section property-section">

            <div class="container">
                <div class="row ratio2_3">
                    <div class="col-xl-12 col-lg-12 property-grid-3 agent-grids">

                        <div class="property-wrapper-grid list-view">
                            <app-common-agency [agencyData]="relatedTeachersData" [type]="'basic'"></app-common-agency>
                        </div>
                    </div>
                </div>
            </div>

        </section>
        }
    </div>
</section>





<app-adly-footer></app-adly-footer>