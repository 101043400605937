import { ChangeDetectionStrategy, Component, HostListener, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-property-slider',
  templateUrl: './property-slider.component.html',
  styleUrls: ['./property-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertySliderComponent {
  @Input() propertyData: any;
  @Input() instituteData: any;
  navigations: any;

  public activeClass = 'about';
  public navFixed: boolean = false;

  constructor(public sanitizer: DomSanitizer) { }

  safeVideosUrl: any;
  safeLocationUrl: any;

  ngOnInit(): void {
    this.safeVideosUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.instituteData?.videoLink);
    this.safeLocationUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.instituteData?.locationSrc);

    if (this.instituteData?.moduleType === 'jobs') {
      this.navigations = [
        { title: 'description', value: 'description' },
        this.instituteData?.applyDescription ? { title: 'howToApply', value: 'howToApply' } : null,
        this.instituteData?.responsibilitiesDescription ? { title: 'responsibilities', value: 'responsibilities' } : null,
        this.instituteData?.additionalDescription ? { title: 'additional', value: 'additional' } : null,
        this.instituteData?.skillVMs?.length >= 1 ? { title: 'skills', value: 'skills' } : null,
        this.instituteData?.departments?.length >= 1 ? { title: 'education', value: 'departments' } : null,
        this.instituteData?.courses?.length >= 1 ? { title: 'experience', value: 'courses' } : null,
        // { title: 'gallery', value: 'gallery' },
        { title: 'details', value: 'details' },
        this.safeLocationUrl ? { title: 'location', value: 'location' } : null
      ].filter(Boolean);
    }
    else if (this.instituteData?.moduleType === 'admission') {
      this.navigations = [
        { title: 'description', value: 'description' },
        this.instituteData?.applyDescription ? { title: 'howToApply', value: 'howToApply' } : null,
        this.instituteData?.responsibilitiesDescription ? { title: 'responsibilities', value: 'responsibilities' } : null,
        this.instituteData?.additionalDescription ? { title: 'additional', value: 'additional' } : null,
        this.instituteData?.skillVMs?.length >= 1 ? { title: 'Documents', value: 'skills' } : null,
        this.instituteData?.departments?.length >= 1 ? { title: 'Departments', value: 'departments' } : null,
        this.instituteData?.courses?.length >= 1 ? { title: 'Courses', value: 'courses' } : null,
        this.instituteData?.schlorships?.length >= 1 ? { title: 'Schlorships', value: 'schlorships' } : null,
        this.instituteData?.classes?.length >= 1 ? { title: 'Classes', value: 'classes' } : null,
        // { title: 'gallery', value: 'gallery' },
        { title: 'details', value: 'details' },
        this.safeLocationUrl ? { title: 'location', value: 'location' } : null
      ].filter(Boolean);
    }
    else if (this.instituteData?.moduleType === 'scholarships') {
      this.navigations = [
        { title: 'description', value: 'description' },
        this.instituteData?.applyDescription ? { title: 'howToApply', value: 'howToApply' } : null,
        this.instituteData?.skillVMs?.length >= 1 ? { title: 'Coverages', value: 'skills' } : null,
        this.instituteData?.departments?.length >= 1 ? { title: 'Documents', value: 'departments' } : null,
        this.instituteData?.courses?.length >= 1 ? { title: 'Courses', value: 'courses' } : null,
        { title: 'details', value: 'details' },
        this.safeLocationUrl ? { title: 'location', value: 'location' } : null,
      ].filter(Boolean);
    } else if (this.instituteData?.moduleType === 'books') {
      {
        this.navigations = [
          { title: 'description', value: 'description' },

          this.instituteData?.departments?.length >= 1 ? { title: 'Keywords', value: 'departments' } : null,
          this.instituteData?.courses?.length >= 1 ? { title: 'Authors', value: 'courses' } : null,
          { title: 'details', value: 'details' }
          // { title: 'review', value: 'review' }
        ].filter(Boolean);
      }
    }
    else {
      this.navigations = [
        { title: 'description', value: 'description' },
        this.instituteData?.additionalFeatures?.length >= 1 ? { title: 'feature', value: 'feature' } : null,
        this.instituteData?.departments?.length >= 1 ? { title: 'departments', value: 'departments' } : null,
        this.instituteData?.courses?.length >= 1 ? { title: 'courses', value: 'courses' } : null,
        this.instituteData?.classes?.length >= 1 ? { title: 'classes', value: 'classes' } : null,
        this.instituteData?.images?.length >= 1 ? { title: 'gallery', value: 'gallery' } : null,
        this.instituteData?.videoLink ? { title: 'video', value: 'video' } : null,
        { title: 'details', value: 'details' },
        this.safeLocationUrl ? { title: 'location', value: 'location' } : null,
        // { title: 'review', value: 'review' }
      ].filter(Boolean);
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollPosition = window.pageYOffset || 0;
    const offsetTopAdjustment = 80; // Adjust this if needed

    // Toggle fixed navigation based on scroll position
    this.navFixed = scrollPosition > 500;

    // Get references to sections in the DOM
    const sections: { [key: string]: HTMLElement | null } = {
      description: document.getElementById('description'),
      howToApply: this.instituteData?.applyDescription ? document.getElementById('howToApply') : null,
      responsibilities: this.instituteData?.responsibilitiesDescription ? document.getElementById('responsibilities') : null,
      additional: this.instituteData?.additionalDescription ? document.getElementById('additional') : null,
      feature: this.instituteData?.additionalFeatures?.length >= 1 ? document.getElementById('feature') : null,
      departments: this.instituteData?.departments?.length >= 1 ? document.getElementById('departments') : null,
      courses: this.instituteData?.courses?.length >= 1 ? document.getElementById('courses') : null,
      classes: this.instituteData?.classes?.length >= 1 ? document.getElementById('classes') : null,
      skills: this.instituteData?.skillVMs?.length >= 1 ? document.getElementById('skills') : null,
      schlorships: this.instituteData?.schlorships?.length >= 1 ? document.getElementById('schlorships') : null,
      gallery: document.getElementById('gallery'),
      video: this.instituteData?.videoLink ? document.getElementById('video') : null,
      details: document.getElementById('details'),
      review: this.instituteData?.moduleType === 'institute' ? document.getElementById('review') : null,
      location: this.safeLocationUrl ? document.getElementById('location') : null
    };

    this.activeClass = '';

    // key in the order
    const sectionKeys = Object.keys(sections).filter(key => sections[key]);

    // finding active section
    let activeSectionKey = '';
    for (let i = 0; i < sectionKeys?.length; i++) {
      const currentSectionKey = sectionKeys[i];
      const currentSection = sections[currentSectionKey];
      const nextSectionKey = i + 1 < sectionKeys?.length ? sectionKeys[i + 1] : null;
      const nextSection = nextSectionKey ? sections[nextSectionKey] : null;

      // Calculate the top and bottom of the current section
      const currentSectionTop = this.getOffsetTop(currentSection!) - this.getNavHeight() - offsetTopAdjustment;
      const currentSectionBottom = nextSection ? this.getOffsetTop(nextSection) - this.getNavHeight() - offsetTopAdjustment : Infinity;

      // Check if the scroll position is within the current section
      if (scrollPosition >= currentSectionTop && scrollPosition < currentSectionBottom) {
        activeSectionKey = currentSectionKey;
      }
    }

    // Special case for the 'education' section
    if (sections['departments']) {
      const educationSectionTop = this.getOffsetTop(sections['departments']) - this.getNavHeight() - offsetTopAdjustment;
      const educationSectionBottom = sections['courses'] ? this.getOffsetTop(sections['courses']) - this.getNavHeight() - offsetTopAdjustment : Infinity;

      if (scrollPosition >= educationSectionTop && scrollPosition < educationSectionBottom) {
        activeSectionKey = 'departments';
      }
    }

    // Special case for the 'experience' section
    if (sections['courses']) {
      const experienceSectionTop = this.getOffsetTop(sections['courses']) - this.getNavHeight() - offsetTopAdjustment;
      const experienceSectionBottom = sections['gallery'] ? this.getOffsetTop(sections['gallery']) - this.getNavHeight() - offsetTopAdjustment : Infinity;

      if (scrollPosition >= experienceSectionTop && scrollPosition < experienceSectionBottom) {
        activeSectionKey = 'courses';
      }
    }

    // Special case for the 'schlorships' section
    if (sections['schlorships']) {
      const scholarshipsSectionTop = this.getOffsetTop(sections['schlorships']) - this.getNavHeight() - offsetTopAdjustment;
      const scholarshipsSectionBottom = sections['gallery'] ? this.getOffsetTop(sections['gallery']) - this.getNavHeight() - offsetTopAdjustment : Infinity;

      if (scrollPosition >= scholarshipsSectionTop && scrollPosition < scholarshipsSectionBottom) {
        activeSectionKey = 'schlorships';
      }
    }

    // Special case for the 'details' section
    if (sections['details']) {
      const detailsSectionTop = this.getOffsetTop(sections['details']) - this.getNavHeight() - offsetTopAdjustment;
      const detailsSectionBottom = sections['location'] ? this.getOffsetTop(sections['location']) - this.getNavHeight() - offsetTopAdjustment : Infinity;

      if (scrollPosition >= detailsSectionTop && scrollPosition < detailsSectionBottom) {
        activeSectionKey = 'details';
      }
    }

    // Special case for the 'location' section
    if (sections['location']) {
      const locationSectionTop = this.getOffsetTop(sections['location']) - this.getNavHeight() - offsetTopAdjustment;
      const locationSectionBottom = Infinity; // Last section or no next section

      if (scrollPosition >= locationSectionTop) {
        activeSectionKey = 'location';
      }
    }
    // Special handling for the 'review' section
    if (sections['review']) {
      const reviewSectionTop = this.getOffsetTop(sections['review']) - this.getNavHeight() - offsetTopAdjustment;
      const reviewSectionBottom = Infinity; // Last section or no next section

      if (scrollPosition >= reviewSectionTop) {
        activeSectionKey = 'review';
      }
    }
    // Set the active class
    this.activeClass = activeSectionKey;
  }

  private getNextSection(current: string): string | null {
    const sectionOrder = this.instituteData?.moduleType === 'jobs'
      ? ['description', 'howToApply', 'responsibilities', 'additional', 'skills', 'departments', 'courses', 'gallery', 'details', 'location']
      : ['description', 'feature', 'departments', 'courses', 'schlorships', 'classes', 'gallery', 'video', 'details', 'review', 'location'];

    const currentIndex = sectionOrder.indexOf(current);
    return sectionOrder.slice(currentIndex + 1).find(key => !!document.getElementById(key)) || null;
  }

  private getOffsetTop(element: HTMLElement): number {
    return element?.getBoundingClientRect().top + window.pageYOffset || 0;
  }

  private getNavHeight(): number {
    return document.querySelector('nav')?.offsetHeight || 0;
  }

  setPage(value: string) {
    // console.log(`Clicked section: ${value}`);

    const targetElement = document.getElementById(value);
    if (targetElement) {
      const offsetTop = this.getOffsetTop(targetElement) - 120;
      window.scrollTo({ top: offsetTop, behavior: 'smooth' });
      this.activeClass = value;
    }
  }
}
