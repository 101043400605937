import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { totalAgents, totalProperty, totalSales } from '../../../../shared/data/dashboard-charts';
import { MetaService } from 'src/app/shared/services/meta.service';
import { ApiCallService } from 'src/app/shared/services/api-call.service';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserDashboardComponent {

  public themeLogo = 'assets/images/logo/2.png';
  public footerLogo = 'assets/images/logo/footer-logo.png';
  public bgImage = 'assets/images/banner/banner.webp';
  public title = 'Dashboard';
  public parent = 'Home';
  public child = 'Dashboard';

  public totalAgents = totalAgents;
  public totalSales = totalSales;
  public totalProperty = totalProperty;
  private readonly metaService: MetaService = inject(MetaService);
  private _apiService=inject(ApiCallService);

  public theme_default3 = '#ff5c41';
  public theme_default4 = '#ff8c41';

  ngOnInit() {
    this.getTotalRecords();
    console.log("Records", this.totalRecords)
    document.documentElement.style.setProperty('--theme-default', this.theme_default3);
    document.documentElement.style.setProperty('--theme-default3', this.theme_default3);
    document.documentElement.style.setProperty('--theme-default4', this.theme_default4);

    window.scrollTo({ top: 0, behavior: 'smooth' });

    this.metaService.updateTitle('Dashboard | AdmissionLylo');
    this.metaService.updateMetaTags([
      { name: 'description', content: 'Your page description here' },
      { name: 'keywords', content: 'angular, seo, meta tags' },
      { name: 'author', content: 'Your Name' },
      { name: 'robots', content: 'index, follow' }
    ]);
    this.metaService.setCanonicalURL();
    this.metaService.initializeMetaData();
    console.log("Console from Home Page")
  }

  ngOnDestroy(): void {
    document.documentElement.style.removeProperty('--theme-default');
    document.documentElement.style.removeProperty('--theme-default3');
    document.documentElement.style.removeProperty('--theme-default4');
  }



  totalRecords: any = {
    institutes: 0,
    admissions: 0,
    jobs: 0,
    scholarships: 0,
    books: 0,
    teachers:0,
  };

  getTotalRecords() {
    this._apiService.GetCallWithoutToken("HomePage/GetBannerTotalCounts").subscribe((res: any) => {
      if (res && res.data && res.data.length > 0) {
        const data = res.data[0];  
        this.totalRecords.institutes = data.totalInstitutes || 0;
        this.totalRecords.admissions = data.totalAdmissions || 0;
        this.totalRecords.scholarships = data.totalScholarships || 0;
        this.totalRecords.jobs = data.totalJobs || 0;
        this.totalRecords.books = data.totalBooks || 0;
        this.totalRecords.teachers = data.totalTeachers || 0;
      }
    });
  }

}
