import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrl: './education.component.scss',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class EducationComponent implements OnInit, OnDestroy {
  // private destroy$ = new Subject<void>();

  // VARIABLES
  educationForm: FormGroup;
  public FormName: string = "Add";
  public isSpinnerShown = false;
  editEducationId: number = 0;
  delEducationId: number;
  isLoading: boolean = true;

  educationDetails: any = [ ];

  // SERVICES
  private readonly _apiCall = inject(ApiCallService);
  private readonly _toaster = inject(ToastrService);
  private readonly _spinner = inject(NgxSpinnerService);
  private readonly errorHandlingService = inject(ErrorHandlingService);


  constructor(private fb: FormBuilder, public modal: NgbModal, private cdr: ChangeDetectorRef) {
    this.educationForm = this.fb.group({
      institute: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      cgpa: ['', [Validators.required, Validators.pattern(/^(?:[0-3](?:\.\d{1,2})?|4(?:\.00?)?)$/)]],
      // total: ['', [Validators.required, Validators.min(0)]],
      // obtained: ['', [Validators.required, Validators.min(0)]]
    });
  }

  ngOnInit(): void {
    this.getDegreeDropDown();
    this.getEducationDetails();
  }

  ngOnDestroy(): void {
    // this.destroy$.next();
    // this.destroy$.complete();
  }



  // FUNCTION FOR ADD FORM POPUP
  addCard(content: TemplateRef<any>) {
    this.educationForm.reset();
    this.degree = "";
    this.isPresent = false;
    if (!this.isPresent) {
      this.educationForm.get('endDate')?.enable();
    }
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Add";
  }

  newEducationsDetails: any;

  // FUNCTION FOR EDIT CARD POPUP
  editCard(content: TemplateRef<any>, tag: any) {
    this.educationForm.reset();
    this.degree = "";
    this.isPresent = false;
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Edit";
    this.editEducationId = tag?.id;
    this.educationForm.get('institute')?.setValue(tag?.institute);
    this.educationForm.get('startDate')?.setValue(tag?.startDate?.substring(0, 10));
    this.educationForm.get('endDate')?.setValue(tag?.endDate?.substring(0, 10));
    this.educationForm.get('cgpa')?.setValue(tag?.cgpa);
    this.degree = tag?.degreeTitle;
    this.isPresent = tag?.isPresent;
    if (this.isPresent) {
      this.educationForm.get('endDate')?.disable();
    } else {
      this.educationForm.get('endDate')?.enable();
    }
    // this.educationForm.get('total')?.setValue("Atg System");
    // this.educationForm.get('obtained')?.setValue("Atg System");

  }

  // FUNCTION FOR DELETE CARD POPUP
  deleteCard(content: TemplateRef<any>, id: any) {
    this.delEducationId = id;
    this.modal.open(content, { centered: true, size: 'sm' });
  }

  public deleteEducation(): void {
    this.isSpinnerShown = true

    this._apiCall.PostCallWithToken(null, `AdmissionLeloUsers/DeleteTeacherEducation?EducationId=${this.delEducationId}`)
      .subscribe(
        (res) => {
          if (res.responseCode === 200) {
            this._toaster.success(res.responseMessage);
            this.getEducationDetails();
            this.modal.dismissAll();
          } else {
            this.errorHandlingService.handleResponseError(res);
          }
          this.isSpinnerShown = false;
        },
        (error) => {
          this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
          this.isSpinnerShown = false;
        }
      );
}



  // FUNCTION TO GET EDUCATION DETAILS

  public getEducationDetails(): void {
    this.isLoading = true;

    this._apiCall.GetCallWithToken("AdmissionLeloUsers/GetTeacherEducation")
      .subscribe(
        (response) => {
          if (response.responseCode === 200) {
            this.educationDetails = response.data;
          } else {
            this.errorHandlingService.handleResponseError(response);
            this.educationDetails = [];
          }
          this.isLoading = false;
        },
        (error) => {
          this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
          this.isLoading = false;
        }
      );
}


  checkDegreeExsists(): boolean {
    const payload = this.createApiPayload();
    if (this.educationDetails?.length > 0) {
      if (this.FormName == "Add") {
        const isDegreeExist = this.educationDetails.some((tag: any) => tag.degreeTitle == payload.degreeTitle);
        if (isDegreeExist) {
          this._toaster.error("This Degree Already Exist");
          return true;
        }
      } else {
        let newEducations = this.educationDetails;
        console.log("newEducations before", newEducations)
        newEducations = newEducations.filter((item: any) => item.id != this.editEducationId);
        console.log("newEducations after", newEducations)
        const isDegreeExist = newEducations.some((tag: any) => tag.degreeTitle == payload.degreeTitle && tag.id != this.editEducationId);
        if (isDegreeExist) {
          this._toaster.error("This Degree Already Exist");
          return true;
        }
      }

      return false;
    }
    return false;
  }


  // FUNCTION TO SAVE EDUCATION DETAILS
  public saveEducationDetails(): void {
    this.degreeError = this.degree === "" ? true : false;
    this.educationForm.markAllAsTouched();
    const isDegExsists = this.checkDegreeExsists();
    if (!isDegExsists) {
        const payload = this.createApiPayload();
        if (this.educationForm.invalid || this.degree === "") {
            this._toaster.error("Invalid Form");
            return;
        } else {
            this.isSpinnerShown = true;
            this._apiCall.PostCallWithToken(payload, 'AdmissionLeloUsers/SaveTeacherEducation')
                .subscribe(
                    (response) => {
                        if (response.responseCode === 200) {
                            this._toaster.success(response.responseMessage);
                            this.degree = "";
                            this.editEducationId = 0;
                            this.educationForm.reset();
                            this.modal.dismissAll();
                            this.getEducationDetails();
                        } else {
                            this.errorHandlingService.handleResponseError(response);
                        }
                        this.isSpinnerShown = false;
                    },
                    (error) => {
                        this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
                        this.isSpinnerShown = false;
                    }
                );
        }
    }
}

  // FUNCTION TO CRAETE API PAYLOAD
  createApiPayload() {
    const date = new Date().toJSON();

    this.editEducationId = this.FormName == "Add" ? 0 : this.editEducationId;
    return {
      id: this.editEducationId,
      degreeTitle: this.degree,
      institute: this.educationForm.get('institute')?.value,
      startDate: this.educationForm.get('startDate')?.value || date,
      endDate: this.educationForm.get('endDate')?.value || date,
      cgpa: this.educationForm.get('cgpa')?.value,
      total: this.educationForm.get('total')?.value,
      obtained: this.educationForm.get('obtained')?.value,
      IsPresent: this.isPresent
    };
  }




  // FUNCTIOND FOR DATES
  minmumDate: any
  getMaxDate(): string {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    return formattedDate;
  }
  getMinDate() {
    const maxDate = this.educationForm.get('startDate')?.value;
    this.educationForm.get('endDate')?.reset();
    this.minmumDate = maxDate
  }


  // DEGREE DROPDOWN DATA
  degree: string = '';
  degreeList = []
  degreeError = false;
  onDeegreSelected(option: any) {
    this.degree = option.value;
  }
  onDegreeValueChange(searchValue: any) {
    this.degree = searchValue
  }
  getDegreeDropDown() {
    this._apiCall
      .GetCallWithoutToken("DropDown/GetDegreeDropDown")
      .subscribe((response) => {
        this.degreeList = response?.data;
      });
  }



  // PRESENT BUTTON LOGISTICS
  isPresent: boolean = false;
  getCheckboxValue() {
    console.log(this.isPresent);
    if (this.isPresent) {
      this.educationForm.get('endDate')?.disable();
    } else {
      this.educationForm.get('endDate')?.enable();
    }
  }

}
