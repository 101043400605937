
<!-- <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="var(--mainColor)" type="ball-spin-clockwise"
    [fullScreen]="true">
    <p style="font: size 20px; color: var(--mainColor)">Loading...</p>
</ngx-spinner> -->
<app-loader-two [isLoading]="isSpinnerShown"></app-loader-two>
<app-header-one></app-header-one>
<app-breadcrumb [bgImage]="bgImage" [title]="title" [parent]="parent" [child]="child"></app-breadcrumb>

<section class="user-dashboard small-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="sidebar-user sticky-cls">
                    <div class="user-profile">
                        <app-user-info></app-user-info>
                    </div>
                    <div class="dashboard-list">
                        <app-user-panel-side-menu [activeTab]="'add-admission'"></app-user-panel-side-menu>
                    </div>
                </div>
            </div>
            <div class="col-lg-9">
                <div class="dashboard-content">
                    <div class="payment-user" id="payment">
                        <div class="common-card">
                            <div class="common-header">
                                <h5>Admission</h5>
                            </div>
                            <div class="row card-payment">
                                @if (isDataLoading) {
                                <div class="item">
                                    <ngx-skeleton-loader count="3" [theme]="{
                                                                height: '22.5rem',
                                                                width: '30%',
                                                                'border-radius': '10px',
                                                                'margin-right': '0.5rem',
                                                                'margin-left': '0.5rem'
                                                                }" />
                                </div>
                                }@else{
                                @for(data of admissionData; track data){
                                <div class="col-xl-4 col-sm-6">
                                    <!-- //ultra new cards  -->

                                    <div class="property-box payment-card">
                                        <div class="property-image">
                                            <!-- <owl-carousel-o [options]="Options">
                                                @for(images of data?.admissionImages; track images){
                                                
                                                <ng-template carouselSlide>
                                                    <div class="property-slider" [class]="tagClass">
                                                        <a href="javascript:void(0)" class="bg-size"
                                                            [style.background-image]="'url('+images?.imageBase64+')'">
                                                            <img [src]="images?.imageBase64" class="bg-img"
                                                                alt="property-image" style="display: none;" />
                                                        </a>
                                                    </div>
                                                </ng-template>
                                               
                                                }
                                            </owl-carousel-o> -->

                                            <div class="property-slider" [class]="tagClass">
                                                <div href="javascript:void(0)" class="bg-size"
                                                    [style.background-image]="'url(' + data?.imageBase64 + ')'">
                                                    <img [src]="data?.imageBase64" class="bg-img"
                                                        alt="property-image" style="display: none;" />
                                                </div>
                                            </div>
                                            <!-- <div class="seen-data">
                                                <app-feather-icons [icon]="'camera'"></app-feather-icons>
                                                <span>{{ data?.img?.length }}</span>
                                            </div> -->
                                        </div>
                                        <div class="property-details">
                                            <span class="font-roboto card-title">
                                                <app-feather-icons class="color"
                                                    [icon]="'map-pin'"></app-feather-icons>{{ data?.city + ", "
                                                + data?.country }}</span>
                                            <a href="javascript:void(0)">
                                                <h3 class="line-clamp">{{ data?.instituteName }}</h3>
                                            </a>
                                            <p class="font-roboto description-clamp">{{ data.description }}</p>
                                            <ul>
                                                <li>
                                                    <img src="assets/images/svg/icon/telephone.png"
                                                        class="img-fluid ruler-tool" alt="ruler-icon" />{{
                                                    data?.contactNumber }}
                                                </li>
                                                <li class="border-line"></li>
                                                <li>
                                                    <img src="assets/images/svg/icon/email.png"
                                                        class="img-fluid ruler-tool" alt="ruler-icon" />
                                                    {{ data?.email }}
                                                </li>
                                            </ul>
                                            <!-- <div class="property-btn d-flex">
                                                <button class="btn btn-link">
                                                    Details<i class="fas fa-arrow-right ms-1"></i>
                                                </button>
                                            </div> -->
                                        </div>
                                        <div class="edit-card">
                                            <a href="javascript:void(0)"
                                                (click)="editCard(addAdmission,data?.id)">edit</a>
                                            <a href="javascript:void(0)"
                                                (click)="deleteCard(deleteModal,data?.id)">delete</a>
                                        </div>
                                    </div>
                                </div>
                                }
                                }
                                <div class="col-xl-4 col-sm-6">
                                    <div class="payment-card add-card">
                                        <div class="card-details" (click)="addCard(addAdmission)">
                                            <div>
                                                <i class="fas fa-plus-circle"></i>
                                                <h5>Add new Admission</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-adly-footer></app-adly-footer>





<ng-template #addAdmission>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">
                @if(FormName == "Add"){
                    Add New Admission
                    }@else{
                    Update Admission
                    }
            </h5>
            <button type="button" class="btn-close" (click)="modal.dismissAll()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="addAdmissionForm">
                <div class="row gx-3">
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-na">Institute Name</label>
                        <input type="text" class="form-control" id="a-na" placeholder="Enter Institute Name"
                            formControlName="institute_name">
                        @if(addAdmissionForm.get('institute_name')?.hasError('required') &&
                        addAdmissionForm.get('institute_name')?.touched){
                        <small class="text-danger">Name is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-n">Institute Type</label>
                        <!-- <input type="text" class="form-control" id="a-n"> -->
                        <app-common-dropdowns class="first-dropdown" [defaultText]="'Select Institute Type'"
                            [dataList]="InstitueTypeList" (optionSelected)="onInstitueTypeSelected($event)"
                            [isDisabled]="true" [selectedOption]="InstitueType"
                            [clearThevalue]="InstitueType"></app-common-dropdowns>
                        @if(InstitueType == "" && instituteTypeError){
                        <small class="text-danger">Institute Type is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-n">Country</label>
                        <app-common-dropdowns class="second-dropdown" [defaultText]="'Enter Country Name'"
                            [dataList]="CountryList" (optionSelected)="onCountrySelected($event)"
                            [selectedOption]="Country" [clearThevalue]="Country"
                            (sendTheValue)="onCountryValueChange($event)"></app-common-dropdowns>
                        @if(Country == "" && countryTypeError){
                        <small class="text-danger">Country is Required</small>
                        }
                    </div>
                    <!-- </div> -->
                    <!-- <div class="row gx-3"> -->
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-na">State</label>
                        <app-common-dropdowns class="third-dropdown" [defaultText]="'Enter State Name'"
                            [dataList]="StateList" (optionSelected)="onStateSelected($event)" [selectedOption]="State"
                            [clearThevalue]="State" (sendTheValue)="onStateValueChange($event)"></app-common-dropdowns>
                        @if(State == "" && stateError){
                        <small class="text-danger">State is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-n">City</label>
                        <app-common-dropdowns class="fourth-dropdown" [defaultText]="'Enter City Name'"
                            [dataList]="CityList" (optionSelected)="onCitySelected($event)" [selectedOption]="City"
                            [clearThevalue]="City"></app-common-dropdowns>
                        @if(City == "" && cityError){
                        <small class="text-danger">City is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-n">Address</label>
                        <input type="text" class="form-control" id="a-n" placeholder="Enter Address"
                            formControlName="institute_address">
                        @if(addAdmissionForm.get('institute_address')?.hasError('required') &&
                        addAdmissionForm.get('institute_address')?.touched){
                        <small class="text-danger">Address is Required</small>
                        }
                    </div>
                    <!-- </div> -->
                    <!-- <div class="row gx-3"> -->
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-na">Address title</label>
                        <input type="text" class="form-control" id="a-na" placeholder="Enter Location title"
                            formControlName="institute_addressTitle">
                        @if(addAdmissionForm.get('institute_addressTitle')?.hasError('required') &&
                        addAdmissionForm.get('institute_addressTitle')?.touched){
                        <small class="text-danger">location is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-n">Address link</label>
                        <input type="text" class="form-control" id="a-n" placeholder="Enter Location link"
                            formControlName="institute_addressLink">
                        @if(addAdmissionForm.get('institute_addressLink')?.hasError('required') &&
                        addAdmissionForm.get('institute_addressLink')?.touched){
                        <small class="text-danger">location link is Required</small>
                        }
                        @if(addAdmissionForm.get('institute_addressLink')?.touched &&
                        addAdmissionForm.get('institute_addressLink')?.hasError('pattern')
                        ){
                        <small class="text-danger"> https://maps.google.com/maps</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-n">Site link</label>
                        <input type="text" class="form-control" id="a-n" placeholder="Enter Website link"
                            formControlName="site_link">
                        @if(addAdmissionForm.get('site_link')?.hasError('required') &&
                        addAdmissionForm.get('site_link')?.touched){
                        <small class="text-danger">Site link is Required</small>
                        }
                        @if(addAdmissionForm.get('site_link')?.touched &&
                        addAdmissionForm.get('site_link')?.hasError('pattern')
                        ){
                        <small class="text-danger"> https://</small>
                        }
                    </div>
                    <!-- </div> -->
                    <!-- <div class="row gx-3"> -->
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-na">Email</label>
                        <input type="text" class="form-control" id="a-na" placeholder="Enter Email"
                            formControlName="email_address">
                        @if(addAdmissionForm.get('email_address')?.hasError('required') &&
                        addAdmissionForm.get('email_address')?.touched){
                        <small class="text-danger">Email is Required</small>
                        }
                        @if(addAdmissionForm.get('email_address')?.touched &&
                        addAdmissionForm.get('email_address')?.hasError('pattern')
                        ){
                        <small class="text-danger">Invalid Email</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-n">Contact (Office)</label>
                        <input type="text" class="form-control" id="a-n" placeholder="Enter Phone Number"
                            formControlName="phone" minlength="10" maxlength="18" min="0">
                        @if(addAdmissionForm.get('phone')?.hasError('required') &&
                        addAdmissionForm.get('phone')?.touched){
                        <small class="text-danger">Phone is Required</small>
                        }
                        @if(addAdmissionForm.get('phone')?.touched && addAdmissionForm.get('phone')?.hasError('pattern')
                        ){
                        <small class="text-danger">Phone Number must in between 10-18</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-n">Application Fee</label>
                        <input type="text" class="form-control" id="a-n" placeholder="Enter Application Fee"
                            formControlName="application_fee" minlength="10" maxlength="18" min="0">
                        @if(addAdmissionForm.get('application_fee')?.hasError('required') &&
                        addAdmissionForm.get('application_fee')?.touched){
                        <small class="text-danger">fee is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-n">Application Start Date</label>
                        <input type="date" formControlName="start_date" (change)="getMinDate()" class="form-control"
                            id="a-n" placeholder="Enter Start Date">
                        @if(addAdmissionForm.get('start_date')?.hasError('required') &&
                        addAdmissionForm.get('start_date')?.touched){
                        <small class="text-danger">Start Date is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-n">Application Start Date</label>
                        <input type="date" formControlName="end_date" class="form-control" id="a-n"
                            placeholder="Enter End Date"  max="{{ getMaxDate() }}" (change)="getMinDate()">
                        @if(addAdmissionForm.get('end_date')?.hasError('required') &&
                        addAdmissionForm.get('end_date')?.touched){
                        <small class="text-danger">End Date is Required</small>
                        }
                    </div>
                    @if(showEntranceExam){
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-n">Entrance Exam</label>
                        <!-- <input type="text" class="form-control" id="a-n"> -->
                        <app-common-dropdowns class="first-dropdown" [defaultText]="'Select Entrance Exam'"
                            [dataList]="EntranceExamList" 
                            [selectedOption]="TestType"
                            [clearThevalue]="TestType"
                            (optionSelected)="onTestTypeSelected($event)"
                            [isDisabled]="true"></app-common-dropdowns>
                        @if(TestType == "" && entranceexamError){
                        <small class="text-danger">Entrance Exam is Required</small>
                        }
                    </div>
                    }
                    @if(showDepartment){
                    <div class="form-group col-12 col-sm-4">
                        @if(addAdmissionForm.get('department')){
                        <label for="a-n">Departments</label>
                        <input type="text" class="form-control" id="a-n" placeholder="Add Departments"
                            formControlName="department" (keyup.enter)="onEnterDepartment($event)">
                        @if(departmentTags.length == 0 &&
                        addAdmissionForm.get('department')?.touched){
                        <small class="text-danger">Add atleast one Department</small>
                        }
                        @for(departmemntTag of departmentTags; track departmemntTag){
                        <div class="tag" (click)="removeDepartmentTags(departmemntTag)">
                            {{ departmemntTag.departmentName }}
                            <span class="close-icon">&times;</span>
                        </div>
                        }
                        }
                    </div>
                    }
                    @if(showcourses){
                    <div class="form-group col-12 col-sm-4">
                        @if(addAdmissionForm.get('courses_name')){
                        <label for="a-n">Courses</label>
                        <input type="text" class="form-control" id="a-n" placeholder="Add Courses"
                            formControlName="courses_name" (keyup.enter)="onEnterKeyPress($event)">
                        @if(coursestags.length == 0 &&
                        addAdmissionForm.get('courses_name')?.touched){
                        <small class="text-danger">Add atleast one Course</small>
                        }
                        @for(courseTag of coursestags; track coursestags){
                        <div class="tag" (click)="removeCourseTag(courseTag)">
                            {{ courseTag.courseName }}
                            <span class="close-icon">&times;</span>
                        </div>
                        }
                        }
                    </div>
                    }

                    <!-- } -->
                    @if(showClasses){
                    <div class="form-group col-12 col-sm-4">
                        @if(addAdmissionForm.get('classes_name')){
                        <label for="a-n">Classes</label>
                        <input type="text" class="form-control" id="a-n" placeholder="Add Classes"
                            formControlName="classes_name" (keyup.enter)="onEnterKey($event)">
                        @if(classesTags.length == 0 &&
                        addAdmissionForm.get('classes_name')?.touched){
                        <small class="text-danger">Add atleast one Class</small>
                        }
                        @for(classTag of classesTags; track classesTags){
                        <div class="tag" (click)="removeClassTags(classTag)">
                            {{ classTag.classTitle }}
                            <span class="close-icon">&times;</span>
                        </div>
                        }
                        }
                    </div>
                    }

                    @if(showScholarship){
                    <div class="form-group col-12 col-sm-4">
                        @if(addAdmissionForm.get('scholarship_tag')){
                        <label for="a-n">Scholrships</label>
                        <input type="text" class="form-control" id="a-n" placeholder="Add Scholrships"
                            formControlName="scholarship_tag" (keyup.enter)="onEnterScholarship($event)">
                        @if(scholarshipTags.length == 0 &&
                        addAdmissionForm.get('scholarship_tag')?.touched){
                        <small class="text-danger">Add atleast one Scholrships</small>
                        }
                        @for(scholarshipTag of scholarshipTags; track scholarshipTag){
                        <div class="tag" (click)="removeScholarshipTags(scholarshipTag)">
                            {{ scholarshipTag.scholarshipName }}
                            <span class="close-icon">&times;</span>
                        </div>
                        }
                        }
                    </div>
                    }
                    @if(showDocuments){
                    <div class="form-group col-12 col-sm-4">
                        @if(addAdmissionForm.get('department')){
                        <label for="a-n">Requied Documents</label>
                        <input type="text" class="form-control" id="a-n" placeholder="Add Documents"
                            formControlName="required_documents" (keyup.enter)="onEnterDocuments($event)">
                        @if(requiredDocuments.length == 0 &&
                        addAdmissionForm.get('required_documents')?.touched){
                        <small class="text-danger">Add atleast one Document</small>
                        }
                        @for(requiredDocument of requiredDocuments; track requiredDocument){
                        <div class="tag" (click)="removeDocumentsTags(requiredDocument)">
                            {{ requiredDocument.documentName }}
                            <span class="close-icon">&times;</span>
                        </div>
                        }
                        }
                    </div>
                    }
                </div>

                <div class="row gx-3">
                    <div class="form-group col-12">
                        <label for="a-na">Description</label>
                        <!-- <input type="text" class="form-control" id="a-na"> -->
                        <textarea rows="4" name="" id="" class="form-control" placeholder="Enter Description"
                            formControlName="description" (input)="updateCharCount()"></textarea>
                        <small class="">{{ charCount }}/400</small>
                        @if(addAdmissionForm.get('description')?.hasError('required') &&
                        addAdmissionForm.get('description')?.touched){
                        <small class="text-danger">Description is Required</small>
                        }
                    </div>
                </div>
            </form>
            <div class="dropzone-admin mb-0">
                <label>Media <span class="font-danger">*</span></label>
                @if(FormName == "Add"){
                <form class="dropzone" id="multiFileUpload">
                    <ngx-dropzone (change)="onSelect($event)" accept="image/jpeg,image/jpg,image/png"
                        [multiple]="false">
                        <ngx-dropzone-label>
                            <div class="dz-message needsclick">
                                <i class="fas fa-cloud-upload-alt"></i>
                                <h6 class="f-w-700 mb-0">
                                    Drop files here or click to upload.
                                </h6>
                            </div>
                        </ngx-dropzone-label>
                        @for(f of files; track f){
                        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" [removable]="true"
                            (removed)="onRemove(f)" [file]="f">
                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>

                        </ngx-dropzone-image-preview>
                        }
                    </ngx-dropzone>
                </form>
                } @else if (FormName == "Edit"){
                <!-- for update case  -->
                <div class="image-gallery">
                    <div *ngFor="let image of images" class="image-container">
                        <app-feather-icons [icon]="'x'" class="closeImageIcon"
                            (click)="removeFile(image)"></app-feather-icons>
                        <img [src]="image.imageBase64" alt="Image">
                    </div>
                </div>
                <div class="image-gallery">
                    <div class="upload-container" (click)="fileInput.click()">
                        <app-feather-icons [icon]="'upload'"></app-feather-icons>
                        <input #fileInput type="file" style="display: none;" accept="image/jpeg,image/jpg,image/png"
                            (change)="onFileSelected($event)">
                        Upload more Files
                    </div>
                </div>
                }
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-dashed color-2 btn-pill" (click)="modal.dismissAll()">Cancel</button>
            <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="onSubmit()">@if(FormName == "Add"){
                Add Admission
                }@else{
                Update Admission
                }</button>
        </div>
    </div>

</ng-template>

<ng-template #deleteModal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" (click)="modal.dismissAll()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="text">
                Are You Sure you want to delete this Admission
            </div>
            <div class="deleteButtons">
                <button type="button" class="btn btn-dashed color-2 btn-pill"
                    (click)="modal.dismissAll()">Cancel</button>
                <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="deleteAdmission()">
                    Delete
                </button>
            </div>
        </div>
    </div>
</ng-template>