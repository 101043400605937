import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { MyListingComponent } from './my-listing/my-listing.component';
import { CreatePropertyComponent } from './create-property/create-property.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { FavouritesComponent } from './favourites/favourites.component';
import { CardsPaymentComponent } from './cards-payment/cards-payment.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ComparePropertyComponent } from './compare-property/compare-property.component';
import { AddInstituteComponent } from './manage-institute/add-institute/add-institute.component';
import { AddAdmissionComponent } from './manage-admission/add-admission/add-admission.component';
import { AddJobComponent } from './manage-jobs/add-job/add-job.component';
// import { AddBooksComponent } from './manage-books/add-books/add-books.component';
// import { AddScholarshipComponent } from './manage-scholarship/add-scholarship/add-scholarship.component';
import { AddScholarshipComponent } from './manage-scholarship/add-scholarship/add-scholarship.component';
import { AddBooksComponent } from './manage-library/add-books/add-books.component';
import { ManageCvComponent } from './manage-cv\'s/manage-cv/manage-cv.component';
import { CvEditComponent } from './manage-cv\'s/cv-edit/cv-edit.component';
import { Cv1Component } from './manage-cv\'s/cv-design/cv1/cv1.component';
import { Cv2Component } from './manage-cv\'s/cv-design/cv2/cv2.component';
import { Cv3Component } from './manage-cv\'s/cv-design/cv3/cv3.component';
import { Cv4Component } from './manage-cv\'s/cv-design/cv4/cv4.component';
import { Cv5Component } from './manage-cv\'s/cv-design/cv5/cv5.component';
import { AddHostelComponent } from './manage-hostel/add-hostel/add-hostel.component';
import { TeacherTestComponent } from './manage-teacherTest/teacher-test/teacher-test.component';
import { QuestionTemplateComponent } from './manage-teacherTest/question-template/question-template.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'user-dashboard',
    pathMatch: 'full'
  },
  {
    path: 'user-dashboard',
    component: UserDashboardComponent,
  },
  {
    path: 'my-listing',
    component: MyListingComponent,
  },
  {
    path: 'profile',
    component: CreatePropertyComponent,
  },
  {
    path: 'my-profile',
    component: MyProfileComponent,
  },
  {
    path: 'favourite',
    component: FavouritesComponent,
  },
  {
    path: 'compare-property',
    component: ComparePropertyComponent
  },
  {
    path: 'cards-payment',
    component: CardsPaymentComponent,
  },
  {
    path: 'privacy',
    component: PrivacyComponent
  },
  {
    path: 'add-institute',
    component: AddInstituteComponent
  },
  {
    path: 'add-admission',
    component: AddAdmissionComponent
  },
  {
    path: 'add-job',
    component: AddJobComponent
  },
  {
    path: 'add-library',
    component: AddBooksComponent
  },
  {
    path: 'add-scholarship',
    component: AddScholarshipComponent
  },
  {
    path: 'add-books',
    component: AddBooksComponent
  },
  {
    path: 'add-hostel',
    component: AddHostelComponent,
  },
  {
    path: 'add-cv',
    component: ManageCvComponent
  },
  {
    path: 'teacher-test',
    component: TeacherTestComponent
  },
  { path: 'question/:id',
   component: QuestionTemplateComponent 
  },
  { path: '', redirectTo: '/teacher-test', pathMatch: 'full' },
  {
    path: 'cv-templates/cv/:templateId',
    component: CvEditComponent
  }
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserPanelRoutingModule { }
