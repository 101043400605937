<div class="container">
    <div class="cv-header">
        <div class="profile-picture">
            <img [src]="userData?.personalDetails?.data?.profileBase64 || 'https://default-image-url.com/default-profile.png'"
                class="img-fluid bg-img" alt="profile-image" />
        </div>
        <div class="personal-info">
            <h1> {{userData?.personalDetails.data.firstName + " " + userData?.personalDetails.data.lastName}}
            </h1>
            <p class="job-title"> {{ userData?.personalDetails.data.gender }}
            </p>
            <div class="contact-info">
                <p><i class="fa fa-phone" aria-hidden="true"></i> {{userData?.personalDetails.data.phoneNumber ||"+92
                    123 4567890"}}
                </p>
                <p><i class="fa fa-envelope" aria-hidden="true"></i> {{
                    userData?.personalDetails.data.email||"MYEMAILgmail.com"}}</p>
                <p><i class="fa fa-map-marker" aria-hidden="true"></i> {{userData?.personalDetails.data.address + ", " +
                    userData?.personalDetails.data.city + ",
                    " + userData?.personalDetails.data.state + ", " + userData?.personalDetails.data.country ||"253rd
                    home, 14th street, Magistrate colony,
                    Islamabad, Pakistan."}}</p>
            </div>
        </div>
    </div>
    <div class="cv-body">
        <div class="skills">
            <h2>Skills</h2>
            <ul>

                <li *ngFor="let skill of userData?.skillsDetails.data">
                    <span class="skill-name"> {{ skill?.skillTitle }}
                    </span>
                    <span class="years-of-experience">{{skill?.percentage + "%"}}</span>
                </li>

            </ul>
        </div>
        <div class="experience">
            <h2>Experience</h2>
            <div class="exp-item" *ngFor="let experience of userData?.experienceDetails?.data">
                <h3> {{ experience?.role || "SENIOR UI AND UX DESIGNER"}}
                </h3>
                <p class="dates">{{experience?.startDate?.substring(0,4) +" - " +
                    experience?.endDate?.substring(0,4)}}</p>
                <ul class="data">
                    <li> {{experience?.description || "lorem ipsum dolor sit amet consectetur adipisicing elit. At
                        iure mollitia commodi odio ipsa qui, similique perferendis in sequi, eligendi inventore
                        laborum quos numquam voluptatibus illo delectusamet cumque explicabo"}}</li>
                </ul>
            </div>
        </div>
        <div class="education">
            <h2>Education</h2>
            <div class="edu-item" *ngFor="let education of userData?.educationDetails?.data">
                <h3>{{ education?.degreeTitle || "Bachelor of Science in Computer Science"
                    }} </h3>
                <p class="dates"> {{education?.startDate?.substring(0,4) +" - " +
                    education?.endDate?.substring(0,4)}}</p>
                <p class="institution"> {{education?.institute || "UNIVERSITY OF EDUCATION"}}
                </p>
            </div>
        </div>
    </div>
</div>