import { ChangeDetectionStrategy, Component, inject, TemplateRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { PropertyService } from 'src/app/shared/services/property.service';

@Component({
  selector: 'app-add-scholarship',
  templateUrl: './add-scholarship.component.html',
  styleUrl: './add-scholarship.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddScholarshipComponent {

  public __apiCall = inject(ApiCallService);
  public _toastr = inject(ToastrService);
  public propertyService = inject(PropertyService);
  public _spinner = inject(NgxSpinnerService)

  public themeLogo = 'assets/images/logo/2.png';
  public footerLogo = 'assets/images/logo/footer-logo.png';
  public bgImage = 'assets/images/banner/banner.webp';
  public title = 'Dashboard';
  public parent = 'Scholarship';
  public child = 'Add Scholarship';
  scholarshipsData: any;
  isDataLoading: boolean = true;
  public isSpinnerShown = false;
  tagClass: string;
  addScholarshipForm: FormGroup;
  public FormName: string = "Add";
  scholarshipDescriptionCharCount: number = 0;
  howToApplyCharCount: number = 0;
  public selectedImage: string;




  ngOnInit() {
    // this.propertyService.getLatestScholarships().subscribe(
    //   (response) => {
    //     this.scholarshipsData = response;
    //     this.isDataLoading = false;
    //   },
    //   (error) => {
    //     console.error('Error fetching scholarships data', error);
    //   }

    // );
    this.getScholarships();
    this.getCountry();
    this.getScholarshipTypes();
  }

  getScholarships() {
    this.isDataLoading = true;
    this.__apiCall.GetCallWithToken("Dashboard/GetScholarships").subscribe((response) => {
      if (response.responseCode == 200) {
        this.scholarshipsData = response.data;
        this.isDataLoading = false;
      } else {
        this.scholarshipsData = [];
        this.isDataLoading = false;
      }
    }, (error) => {
      this._toastr.error('Error fetching institutes data', error);
      this.isDataLoading = false;
    }
    )

  }

  constructor(public modal: NgbModal, private fb: FormBuilder) {
    this.addScholarshipForm = this.fb.group({
      scholarship_name: ["", Validators.required],
      institute_name: ["", Validators.required],
      scholarship_address: ["", Validators.required],
      scholarship_addressTitle: ["", Validators.required],
      scholarship_addressLink: ["", [Validators.required, Validators.pattern('https://maps.google.com/maps.+')]],
      application_Link: ["", [Validators.required, Validators.pattern('https://.+')]],
      email_address: ["", [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
      scholarship_phone: ["", [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10,18}$"), Validators.minLength(10), Validators.maxLength(18)]],
      start_date: ["", [Validators.required,]],
      end_date: ["", Validators.required],
      minimum_cgpa: ["", [Validators.required, this.cgpaValidator]],
      duration: ["", Validators.required],
      scholarship_amount: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
      scholarship_fee: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
      courses_name: ["", Validators.required],
      required_documents: ["", Validators.required],
      coverage_Tags: ["", Validators.required],
      scholarship_description: ["", [Validators.required, this.minWordsValidator(400)]],
      how_to_apply: ["", [Validators.required, this.minWordsValidator(400)]],
    });
  }

  addCard(content: TemplateRef<any>) {
    this.updateScholarshipId = 0;
    this.coursestags = [];
    this.requiredDocuments = [];
    this.coverageTags = [];
    this.Duration = "";
    this.ScholarshipType = "";
    this.City = "";
    this.Country = "";
    this.State = "";
    this.modal.open(content, { centered: true, size: 'lg' })
    this.FormName = "Add";
    this.addScholarshipForm.reset();

  }
  editCard(content: TemplateRef<any>, id: number) {
    this.modal.open(content, { centered: true, size: 'lg' })
    this.FormName = "Edit";
    this.addScholarshipForm.reset();
    this.Country = "";
    this.State = "";
    this.City = "";
    this.Duration = "";
    this.ScholarshipType = "";
    this.coursestags = [];
    this.requiredDocuments = [];
    this.coverageTags = [];
    this.editScholarship(id);
  }
  //////////////////////editScholarship//////////////////////////////
  updateScholarshipId: number = 0;
  scholarshipByIdData: any;

  editScholarship(id: number) {
    this.updateScholarshipId = id;
    this.isSpinnerShown = true;
    this._spinner.show();

    this.__apiCall.PostCallWithoutToken(null, `Dashboard/GetScholarshipById?ScholarshipId=${id}`).subscribe((res) => {
      if (res.responseCode === 200) {
        

        this.scholarshipByIdData = res.data;

        this.addScholarshipForm.get('scholarship_name')?.setValue(this.scholarshipByIdData?.scholarshipName);
        this.addScholarshipForm.get('institute_name')?.setValue(this.scholarshipByIdData?.instituteName);
        this.addScholarshipForm.get('scholarship_address')?.setValue(this.scholarshipByIdData?.address);
        this.addScholarshipForm.get('scholarship_addressTitle')?.setValue(this.scholarshipByIdData?.locationTitle);
        this.addScholarshipForm.get('scholarship_addressLink')?.setValue(this.scholarshipByIdData?.locationSrc);
        this.addScholarshipForm.get('application_Link')?.setValue(this.scholarshipByIdData?.applicationLink);
        this.addScholarshipForm.get('email_address')?.setValue(this.scholarshipByIdData?.email);
        this.addScholarshipForm.get('scholarship_phone')?.setValue(this.scholarshipByIdData?.contactNumber);
        this.addScholarshipForm.get('start_date')?.setValue(this.scholarshipByIdData?.startDate?.substring(0, 10));
        this.addScholarshipForm.get('end_date')?.setValue(this.scholarshipByIdData?.endDate?.substring(0, 10));
        this.addScholarshipForm.get('minimum_cgpa')?.setValue(this.scholarshipByIdData?.minimumCGPA);
        this.addScholarshipForm.get('scholarship_amount')?.setValue(this.scholarshipByIdData?.amount);
        this.addScholarshipForm.get('scholarship_fee')?.setValue(this.scholarshipByIdData?.applicationFee);
        this.addScholarshipForm.get('scholarship_description')?.setValue(this.scholarshipByIdData?.description);
        this.addScholarshipForm.get('how_to_apply')?.setValue(this.scholarshipByIdData?.howToApply);

        this.Country = this.scholarshipByIdData?.country;
        this.State = this.scholarshipByIdData?.state;
        this.City = this.scholarshipByIdData?.city;
        this.Duration = this.scholarshipByIdData?.duration;
        this.ScholarshipType = this.scholarshipByIdData?.type;
        this.coursestags = this.scholarshipByIdData?.courses;
        this.requiredDocuments = this.scholarshipByIdData?.documents;
        this.coverageTags = this.scholarshipByIdData?.coverages;
        this.images = this.scholarshipByIdData?.images;



        this.updateScholarshipDescriptionCharCount();
        this.updateHowToApplyCharCount();
        this.isSpinnerShown = false;
        this._spinner.hide();
      }
      else {
        this._toastr.error(res.responseMessage);
        this.isSpinnerShown = false;
        this._spinner.hide();
      }
    });

    this.addScholarshipForm.updateValueAndValidity();
  }

  delId: number;
  deleteCard(content: TemplateRef<any>, id: number) {
    this.modal.open(content, { centered: true, size: 'sm' });
    this.delId = id;
  }

  deleteScholarship() {
    this.isSpinnerShown = true;
    this._spinner.show();
    this.__apiCall
      .PostCallWithoutToken(null, `Dashboard/DeleteScholarship?id=${this.delId}`).subscribe((res) => {
        if (res.responseCode === 200) {
          this._toastr.success(res.responseMessage);
           this.getScholarships();
          this.modal.dismissAll();
          this.isSpinnerShown = false;
          this._spinner.hide();

        }
        else {
          this._toastr.error(res.responseMessage);
          this.modal.dismissAll();
          this.isSpinnerShown = false;
          this._spinner.hide();
        }
      })
  }

  //////////////////////Deletion Confirmation//////////////////////
  openModal(content: TemplateRef<any>, id: number) {
    this.modal.open(content, { centered: true, size: 'md' })
  }

  public Options = {
    items: 1,
    loop: true,
    nav: true,
    dots: true,
    autoplay: true,
    autoplayTimeout: 2000,
    navText: [
      "<i class='fa fa-chevron-left'></i>",
      "<i class='fa fa-chevron-right'></i>",
    ],
  };


  files: File[] = [];
  images: { imageBase64: string }[] = [];

  onSelect(event: NgxDropzoneChangeEvent) {
    const newFiles = event.addedFiles;
    this.files.push(...newFiles);
    // console.log("files", this.files)

    newFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {

        const sizeInKB = Math.round(file.size / 1024);
        const istoDel = this.checkImgeSizeValidity(sizeInKB, file)
        if (!istoDel) {
          const base64String = e.target.result as string;
          this.images.push({ imageBase64: base64String });
        }
      };
      reader.readAsDataURL(file);
    });

  }

  onRemove(event: File) {
    const index = this.files.indexOf(event);
    if (index !== -1) {
      this.files.splice(index, 1);
      this.images.splice(index, 1);
    }
  }
  imageSizeError: boolean = false
  checkImgeSizeValidity(size: number, file: any) {
    if (size > 1024) {
      this.imageSizeError = true;
      this.onRemove(file)
      return true;

    }
    else {
      this.imageSizeError = false;
      return false;
    }
  }


  /////////////////////////////////////////////////////
  //////////// image update case //////////////////////
  //////////////////////////////////////////////////



  ////////////////////////////////////////////
  //////////// code for image /////////////////////
  //////////////////////////////////////////////


  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {

        const sizeInKB = Math.round(file.size / 1024);
        const isToDel = this.checkImgeSizeValidity(sizeInKB, file)
        if (!isToDel) {
          const base64String = reader.result as string;
          this.images.push({ imageBase64: base64String });
        };
      };
    }
  }

  removeFile(file: any): void {

    this.images = this.images.filter((t: any) => t !== file);

  }



  ////////////////////////////////////////////
  //////////// code for image /////////////////////
  //////////////////////////////////////////////


  ////////////////////////////////////////////
  // code for Custom NonSearchable  DropDown for ScholarshipType Starts Here
  //////////////////////////////////////////////
  ScholarshipType: string = ""
  ScholarshipTypeList = [];

  getScholarshipTypes() {
    this.__apiCall
      .GetCallWithoutToken("DropDown/GetScholarshipDropDown")
      .subscribe((response) => {
        this.ScholarshipTypeList = response?.data;
      });
  }

  onScholarshipTypeSelected(option: any) {
    this.ScholarshipType = option.value;
  }
  ////////////////////////////////////////////
  // code for Custom NonSearchable  DropDown for ScholarshipType Ends Here
  //////////////////////////////////////////////

  ////////////////////////////////////////////
  // code for Custom NonSearchable  DropDown for  Duration Starts Here
  //////////////////////////////////////////////

  Duration: string = "";

  DurationTimeList = [
    { id: 1, value: "One Year" },
    { id: 2, value: "Two Year" },
    { id: 3, value: "Full Degree" },

  ];

  onDurationSelected(option: any) {
    this.Duration = option.value;

  }

  ////////////////////////////////////////////
  // code for Custom NonSearchable  DropDown for  Duration Ends Here
  //////////////////////////////////////////////

  ////////////////////////////////////////////////////
  /////////  Country drop down  //////////
  ///////////////////////////////////////////////////// 
  CountrysearchTerm: string = "";
  Country: string = "";
  State: string = '';
  City: string = '';
  CountryList = []
  SearchTermCountry(searchTerm: string) {
    this.CountrysearchTerm = searchTerm;
    this.Country = searchTerm;
  }
  getCountry() {
    this.__apiCall
      .GetCallWithoutToken('DropDown/GetCountryDropDown')
      .subscribe((response: any) => {
        if (response.responseCode === 200) {
          this.CountryList = response?.data;
        }
      })

  }
  scholarshipTypeError: boolean = false;
  countryTypeError: boolean = false;
  stateError: boolean = false;
  cityError: boolean = false;
  ShowError() {
    this.scholarshipTypeError = this.ScholarshipType == "" ? true : false;
    this.countryTypeError = this.Country == "" ? true : false;
    this.stateError = this.State == "" ? true : false;
    this.cityError = this.City == "" ? true : false;
  }

  onCountrySelected(option: any) {
    if (this.Country != option.value) {
      this.State = '';
      this.City = '';
    }
    this.Country = option.value;
    this.getStateList(option.id);

  }
  onCountryValueChange(searchValue: any) {
    this.Country = searchValue
    if (this.Country === '') {
      this.State = '';
      this.City = ''
    }
  }

  ////////////////////////////////////////////////////
  /////////  State drop down data //////////
  ////////////////////////////////////////////////////

  StatesearchTerm: string = "";

  StateList = []
  SearchTermState(searchTerm: string) {
    this.StatesearchTerm = searchTerm;
    this.State = searchTerm;
  }

  onStateSelected(option: any) {
    if (this.State !== option.value) {
      this.City = '';
    }
    this.State = option.value;
    this.getCityList(option.id);

  }
  onStateValueChange(searchValue: any) {
    this.State = searchValue
    if (this.State === '') {
      this.City = ''
    }
  }

  getStateList(countryId: number) {
    this.__apiCall
      .GetCallWithoutToken('DropDown/GetStateDropDown?CountryId=' + countryId)
      .subscribe((response: any) => {
        if (response.responseCode === 200) {
          this.StateList = response?.data;
        }
      });
  }


  ////////////////////////////////////////////////////
  /////////  City drop down data //////////
  ////////////////////////////////////////////////////


  CityList = []
  onCitySelected(option: any): void {
    this.City = option.value;
  }

  getCityList(stateId: number) {
    this.__apiCall.GetCallWithoutToken('DropDown/GetCityDropDown?StateId=' + stateId).subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.CityList = response?.data;
        }
      }
    )
  }

  ///////////////////////////////////////////////////////////
  // Function to add COURSE tag functionality start here
  coursestags: any[] = [];

  addCourseTag(): void {
    const tagInput = this.addScholarshipForm.get("courses_name")?.value.trim();

    if (
      tagInput &&
      !this.coursestags.some((tag) => tag.courseName === tagInput)
    ) {
      const newTag = { courseName: tagInput };
      this.coursestags.push(newTag);
      this.addScholarshipForm.get("courses_name")?.setValue("");
    }
  }

  removeCourseTag(tag: any): void {
    this.coursestags = this.coursestags.filter((t) => t !== tag);
  }

  onEnterKeyPress(event: Event): void {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.addCourseTag();
    }
  }

  // Function to add COURSE tag functionality end here
  /////////////////////////////////////////////////////////////////////////


  ///////////////////////////////////////////////////////////
  // Function to add Required Documents tag functionality start here
  requiredDocuments: any[] = [];

  addDocumentsTags(): void {
    const tagInput = this.addScholarshipForm.get("required_documents")?.value.trim();

    if (tagInput && !this.requiredDocuments.some((tag) => tag.documentName === tagInput)) {
      const newTag = { documentName: tagInput };
      this.requiredDocuments.push(newTag);
      this.addScholarshipForm.get("required_documents")?.setValue("");
    }
  }

  removeDocumentsTags(tag: any): void {
    this.requiredDocuments = this.requiredDocuments.filter((t) => t !== tag);
  }

  onEnterDocuments(event: Event): void {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.addDocumentsTags();
    }
  }
  // Function to add Required Documents tag functionality end here
  /////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////
  // Function to add Coverage Tags functionality start here
  coverageTags: any[] = [];

  addCoverageTags(): void {

    const tagInput = this.addScholarshipForm.get("coverage_Tags")?.value.trim();

    if (
      tagInput &&
      !this.coverageTags.some((tag) => tag.coverageName === tagInput)
    ) {
      const newTag = { coverageName: tagInput };
      this.coverageTags.push(newTag);
      this.addScholarshipForm.get("coverage_Tags")?.setValue("");
    }
  }

  removeCoverageTags(tag: any): void {
    this.coverageTags = this.coverageTags.filter((t) => t !== tag);
  }

  onEnterCoverage(event: Event): void {

    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.addCoverageTags();
    }
  }
  // Function to add  Coverage Tagsfunctionality end here
  /////////////////////////////////////////////////////////////////////////

  ////////////////////////futureDateValidator//////////////////////////////////////
  minEndDate:any;
  updateEndDateMin(): void {
    const startDate = this.addScholarshipForm.get('start_date')?.value;
    this.minEndDate = startDate ? startDate : null;
  }
  

  // Method for word count validation
  minWordsValidator(minWords: number) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value || '';
      const wordCount = value.trim().split(/\s+/).length;
      return wordCount < minWords ? { minWords: true } : null;
    };
  }


  // Method to update job description char count
  updateScholarshipDescriptionCharCount(): void {
    const scholarshipdescription = this.addScholarshipForm.get("scholarship_description")?.value;
    this.scholarshipDescriptionCharCount = scholarshipdescription?.length || 0;
  }

  // Method to update how to apply char count
  updateHowToApplyCharCount(): void {
    const howToApply = this.addScholarshipForm.get("how_to_apply")?.value;
    this.howToApplyCharCount = howToApply?.length || 0;
  }
  // Custom validator method
  cgpaValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    return value && (Number(value) > 4) ? { greaterThanFour: true } : null;
  }
  checkScholarshipFieldsValidity(): boolean {
    if (this.coursestags.length < 1 || this.requiredDocuments.length < 1 || this.coverageTags.length < 1) {
      return true;
    }
    return false;
  }
  addControls() {
    // Adding tag controls unconditionally
    if (!this.addScholarshipForm.contains('courses_name')) {
      this.addScholarshipForm.addControl('courses_name', new FormControl("", Validators.required));
    }

    if (!this.addScholarshipForm.contains('required_documents')) {
      this.addScholarshipForm.addControl('required_documents', new FormControl("", Validators.required));
    }

    if (!this.addScholarshipForm.contains('coverage_Tags')) {
      this.addScholarshipForm.addControl('coverage_Tags', new FormControl("", Validators.required));
    }

    // Update the form's validity
    this.addScholarshipForm.updateValueAndValidity();
  }

  onSubmit() {
    this.addScholarshipForm.markAllAsTouched();
    const tagsValidity = this.checkScholarshipFieldsValidity();
    this.ShowError();

    const description = this.addScholarshipForm.get("scholarship_description")?.value;

    if (
      this.Country === "" ||
      this.State === "" ||
      this.City === "" ||
      this.ScholarshipType === "" ||
      this.Duration === "" ||
      this.images.length < 1 ||
      tagsValidity ||
      description?.length < 1
    ) {
      this._toastr.error("Invalid Form");
      this.addControls();
    }
    else {
      const payload = this.createApiPayload();

      this.__apiCall
        .PostCallWithoutToken(payload, "Dashboard/SaveScholarship")
        .subscribe((response: any) => {
          this.addControls();
          if (response.responseCode == 200) {
            this._toastr.success(response.responseMessage);
            this.modal.dismissAll();
             this.getScholarships();
          } else {
            this._toastr.error(response.responseMessage);
          }
        })
    }


  }
  createApiPayload() {
    const payload = {
      id: this.updateScholarshipId,
      scholarshipName: this.addScholarshipForm.get("scholarship_name")?.value,
      instituteName: this.addScholarshipForm.get("institute_name")?.value,
      address: this.addScholarshipForm.get("scholarship_address")?.value,
      locationTitle: this.addScholarshipForm.get("scholarship_addressTitle")?.value,
      locationSrc: this.addScholarshipForm.get("scholarship_addressLink")?.value,
      applicationLink: this.addScholarshipForm.get("application_Link")?.value,
      email: this.addScholarshipForm.get("email_address")?.value,
      contactNumber: this.addScholarshipForm.get("scholarship_phone")?.value,
      startDate: this.addScholarshipForm.get("start_date")?.value,
      endDate: this.addScholarshipForm.get("end_date")?.value,
      minimumCGPA: this.addScholarshipForm.get("minimum_cgpa")?.value,
      amount: this.addScholarshipForm.get("scholarship_amount")?.value,
      applicationFee: this.addScholarshipForm.get("scholarship_fee")?.value,
      description: this.addScholarshipForm.get("scholarship_description")?.value,
      howToApply: this.addScholarshipForm.get("how_to_apply")?.value,

      country: this.Country,
      state: this.State,
      city: this.City,
      duration: this.Duration,
      type: this.ScholarshipType,
      courses: this.coursestags,
      documents: this.requiredDocuments,
      coverages: this.coverageTags,
      images: this.images,
    };
    return payload;
  }



}
