import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild, inject } from "@angular/core";
import {
  agency,
  baths,
  beds,
  category,
  propertyStatus,
  propertyType,
  rooms,
  location,
} from "../../../../../shared/data/advance-filter";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApiCallService } from "src/app/shared/services/api-call.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { setValue } from "@ngxs/store";
import { AuthService } from "src/app/shared/services/auth.service";
import { CommonUtilsServiceService } from "src/app/shared/services/common-utils-service.service";
import { Subject, takeUntil } from "rxjs";
import { ErrorHandlingService } from "src/app/shared/services/error-handling.service";
import { HttpErrorResponse } from "@angular/common/http";
import intlTelInput from 'intl-tel-input';

interface DropdownItem {
  id: number;
  value: string;
}

@Component({
  selector: "app-basic-information",
  templateUrl: "./basic-information.component.html",
  styleUrls: ["./basic-information.component.scss"],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class BasicInformationComponent implements OnInit, OnDestroy {
  @ViewChild("fileInput") fileInput!: ElementRef;

  private destroy$ = new Subject<void>();
  private intlTelInputInstance: any;

  public isSpinnerShown = false;

  //VARIABLES
  userForm: FormGroup;
  Country: string = "";
  State: string = "";
  City: string = "";
  profileBase64: string = "";
  userPersonalData: any;
  imagePreview: string = "";
  contactNumber: string = '';
  email: string = "";

  //SERVICES
  private readonly _apiCall = inject(ApiCallService);
  private readonly _toaster = inject(ToastrService);
  private readonly _spinner = inject(NgxSpinnerService);
  private readonly _authService = inject(AuthService);
  private readonly commonUtilsService = inject(CommonUtilsServiceService);
  private readonly errorHandlerService = inject(ErrorHandlingService);

  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef, private renderer: Renderer2, private el: ElementRef) {
    this.userForm = this.fb.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      phoneNumber: [
        "",
        [
          Validators.required,
          Validators.pattern("^((\\+91-?)|0)?[0-9]{10,18}$"),
          Validators.minLength(10),
          Validators.maxLength(18),
        ],
      ],
      dateOfBirth: ["", Validators.required],
      address: ["", Validators.required],
      // username: ['', Validators.required],
      // email: ['', [
      //   Validators.required, Validators.email, Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
      // ]],
      description: ["", Validators.required],
    });

    // const userDetails = this._authService.getUserDetails();
    // this.profileBase64 = this._authService.generateFallbackImage(userDetails.firstName);
  }

  ngOnInit(): void {
    // Initialize intl-tel-input
    const inputElement = this.el.nativeElement.querySelector('#phoneNumber');
    if (inputElement) {
      this.intlTelInputInstance = intlTelInput(inputElement, {
        initialCountry: 'pk',
        separateDialCode: true,
        utilsScript: 'node_modules/intl-tel-input/build/js/utils.js'
      });

      // Add a listener for formatting the phone number
      this.renderer.listen(inputElement, 'input', (event) => {
        this.formatPhoneNumber(event);
      });
    }

    this.getCountry();
    this.getPersonalDetails();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  formatPhoneNumber(event: any): void {
    let phoneNumber = event.target.value.replace(/\D/g, '');

    if (this.intlTelInputInstance) {
      const countryCode = this.intlTelInputInstance.getSelectedCountryData().dialCode;

      // Ensure the country code is added only once
      if (!phoneNumber.startsWith(countryCode)) {
        phoneNumber = `+${countryCode}${phoneNumber}`;
      }
    }

    event.target.value = phoneNumber;
    this.contactNumber = phoneNumber;
  }

  triggerFileInput() {
    this.fileInput.nativeElement.click();
  }

  // FUNCTION TO GET PERSONAL DETAILS
  private async getPersonalDetails(): Promise<void> {
    this.isSpinnerShown = true;
    this._spinner.show();
    try {
      const res = await this._apiCall
        .GetCallWithToken("AdmissionLeloUsers/GetTeacherPersonalDetails")
        .pipe(takeUntil(this.destroy$))
        .toPromise();
      if (res.responseCode == 200) {
        this.userPersonalData = res.data;
        const userData = res.data;
        this.userForm.get("firstName")?.setValue(userData?.firstName);
        this.userForm.get("lastName")?.setValue(userData?.lastName);
        this.userForm.get("phoneNumber")?.setValue(userData?.phoneNumber);
        this.userForm
          .get("dateOfBirth")
          ?.setValue(userData?.dateOfBirth?.substring(0, 10));
        this.userForm.get("address")?.setValue(userData?.address);
        this.userForm.get("description")?.setValue(userData?.description);
        this.Country = userData?.country;
        this.State = userData?.state;
        this.City = userData?.city;
        this.gender = userData?.gender;
        this.email = userData?.email;
        this.profileBase64 = userData?.profileBase64;
        if (this.profileBase64 == "no file found") {
          const userDetails = this._authService.getUserDetails();
          this.profileBase64 = JSON.parse(userDetails)?.profileBase64;
        }
        if (this.profileBase64?.length < 20) {
          this.profileBase64 =
            this._authService.generateFallbackImage(userData?.firstName);
        }
        
        const userDetailsString = localStorage.getItem("userDetails");
        if (userDetailsString) {
          const userDetails = JSON.parse(userDetailsString);
          userDetails.profileBase64 = this.profileBase64;
          userDetails.firstName = this.userForm.get("firstName")?.value;
          userDetails.lastName = this.userForm.get("lastName")?.value;
          userDetails.email = this.email;
          localStorage.setItem("userDetails", JSON.stringify(userDetails));
        }

        // Notify that user details have been updated
        this.commonUtilsService.notifyUserDetailsUpdated();
        this.userForm.updateValueAndValidity();
      } else {
        this.errorHandlerService.handleResponseError(res);
      }
    } catch (error) {
      this.errorHandlerService.handleHttpError(error as HttpErrorResponse);
    } finally {
      this.isSpinnerShown = false;
      this._spinner.hide();
      this.cdr.detectChanges();
    }
  }

  // FUNCTION TO SAVE PERSONAL DETAILS IMAGE
  onFileSelect(event: any) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imagePreview = e.target.result;
        this.profileBase64 = e.target.result;
        this.userForm.patchValue({ profileBase64: this.profileBase64 });

        const payload = {
          imageBase64: this.profileBase64,
        };

        this._apiCall
          .PostCallWithToken(
            payload,
            "AdmissionLeloUsers/SaveAndUpdateTeacherImage"
          )
          .pipe(takeUntil(this.destroy$))
          .subscribe((response) => {
            if (response.responseCode == 200) {
              // Update profileBase64 in userDetails stored in localStorage
              const userDetailsString = localStorage.getItem("userDetails");
              if (userDetailsString) {
                const userDetails = JSON.parse(userDetailsString);
                userDetails.profileBase64 = this.profileBase64;
                localStorage.setItem("userDetails", JSON.stringify(userDetails));
              }

              // Notify that user details have been updated
              this.commonUtilsService.notifyUserDetailsUpdated();


              this._toaster.success('Profile Image Updated Successfully', 'Success');
            } else {
              this.errorHandlerService.handleResponseError(response);
            }
          }, (error) => {
            this.errorHandlerService.handleHttpError(error);
          });
      };
      reader.readAsDataURL(file);
    }
  }

  // FUNCTION TO SAVE PERSONAL DETAILS
  public async savePersonalDetails(): Promise<void> {
    const isdataMatch = this.matchPayload();
    if (isdataMatch) {
      this._toaster.success("Already Uptodate");
      return;
    }
    this.userForm.markAllAsTouched();
    this.ShowError();
    console.log("form val", this.userForm.value);
    if (
      this.userForm.invalid ||
      this.City == "" ||
      this.Country == "" ||
      this.State == "" ||
      // this.profileBase64 == "" ||
      this.gender == ""
    ) {
      this._toaster.error("Invalid Form");
    } else {
      try {
        this.isSpinnerShown = true;
        this._spinner.show();
        const payload = this.createApiPayload();
        const response = await this._apiCall
          .PostCallWithToken(payload, "AdmissionLeloUsers/SavePersonalDetails")
          .pipe(takeUntil(this.destroy$))
          .toPromise();
        if (response.responseCode == 200) {
          this._toaster.success(response.responseMessage);
          this.userForm.reset();
          this.City = "";
          this.Country = "";
          this.State = "";
          this.gender = "";
          this.profileBase64 = "";
          this.isSpinnerShown = false;
          this._spinner.hide();
          const userDetailsString = localStorage.getItem("userDetails");
          if (userDetailsString) {
            const userDetails = JSON.parse(userDetailsString);
            
            console.log("imagelinkbase",this.profileBase64)
            userDetails.profileBase64 = this.profileBase64;
            userDetails.firstName = this.userForm.get("firstName")?.value;
            userDetails.lastName = this.userForm.get("lastName")?.value;
            localStorage.setItem("userDetails", JSON.stringify(userDetails));
          }

          // Notify that user details have been updated
          this.commonUtilsService.notifyUserDetailsUpdated();


          this.getPersonalDetails();

          

        } else {
          this.errorHandlerService.handleResponseError(response);
          this.isSpinnerShown = false;
          this._spinner.hide();
        }
      } catch (error) {
        this.errorHandlerService.handleHttpError(error as HttpErrorResponse);
      } finally {
        this.isSpinnerShown = false;
        this._spinner.hide();
        this.cdr.detectChanges();
      }
    }
  }

  // FUNCTION TO CREATE API PAYLOAD
  createApiPayload() {
    return {
      firstName: this.userForm.get("firstName")?.value,
      lastName: this.userForm.get("lastName")?.value,
      gender: this.gender,
      phoneNumber: this.userForm.get("phoneNumber")?.value,
      dateOfBirth: this.userForm.get("dateOfBirth")?.value,
      address: this.userForm.get("address")?.value,
      city: this.City,
      state: this.State,
      country: this.Country,
      // username: this.userForm.get('username')?.value,
      // email: this.userForm.get('email')?.value,
      description: this.userForm.get("description")?.value,
      // profileBase64: this.profileBase64,
      // userType: "testing"
    };
  }

  // FUNCTION TO CHECK DATA MATCH
  matchPayload() {
    const oldPayload = this.userPersonalData;
    const newPayload = this.createApiPayload();
    ;
    const apipayload = {
      firstName: oldPayload?.firstName,
      lastName: oldPayload?.lastName,
      gender: oldPayload?.gender,
      phoneNumber: oldPayload?.phoneNumber,
      dateOfBirth: oldPayload?.dateOfBirth.substring(0, 10),
      address: oldPayload?.address,
      city: oldPayload?.city,
      state: oldPayload?.state,
      country: oldPayload?.country,
      description: oldPayload?.description,
      // profileBase64: oldPayload?.profileBase64,
    };

    return JSON.stringify(apipayload) === JSON.stringify(newPayload);
  }

  // DISPLAY ERROR MESSAGE OF COMMON DROPDOWNS
  genderError: boolean = false;
  countryTypeError: boolean = false;
  stateError: boolean = false;
  cityError: boolean = false;
  imageError: boolean = false;
  ShowError() {
    this.genderError = this.gender == "" ? true : false;
    this.countryTypeError = this.Country == "" ? true : false;
    this.stateError = this.State == "" ? true : false;
    this.cityError = this.City == "" ? true : false;
    this.imageError = this.profileBase64 == "" ? true : false;
  }

  // GENDER DROPDOWN DATA
  genderList = [
    { id: 1, value: "Male" },
    { id: 2, value: "Female" },
  ];
  gender: string = "";
  onGenderSelected(option: any) {
    this.gender = option.value;
  }

  // COUNTRY DROPDOWN DATA
  CountrysearchTerm: string = "";
  CountryList: DropdownItem[] = [];
  SearchTermCountry(searchTerm: string) {
    this.CountrysearchTerm = searchTerm;
    this.Country = searchTerm;
  }
  getCountry() {
    this._apiCall
      .GetCallWithoutToken("DropDown/GetCountryDropDown")
      .subscribe((response: any) => {
        if (response.responseCode === 200) {
          this.CountryList = response?.data;
        }
      });
  }
  onCountrySelected(option: any) {
    if (this.Country != option.value) {
      this.State = "";
      this.City = "";
    }
    this.Country = option.value;
    this.getStateList(option.id);
  }
  onCountryValueChange(searchValue: any) {
    this.Country = searchValue;
    if (this.Country === "") {
      this.State = "";
      this.City = "";
    }
  }

  // STATE DROPDOWN DATA
  StatesearchTerm: string = "";
  StateList: DropdownItem[] = [];
  SearchTermState(searchTerm: string) {
    this.StatesearchTerm = searchTerm;
    this.State = searchTerm;
  }
  onStateSelected(option: any) {
    if (this.State !== option.value) {
      this.City = "";
    }
    this.State = option.value;
    this.getCityList(option.id);
  }
  onStateValueChange(searchValue: any) {
    this.State = searchValue;
    if (this.State === "") {
      this.City = "";
    }
  }

  getStateList(countryId: number) {
    this._apiCall
      .GetCallWithoutToken("DropDown/GetStateDropDown?CountryId=" + countryId)
      .subscribe((response: any) => {
        if (response.responseCode === 200) {
          this.StateList = response?.data;
        }
      });
  }

  // CITY DROPDOWN DATA
  CityList: DropdownItem[] = [];
  onCitySelected(option: any): void {
    this.City = option.value;
  }
  getCityList(stateId: number) {
    this._apiCall
      .GetCallWithoutToken("DropDown/GetCityDropDown?StateId=" + stateId)
      .subscribe((response: any) => {
        if (response.responseCode === 200) {
          this.CityList = response?.data;
        }
      });
  }
}
