import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, inject, TemplateRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { PropertyService } from 'src/app/shared/services/property.service';

@Component({
  selector: 'app-add-hostel',
  templateUrl: './add-hostel.component.html',
  styleUrl: './add-hostel.component.scss'
})
export class AddHostelComponent {
  public themeLogo = 'assets/images/logo/2.png';
  public footerLogo = 'assets/images/logo/footer-logo.png';
  public bgImage = 'assets/images/banner/banner.webp';
  public title = 'Dashboard';
  public parent = 'Hostels';
  public child = 'Add Hostel';
  addHostelForm: FormGroup;
  hostelsData: any;
  gridImages: boolean = false;
  listView: boolean = false;
  thumbnail: boolean = false;
  thumbnail_video: boolean = false;
  tagClass: string;
  total: number;
  public selectedImage: string;
  isDataLoading: boolean = true;
  public isSpinnerShown = false;

  private destroy$ = new Subject<void>();

  public __apiCall = inject(ApiCallService);
  public _toastr = inject(ToastrService);
  public _spinner = inject(NgxSpinnerService)
  public propertyService = inject(PropertyService);
  private readonly errorHandlingService = inject(ErrorHandlingService);
  public FormName: string = "Add";

  
  public theme_default3 = '#ff5c41';
  public theme_default4 = '#ff8c41';



  constructor(public modal: NgbModal, private fb: FormBuilder, private cdr: ChangeDetectorRef) {
    this.addHostelForm = this.fb.group({
      hostel_name: ["", Validators.required],
      facilites: ["", Validators.required],
      site_link: ["", [Validators.required, Validators.pattern('https?://.+')]],
      hostel_address: ["", Validators.required],
      hostel_addressTitle: ["", Validators.required],
      hostel_addressLink: ["", [Validators.required, Validators.pattern('https://maps.google.com/maps.+')]],

      email_address: ["", [
        Validators.required,
        Validators.email,
        Validators.pattern(
          "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$"
        ),
      ]],
      description: ["", [Validators.required, Validators.minLength(400)]],
      phone: ["", [Validators.required,
      Validators.pattern("^((\\+91-?)|0)?[0-9]{10,18}$"),
      Validators.minLength(10),
      Validators.maxLength(18),]],
      video_link: [""],
      opening_time: ["", Validators.required],
      closing_time: ["", Validators.required],
      number_of_seats: ["", [
        Validators.required,
        Validators.min(1), 
        Validators.pattern("^[0-9]*$") 
      ]],
      rent_per_seat: ["", [
        Validators.required,
        Validators.min(1), 
        Validators.pattern("^[0-9]*$") 
      ]]
    }, { validators: this.validateOpeningClosingTime });


  }
  ngOnInit() {
    document.documentElement.style.setProperty('--theme-default', this.theme_default3);
    document.documentElement.style.setProperty('--theme-default3', this.theme_default3);
    document.documentElement.style.setProperty('--theme-default4', this.theme_default4);

    this.getHostels();
    this.getCountry();
  }


  private async getHostels(): Promise<void> {
    this.isDataLoading = true;
    try {
      const response = await this.__apiCall.GetCallWithToken("Dashboard/getHostels").pipe(takeUntil(this.destroy$))
        .toPromise();
      if (response.responseCode == 200) {
        this.hostelsData = response.data;
      } else {
        this.errorHandlingService.handleResponseError(response);
        this.hostelsData = [];
      }
    } catch (error) {
      this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
    } finally {
      this.isSpinnerShown = false;
      this._spinner.hide();
      this.isDataLoading = false;
      this.cdr.detectChanges();
    }
  }


  ngOnDestroy(): void {
    document.documentElement.style.removeProperty('--theme-default');
    document.documentElement.style.removeProperty('--theme-default3');
    document.documentElement.style.removeProperty('--theme-default4');

    this.destroy$.next();
    this.destroy$.complete();
  }

  charCount: number = 0;

  updateCharCount(): void {
    let description = this.addHostelForm.get("description")?.value;
    this.charCount = description?.length || 0;
  }

  updateCheckedValues(feature: any) {
    feature.checked = !feature.checked;
  }

  delId: number;
  deleteCard(content: TemplateRef<any>, id: number) {
    this.modal.open(content, { centered: true, size: 'sm' });
    this.delId = id;
  }

  addCard(content: TemplateRef<any>) {
    this.updateHostelId = 0;
    this.FacilitesTags = [];
    this.coursestags = [];
    this.classesTags = [];
    this.HostelType = "";
    this.City = "";
    this.Country = "";
    this.State = "";
    this.updateCharCount();
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Add";
    this.addHostelForm.reset();
  }


  editCard(content: TemplateRef<any>, id: number) {
    this.addHostelForm.reset();
    // this.showDepartment = false;
    // this.showClasses = false;
    this.Country = "";
    this.State = "";
    this.HostelType = "";
    this.City = "";
    this.coursestags = [];
    this.FacilitesTags = [];
    this.classesTags = [];
    this.updateCharCount();
    
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Edit";

    this.editInstitute(id);
  }



  public Options = {
    items: 1,
    loop: true,
    nav: false,
    dots: true,
    autoplay: true,
    autoplayTimeout: 2000,
    navText: [
      "<i class='fa fa-chevron-left'></i>",
      "<i class='fa fa-chevron-right'></i>",
    ],
  };

  public thumbnailCarouselOptions = {
    items: 4,
    margin: 10,
    center: true,
    loop: true,
    nav: false,
    dots: false,
  };

  changeImage(image: string) {
    this.selectedImage = image;
  }

  //edit form logistics ///
  hostelByIdData: any;

  updateHostelId: number = 0;

  public async editInstitute(id: number): Promise<void> {
    this.updateHostelId = id;
    this.isSpinnerShown = true;
    this._spinner.show();
    try {
      const respone = await this.__apiCall.PostCallWithToken(null, `Dashboard/GetInstituteById?InstituteId=${id}`).pipe(takeUntil(this.destroy$))
        .toPromise();
      if (respone.responseCode == 200) {
        this.hostelByIdData = respone.data;

        this.addHostelForm.get('hostel_name')?.setValue(this.hostelByIdData?.instituteName);
        this.addHostelForm.get("description")?.setValue(this.hostelByIdData?.description);
        this.addHostelForm.get("phone")?.setValue(this.hostelByIdData?.contactNumber);
        this.addHostelForm.get("site_link")?.setValue(this.hostelByIdData?.websiteLink);
        this.addHostelForm.get("email_address")?.setValue(this.hostelByIdData?.email);
        this.addHostelForm.get("hostel_address")?.setValue(this.hostelByIdData?.address);
        this.addHostelForm.get("hostel_addressTitle")?.setValue(this.hostelByIdData?.locationTitle);
        this.addHostelForm.get("hostel_addressLink")?.setValue(this.hostelByIdData?.locationSrc);
        this.addHostelForm.get("video_link")?.setValue(this.hostelByIdData?.videoLink);
        this.HostelType = this.hostelByIdData?.hostelType;
        this.Country = this.hostelByIdData?.country;
        this.State = this.hostelByIdData?.state;
        this.City = this.hostelByIdData?.city;
        this.images = this.hostelByIdData.images;
      
        this.coursestags = this.hostelByIdData?.courses;
        this.classesTags = this.hostelByIdData?.classes;
        this.FacilitesTags = this.hostelByIdData?.departments;


       


        // if (this.InstitueType == "University") {
        //   this.showDepartment = true;
        //   this.showClasses = false;
        // } else {
        //   this.showClasses = true;
        //   this.showDepartment = false;
        // }
        this.updateCharCount();
        this.isSpinnerShown = false;
        this._spinner.hide();
        // this.getHostels();
      }
      else {
        this.errorHandlingService.handleResponseError(respone);
        this._toastr.error(respone.responseMessage);
        this.isSpinnerShown = false;
        this._spinner.hide();
      }

    } catch (error) {
      this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
    } finally {
      this.isSpinnerShown = false;
      this._spinner.hide();
      this.cdr.detectChanges();
      this.addHostelForm.updateValueAndValidity();

    }

  }




  public async deleteHostel(): Promise<void> {
    this.isSpinnerShown = true;
    this._spinner.show();
    try {
      const res = await this.__apiCall.PostCallWithToken(null, `Dashboard/DeleteHostel?id=${this.delId}`).pipe(takeUntil(this.destroy$))
        .toPromise();
      // .subscribe((res) => {
      if (res.responseCode == 200) {
        this._toastr.success(res.responseMessage);
        this.getHostels();
        this.modal.dismissAll();
        this.isSpinnerShown = false;
        this._spinner.hide();
      } else {
        this.errorHandlingService.handleResponseError(res);
        this.modal.dismissAll();
      }
    } catch (error) {
      this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
    } finally {
      this.isSpinnerShown = false;
      this._spinner.hide();
      this.cdr.detectChanges();
    }
    // })
  }

   // Custom validator to check if closing time is after opening time
   validateOpeningClosingTime(formGroup: FormGroup) {
    const openingTime = formGroup.get('opening_time')?.value;
    const closingTime = formGroup.get('closing_time')?.value;
  
    if (openingTime && closingTime && openingTime >= closingTime) {
      return { invalidTimeRange: true };
    }
    return null;
  }
  



  files: File[] = [];
  images: { imageBase64: string }[] = [];

  onSelect(event: NgxDropzoneChangeEvent) {
    const newFiles = event.addedFiles;
    this.files.push(...newFiles);
    // console.log("files", this.files)

    newFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {

        const sizeInKB = Math.round(file.size / 1024);
        const istoDel = this.checkImgeSizeValidity(sizeInKB, file)
        if (!istoDel) {
          const base64String = e.target.result as string;
          this.images.push({ imageBase64: base64String });
        }
      };
      reader.readAsDataURL(file);
    });

  }

  onRemove(event: File) {
    const index = this.files.indexOf(event);
    if (index !== -1) {
      this.files.splice(index, 1);
      this.images.splice(index, 1);
    }
  }
  imageSizeError: boolean = false
  checkImgeSizeValidity(size: number, file: any) {
    if (size > 1024) {
      this.imageSizeError = true;
      this.onRemove(file)
      return true;

    }
    else {
      this.imageSizeError = false;
      return false;
    }
  }


  /////////////////////////////////////////////////////
  //////////// image update case //////////////////////
  //////////////////////////////////////////////////



  ////////////////////////////////////////////
  //////////// code for image /////////////////////
  //////////////////////////////////////////////


  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {

        const sizeInKB = Math.round(file.size / 1024);
        const isToDel = this.checkImgeSizeValidity(sizeInKB, file)
        if (!isToDel) {
          const base64String = reader.result as string;
          this.images.push({ imageBase64: base64String });
        };
      };
    }
  }

  removeFile(file: any): void {

    this.images = this.images.filter((t: any) => t !== file);

  }



  ////////////////////////////////////////////
  //////////// code for image /////////////////////
  //////////////////////////////////////////////



  HostelTypeList = [
    { id: 1, value: "Boys Hostel" },
    { id: 2, value: "Girls Hostel" },
    
  ];
  HostelType: string = "";
  // showDepartment: boolean = false;
  // showClasses: boolean = false;
  // onInstitueTypeSelected(option: any) {
  //   this.InstitueType = option.value;
  //   this.addControls();
  //   if (option.value == "University") {
  //     this.showDepartment = true;
  //     this.showClasses = false;
  //   } else {
  //     this.showClasses = true;
  //     this.showDepartment = false;
  //   }
  // }



  ////////////////////////////////////////////////////
  /////////  Country drop down  //////////
  ///////////////////////////////////////////////////// 
  CountrysearchTerm: string = "";
  Country: string = "";
  State: string = '';
  City: string = '';
  CountryList = []
  SearchTermCountry(searchTerm: string) {
    this.CountrysearchTerm = searchTerm;
    this.Country = searchTerm;
  }
  getCountry() {
    this.__apiCall
      .GetCallWithoutToken('DropDown/GetCountryDropDown')
      .subscribe((response: any) => {
        if (response.responseCode === 200) {
          this.CountryList = response?.data;
        }
      })

  }
  hostelTypeError: boolean = false;
  countryTypeError: boolean = false;
  stateError: boolean = false;
  cityError: boolean = false;
  ShowError() {
    this.hostelTypeError = this.HostelType == "" ? true : false;
    this.countryTypeError = this.Country == "" ? true : false;
    this.stateError = this.State == "" ? true : false;
    this.cityError = this.City == "" ? true : false;
  }

  onCountrySelected(option: any) {
    if (this.Country != option.value) {
      this.State = '';
      this.City = '';
    }
    this.Country = option.value;
    this.getStateList(option.id);

  }
  onCountryValueChange(searchValue: any) {
    this.Country = searchValue
    if (this.Country === '') {
      this.State = '';
      this.City = ''
    }
  }

  ////////////////////////////////////////////////////
  /////////  State drop down data //////////
  ////////////////////////////////////////////////////

  StatesearchTerm: string = "";

  StateList = []
  SearchTermState(searchTerm: string) {
    this.StatesearchTerm = searchTerm;
    this.State = searchTerm;
  }

  onStateSelected(option: any) {
    if (this.State !== option.value) {
      this.City = '';
    }
    this.State = option.value;
    this.getCityList(option.id);

  }
  onStateValueChange(searchValue: any) {
    this.State = searchValue
    if (this.State === '') {
      this.City = ''
    }
  }

  getStateList(countryId: number) {
    this.__apiCall
      .GetCallWithoutToken('DropDown/GetStateDropDown?CountryId=' + countryId)
      .subscribe((response: any) => {
        if (response.responseCode === 200) {
          this.StateList = response?.data;
        }
      });
  }


  ////////////////////////////////////////////////////
  /////////  City drop down data //////////
  ////////////////////////////////////////////////////


  CityList = []
  onCitySelected(option: any): void {
    this.City = option.value;
  }

  getCityList(stateId: number) {
    this.__apiCall.GetCallWithoutToken('DropDown/GetCityDropDown?StateId=' + stateId).subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.CityList = response?.data;
        }
      }
    )
  }




  ////////////////////////////////////////////
  // code for Custom Searchable DropDown for Language
  //////////////////////////////////////////////




  ///////////////////////////////////////////////////////////
  // Function to add COURSE tag //////////////////////////////
  ////////////////////////////////////////////////////////////


  coursestags: any[] = [];

  addCourseTag(): void {

    const tagInput = this.addHostelForm.get("courses_name")?.value.trim();

    if (
      tagInput &&
      !this.coursestags.some((tag) => tag.courseName === tagInput)
    ) {
      const newTag = { courseName: tagInput };
      this.coursestags.push(newTag);
      this.addHostelForm.get("courses_name")?.setValue("");
    }
  }

  removeCourseTag(tag: any): void {
    this.coursestags = this.coursestags.filter((t) => t !== tag);
  }

  onEnterKeyPress(event: Event): void {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.addCourseTag();
    }
  }


  ///////////////////////////////////////////////////////////
  // Function to add COURSE tag //////////////////////////////
  ////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////
  // Function to add COURSE tag ////////////////////////////
  ////////////////////////////////////////////////////////////


  classesTags: any[] = [];

  addClassTags(): void {
    const tagInput = this.addHostelForm.get("classes_name")?.value.trim();

    if (
      tagInput &&
      !this.classesTags.some((tag) => tag.classTitle === tagInput)
    ) {
      const newTag = { classTitle: tagInput };
      this.classesTags.push(newTag);
      this.addHostelForm.get("classes_name")?.setValue("");
    }
  }

  removeClassTags(tag: any): void {
    this.classesTags = this.classesTags.filter((t) => t !== tag);
  }

  onEnterKey(event: Event): void {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.addClassTags();
    }
  }

  ///////////////////////////////////////////////////////////
  // Function to add COURSE tag ////////////////////////////
  ////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////
  // Function to add Department tag ///////////////////////
  //////////////////////////////////////////////////////////////


  FacilitesTags: any[] = [];

  addFacilitesTags(): void {
    const tagInput = this.addHostelForm.get("facilites")?.value.trim();

    if (tagInput && !this.FacilitesTags.some((tag) => tag.FacilitesName === tagInput)) {
      const newTag = { FacilitesName: tagInput };
      this.FacilitesTags.push(newTag);
      this.addHostelForm.get("facilites")?.setValue("");
    }
  }

  removeFacilitesTags(tag: any): void {
    this.FacilitesTags = this.FacilitesTags.filter((t) => t !== tag);
  }

  onEnterFacilites(event: Event): void {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.addFacilitesTags();
    }
  }


  ///////////////////////////////////////////////////////////
  // Function to add Department tag ///////////////////////
  //////////////////////////////////////////////////////////////


  checkTagsValidity(): boolean {
   
      if (this.FacilitesTags.length < 1) {
        return true;
      }
      return false;
    }
   
   
   
 

  ////////////////////////////////////////////
  // function to clear and add Validations 
  //////////////////////////////////////////////


  removeUmwantedControl() {

    this.addHostelForm.removeControl('classes_name');
    this.addHostelForm.removeControl('courses_name');
    this.addHostelForm.removeControl('department');
    // this.addHostelForm.removeControl('video_link');
    this.addHostelForm.updateValueAndValidity();
  }
  addControls() {

    
      if (!this.addHostelForm.contains('facilites')) {
        this.addHostelForm.addControl('facilites', new FormControl("", Validators.required));
      }
     


    this.addHostelForm.updateValueAndValidity();
  }


  ////////////////////////////////////////////
  // function to clear and add Validations 
  //////////////////////////////////////////////




  ////////////////////////////////////
  // API CALL AND VALIDATIONS CHECK
  ////////////////////////////////////


  vediolink: any;
  public async onSubmit(): Promise<void> {


    this.addHostelForm.markAllAsTouched();
    const tagsValidity = this.checkTagsValidity();
    this.ShowError();

    this.removeUmwantedControl();

    if (
      this.Country === "" ||
      this.HostelType === "" ||
      this.City === "" ||
      this.State === "" ||
      this.images.length < 1 ||
      tagsValidity ||
      this.addHostelForm.invalid
    ) {
      this._toastr.error("Invalid Form");
      this.addControls();
    }
    else {
      this.isSpinnerShown = true;
      this._spinner.show();
      try {
        const payload = this.createApiPayload();
        console.log("Data from hostels",payload)
        const response = await this.__apiCall
          .PostCallWithToken(payload, "Dashboard/SaveInstitute").pipe(takeUntil(this.destroy$))
          .toPromise();
        // .subscribe((response: any) => {
        this.addControls();
        if (response.responseCode == 200) {
          this.isSpinnerShown = false;
          this._spinner.hide();
          this._toastr.success(response.responseMessage);
          this.modal.dismissAll();
          this.getHostels();
        } else {
          this.errorHandlingService.handleResponseError(response);

        }
      } catch (error) {
        this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
      } finally {
        this.isSpinnerShown = false;
        this._spinner.hide();
        this.cdr.detectChanges();
      }
      // });
    }

  }
  ///////////////////////////////////////
  ///////   function for API PAYLOAD ////
  /////////////////////////////////////


  createApiPayload() {
    const payload: any = {
      id: this.updateHostelId,
      instituteName: this.addHostelForm.get("hostel_name")?.value,
      description: this.addHostelForm.get("description")?.value,
      hostelType: this.HostelType,
      contactNumber: this.addHostelForm.get("phone")?.value,
      websiteLink: this.addHostelForm.get("site_link")?.value,
      email: this.addHostelForm.get("email_address")?.value,
      country: this.Country,
      state: this.State,
      address: this.addHostelForm.get("hostel_address")?.value,
      locationTitle: this.addHostelForm.get("hostel_addressTitle")?.value,
      locationSrc: this.addHostelForm.get("hostel_addressLink")?.value,
      openingTime: this.addHostelForm.get("opening_time")?.value,
      closingTime: this.addHostelForm.get("closing_time")?.value,
      numberOfSeats: this.addHostelForm.get("number_of_seats")?.value,
      rentPerSeat: this.addHostelForm.get("rent_per_seat")?.value,
      city: this.City,
      images: this.images,
     
      
    }

    payload.facilities= this.FacilitesTags;
    const facilitiesControl = this.addHostelForm.get('facilites');
    facilitiesControl?.clearValidators();

    return payload;

  }

  ///////////////////////////////////////
  ///////   function for API PAYLOAD ////
  /////////////////////////////////////




}
