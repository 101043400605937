import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Observable, catchError, throwError } from "rxjs";
import { AuthService } from "./auth.service";
// import { AuthenticationService } from '../../core/authentication/authentication.service';

@Injectable({
  providedIn: "root",
})
export class ApiCallService {
  constructor(
    private http: HttpClient,
    // private authentication: AuthenticationService
  ) { }

  // private baseRoute = 'https://localhost:7079/api/';
  private baseRoute = 'http://5.189.131.230:8053/api/';
  // private baseRoute = "http://154.38.171.150:8015/api/";
  private _authService = inject(AuthService);
  PostCallWithoutToken(Payload: any, apiroute: string): Observable<any> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    var finalroute = this.baseRoute + apiroute;
    var response = this.http
      .post<any>(finalroute, Payload, { headers })
      .pipe(catchError(this.handleError));
    // console.log(response);
    return response;
  }
  

  // checkTokenExpiry() {
  //   //
  //   const token = localStorage.getItem("emailToken");
  //   //
  //   if (token) {
  //     //
  //     const decodedToken = this.authentication.decodeToken(token);
  //     console.log("decodedToken", decodedToken);
  //     const now = new Date();
  //     const Timestamp = Math.floor(now.getTime() / 1000);
  //     const isExpired = decodedToken.exp < Timestamp;
  //     if (isExpired) {
  //       console.log("token expired");
  //       this.authentication.logout();
  //     }
  //   }
  //   console.log("token", token);
  // }
  PostCallWithToken(Payload: any, apiroute: string): Observable<any> {
    // this.checkTokenExpiry();
    const tokenTypes = ["googleToken", "facebookToken", "emailToken"];
    let token: string | null = null;

    // for (const type of tokenTypes) {
      // token = localStorage.getItem(type);
    //   if (token) {
    //     break;
    //   }
    // }
    token = this._authService.getToken();
    
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });

    headers.set("Authorization", `Bearer ${token}`);
    var finalroute = this.baseRoute + apiroute;
    var response = this.http
      .post<any>(finalroute, Payload, { headers })
      .pipe(catchError(this.handleError));
    return response;
  }
  GetCallWithoutToken(apiroute: string): Observable<any> {
    // this.checkTokenExpiry();
    var apiurl = this.baseRoute + apiroute;
    var response = this.http
      .get<any>(apiurl)
      .pipe(catchError(this.handleError));
    return response;
  }
  GetCallWithToken(apiroute: string): Observable<any> {
    // this.checkTokenExpiry()

    var apiurl = this.baseRoute + apiroute;

    // const tokenTypes = ["googleToken", "facebookToken", "emailToken"];
    // let token: string | null = null;

    // for (const type of tokenTypes) {
    //   token = localStorage.getItem(type);
    //   if (token) {
    //     break;
    //   }
    // }

    // const token = localStorage.getItem('token');

    const token = this._authService.getToken();

    // Create headers and append the token
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    var response = this.http
      .get<any>(apiurl, { headers })
      .pipe(catchError(this.handleError));
    // console.log(response);
    return response;
  }
  private handleError(error: HttpErrorResponse) {
    if (error.status === 400) {
      // If status is 400, user already exists
      return throwError("User already exists");
    } else {
      // For other errors, log the error and throw a generic message
      // console.error('An error occurred:', error.error);
      return throwError("Something went wrong. Please try again later.");
    }
  }
}
