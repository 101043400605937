import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrl: './skills.component.scss',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkillsComponent implements OnInit, OnDestroy {
  // private destroy$ = new Subject<void>();
  skillForm: FormGroup;
  public FormName: string = "Add";
  isLoading: boolean = true;
  public isSpinnerShown = false;
  skillsDetails: any = [
    // {
    //   skillTitle: 'Angular',
    //   percentage: '80'
    // },
    // {
    //   skillTitle: 'React',
    //   percentage: '70'
    // },
    // {
    //   skillTitle: '.Net Core',
    //   percentage: '60'
    // }
  ];
  delId: number;
  editId: number = 0;
  private readonly _apiCall = inject(ApiCallService);
  private readonly _toaster = inject(ToastrService);
  private readonly _spinner = inject(NgxSpinnerService);
  private readonly errorHandlingService = inject(ErrorHandlingService);
  constructor(private fb: FormBuilder, public modal: NgbModal, private cdr: ChangeDetectorRef) {
    this.skillForm = this.fb.group({
      skillTitle: ['', Validators.required],
      percentage: ['', [Validators.required, Validators.min(0), Validators.max(100)]]
    });
  }

  ngOnInit(): void {
    this.getSKillsDetails();
  }
  ngOnDestroy(): void {
    // this.destroy$.next();
    // this.destroy$.complete();
  }


  addCard(content: TemplateRef<any>) {
    this.skillForm.reset();
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Add";
  }

  editCard(content: TemplateRef<any>, tag: any) {
    this.skillForm.reset();
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Edit";
    this.editId = tag?.id;
    this.skillForm.get("skillTitle")?.setValue(tag?.skillTitle);
    this.skillForm.get("percentage")?.setValue(tag?.percentage);

  }
  deleteCard(content: TemplateRef<any>, id: number) {
    this.modal.open(content, { centered: true, size: 'sm' });
    this.delId = id;
  }


  checkSkillExsists(): boolean {
    const payload = this.createApiPayload();
    if (this.skillsDetails?.length > 0) {
      if (this.FormName == "Add") {
        const isExsist = this.skillsDetails.some((tag: any) => tag.skillTitle == payload.skillTitle);
        if (isExsist) {
          this._toaster.error("This Skill Already Exist");
          return true;
        }
      } else {
        let newSkills = this.skillsDetails;
        newSkills = newSkills.filter((item: any) => item.id != this.editId);
        const isExsist = newSkills.some((tag: any) => tag.skillTitle == payload.skillTitle && tag.id != this.editId);
        if (isExsist) {
          this._toaster.error("This Skill Already Exist");
          return true;
        }
      }

      return false;
    }
    return false;
  }
  public saveSkillDetails(): void {
    this.skillForm.markAllAsTouched();

    const isAlreadyExists = this.checkSkillExsists();
    if (isAlreadyExists) {
      return;
    }

    if (this.skillForm.invalid) {
      this._toaster.error("Invalid Form");
      return;
    }

    const payload = this.createApiPayload();
    this.isSpinnerShown = true;

    this._apiCall.PostCallWithToken(payload, 'AdmissionLeloUsers/SaveTeacherSkill')
      .subscribe(
        (response) => {
          if (response.responseCode === 200) {
            this._toaster.success(response.responseMessage);
            this.editId = 0;
            this.skillForm.reset();
            this.modal.dismissAll();
            this.getSKillsDetails();
          } else {
            this._toaster.error(response.responseMessage);
          }
          this.isSpinnerShown = false;
        },
        (error) => {
          this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
          this.isSpinnerShown = false;
        }
      );
  }

  createApiPayload() {
    this.editId = this.FormName == "Add" ? 0 : this.editId;
    return {
      id: this.editId,
      skillTitle: this.skillForm.get('skillTitle')?.value,
      percentage: this.skillForm.get('percentage')?.value
    };
  }


  public deleteSkill(): void {
    this.isSpinnerShown = true;

    this._apiCall.PostCallWithToken(null, `AdmissionLeloUsers/DeleteTeacherSkill?SkillId=${this.delId}`)
      .subscribe(
        (res) => {
          if (res.responseCode === 200) {
            this._toaster.success(res.responseMessage);
            this.getSKillsDetails();
            this.modal.dismissAll();
          } else {
            this.errorHandlingService.handleResponseError(res);
          }
          this.isSpinnerShown = false;
        },
        (error) => {
          this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
          this.isSpinnerShown = false;
        }
      );
  }


  // FUNCTION TO GET Experience DETAILS

  public getSKillsDetails(): void {
    this.isLoading = true;
    this._spinner.show();

    this._apiCall.GetCallWithToken("AdmissionLeloUsers/GetTeacherSkill")
      .subscribe(
        (response) => {
          if (response.responseCode === 200) {
            this.skillsDetails = response.data;
          } else {
            this.skillsDetails = [];
            this.errorHandlingService.handleResponseError(response);
          }
          this.isLoading = false;
        },
        (error) => {
          this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
          this.isLoading = false;
        }
      );
  }


}
