import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { MetaService } from 'src/app/shared/services/meta.service';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrl: './faqs.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FaqsComponent implements OnInit {
@Input() isOnFaqPage: boolean = true;

  private readonly metaService: MetaService = inject(MetaService);
  public bgImage = 'assets/images/banner/banner.webp';
  public title = 'Faq';
  public parent = 'Home';
  public child = 'Faq';

  FAQsForm: FormGroup;

  private _apicall=inject(ApiCallService);
  private _toaster=inject(ToastrService);

  constructor(
    private fb: FormBuilder,
    private router: Router,
  ) {
    this.FAQsForm = this.fb.group({
      
      faq_question: ['', [Validators.required]],
    })
  }

  ngOnInit(): void {
    const route = this.router.url;
    if (route === "/faqs") {
      this.metaService.updateTitle('Faq | AdmissionLylo');
      this.metaService.updateMetaTags([
        { name: 'description', content: 'Your page description here' },
        { name: 'keywords', content: 'angular, seo, meta tags' },
        { name: 'author', content: 'Your Name' },
        { name: 'robots', content: 'index, follow' }
      ]);
      this.metaService.setCanonicalURL();
      this.metaService.initializeMetaData();
    }
    
  }
  sendFaqQuestion() {
    
    if (this.FAQsForm.invalid) {
      this.FAQsForm.markAllAsTouched();
      this._toaster.error('Invalid Form data');
      return;
    }
    else{
        this._apicall.PostCallWithoutToken(this.createApiPayload(),'AdmissionLeloVisitor/SaveQuestionAndSendEmail').subscribe((res)=>{
          if(res.responseCode==200)
          {
            this._toaster.success(res.errorMessage);
            this.FAQsForm.reset();
          }
          else
          {
            this._toaster.error(res.errorMessage);
            this.FAQsForm.reset();
          }
        })
    }
  }
  createApiPayload(){
    return {
      question:this.FAQsForm.get('faq_question')?.value,
      
    }
  }
}
