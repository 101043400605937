import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TitleStrategy } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { PropertyService } from 'src/app/shared/services/property.service';

@Component({
  selector: 'app-add-institute',
  templateUrl: './add-institute.component.html',
  styleUrl: './add-institute.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddInstituteComponent {
  public themeLogo = 'assets/images/logo/2.png';
  public footerLogo = 'assets/images/logo/footer-logo.png';
  public bgImage = 'assets/images/banner/banner.webp';
  public title = 'Dashboard';
  public parent = 'Instiutes';
  public child = 'Add Institute';
  addInstituteForm: FormGroup;
  institutesData: any;
  gridImages: boolean = false;
  listView: boolean = false;
  thumbnail: boolean = false;
  thumbnail_video: boolean = false;
  tagClass: string;
  total: number;
  public selectedImage: string;
  isDataLoading: boolean = true;
  public isSpinnerShown = false;

  private destroy$ = new Subject<void>();

  public __apiCall = inject(ApiCallService);
  public _toastr = inject(ToastrService);
  public _spinner = inject(NgxSpinnerService)
  public propertyService = inject(PropertyService);
  private readonly errorHandlingService = inject(ErrorHandlingService);
  public FormName: string = "Add";

  public AdditionalFeatures = [
    {
      title: "Hostels/Dormitories",
      id: "chk-ani",
      checked: false,
    },
    {
      title: "Laboratories",
      id: "chk-ani1",
      checked: false,
    },
    {
      title: "Scholarships and Financial Aid",
      id: "chk-ani2",
      checked: false,
    },
    {
      title: "Research Centers",
      id: "chk-ani3",
      checked: false,
    },
    {
      title: "Library Resources and Services",
      id: "chk-ani4",
      checked: false,
    },
    {
      title: "Health and Wellness Facilities",
      id: "chk-ani5",
      checked: false,
    }
  ]

  public theme_default3 = '#ff5c41';
  public theme_default4 = '#ff8c41';



  constructor(public modal: NgbModal, private fb: FormBuilder, private cdr: ChangeDetectorRef) {
    this.addInstituteForm = this.fb.group({
      institute_name: ["", Validators.required],
      courses_name: ["", Validators.required],
      classes_name: ["", Validators.required],
      department: ["", Validators.required],
      site_link: ["", [Validators.required, Validators.pattern('https?://.+')]],
      institute_address: ["", Validators.required],
      institute_addressTitle: ["", Validators.required],
      institute_addressLink: ["", [Validators.required, Validators.pattern('https://maps.google.com/maps.+')]],

      email_address: ["", [
        Validators.required,
        Validators.email,
        Validators.pattern(
          "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$"
        ),
      ]],
      description: ["", [Validators.required, Validators.minLength(400)]],
      phone: ["", [Validators.required,
      Validators.pattern("^((\\+91-?)|0)?[0-9]{10,18}$"),
      Validators.minLength(10),
      Validators.maxLength(18),]],
      video_link: [""],

    });


  }
  ngOnInit() {
    document.documentElement.style.setProperty('--theme-default', this.theme_default3);
    document.documentElement.style.setProperty('--theme-default3', this.theme_default3);
    document.documentElement.style.setProperty('--theme-default4', this.theme_default4);

    this.getInstitutes();
    this.getCountry();
  }

  // getInstitutes() {
  //   this.isDataLoading = true;
  //   this.__apiCall.GetCallWithToken("Dashboard/GetInstitutes").subscribe((response) => {
  //     if (response.responseCode == 200) {
  //       this.institutesData = response.data;
  //       this.isDataLoading = false;
  //     } else {
  //       this.institutesData = [];
  //       this.isDataLoading = false;
  //     }
  //   }, (error) => {
  //     this._toastr.error('Error fetching institutes data', error);
  //     this.isDataLoading = false;
  //   }
  //   )

  // }

  private async getInstitutes(): Promise<void> {
    this.isDataLoading = true;
    try {
      const response = await this.__apiCall.GetCallWithToken("Dashboard/GetInstitutes").pipe(takeUntil(this.destroy$))
        .toPromise();
      if (response.responseCode == 200) {
        this.institutesData = response.data;
      } else {
        this.errorHandlingService.handleResponseError(response);
        this.institutesData = [];
      }
    } catch (error) {
      this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
    } finally {
      this.isSpinnerShown = false;
      this._spinner.hide();
      this.isDataLoading = false;
      this.cdr.detectChanges();
    }
  }


  ngOnDestroy(): void {
    document.documentElement.style.removeProperty('--theme-default');
    document.documentElement.style.removeProperty('--theme-default3');
    document.documentElement.style.removeProperty('--theme-default4');

    this.destroy$.next();
    this.destroy$.complete();
  }


  chehckedFeatures: { featureName: string }[] = [];

  saveCheckedValues() {
    this.chehckedFeatures = this.AdditionalFeatures
      .filter(feature => feature.checked)
      .map(feature => ({ featureName: feature.title }));
  }

  charCount: number = 0;

  updateCharCount(): void {
    let description = this.addInstituteForm.get("description")?.value;
    this.charCount = description?.length || 0;
  }

  updateCheckedValues(feature: any) {
    feature.checked = !feature.checked;
  }

  delId: number;
  deleteCard(content: TemplateRef<any>, id: number) {
    this.modal.open(content, { centered: true, size: 'sm' });
    this.delId = id;
  }

  addCard(content: TemplateRef<any>) {
    this.AdditionalFeatures.forEach(fea => {
      fea.checked = false;
    });
    this.showDepartment = false;
    this.showClasses = false;
    this.updateInstituteId = 0;
    this.departmentTags = [];
    this.coursestags = [];
    this.classesTags = [];
    this.InstitueType = "";
    this.City = "";
    this.Country = "";
    this.State = "";
    this.updateCharCount();
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Add";
    this.addInstituteForm.reset();
  }


  editCard(content: TemplateRef<any>, id: number) {
    this.addInstituteForm.reset();
    this.showDepartment = false;
    this.showClasses = false;
    this.Country = "";
    this.State = "";
    this.InstitueType = "";
    this.City = "";
    this.coursestags = [];
    this.departmentTags = [];
    this.classesTags = [];
    this.updateCharCount();
    this.AdditionalFeatures.forEach(fea => {
      fea.checked = false;
    });
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Edit";

    this.editInstitute(id);
  }



  public Options = {
    items: 1,
    loop: true,
    nav: false,
    dots: true,
    autoplay: true,
    autoplayTimeout: 2000,
    navText: [
      "<i class='fa fa-chevron-left'></i>",
      "<i class='fa fa-chevron-right'></i>",
    ],
  };

  public thumbnailCarouselOptions = {
    items: 4,
    margin: 10,
    center: true,
    loop: true,
    nav: false,
    dots: false,
  };

  changeImage(image: string) {
    this.selectedImage = image;
  }

  //edit form logistics ///
  instituteByIdData: any;

  updateInstituteId: number = 0;

  public async editInstitute(id: number): Promise<void> {
    this.updateInstituteId = id;
    this.isSpinnerShown = true;
    this._spinner.show();
    try {
      const respone = await this.__apiCall.PostCallWithToken(null, `Dashboard/GetInstituteById?InstituteId=${id}`).pipe(takeUntil(this.destroy$))
        .toPromise();
      if (respone.responseCode == 200) {
        this.instituteByIdData = respone.data;

        this.addInstituteForm.get('institute_name')?.setValue(this.instituteByIdData?.instituteName);
        this.addInstituteForm.get("description")?.setValue(this.instituteByIdData?.description);
        this.addInstituteForm.get("phone")?.setValue(this.instituteByIdData?.contactNumber);
        this.addInstituteForm.get("site_link")?.setValue(this.instituteByIdData?.websiteLink);
        this.addInstituteForm.get("email_address")?.setValue(this.instituteByIdData?.email);
        this.addInstituteForm.get("institute_address")?.setValue(this.instituteByIdData?.address);
        this.addInstituteForm.get("institute_addressTitle")?.setValue(this.instituteByIdData?.locationTitle);
        this.addInstituteForm.get("institute_addressLink")?.setValue(this.instituteByIdData?.locationSrc);
        this.addInstituteForm.get("video_link")?.setValue(this.instituteByIdData?.videoLink);
        this.InstitueType = this.instituteByIdData?.instituteType;
        this.Country = this.instituteByIdData?.country;
        this.State = this.instituteByIdData?.state;
        this.City = this.instituteByIdData?.city;
        this.images = this.instituteByIdData.images;
        this.chehckedFeatures = this.instituteByIdData.additionalFeatures;
        this.coursestags = this.instituteByIdData?.courses;
        this.classesTags = this.instituteByIdData?.classes;
        this.departmentTags = this.instituteByIdData?.departments;


        this.AdditionalFeatures.forEach(feature => {
          this.chehckedFeatures.forEach((checkedField: any) => {
            if (feature.title === checkedField.featureName) {
              feature.checked = true;
            }
          });
        });


        if (this.InstitueType == "University") {
          this.showDepartment = true;
          this.showClasses = false;
        } else {
          this.showClasses = true;
          this.showDepartment = false;
        }
        this.updateCharCount();
        this.isSpinnerShown = false;
        this._spinner.hide();
        // this.getInstitutes();
      }
      else {
        this.errorHandlingService.handleResponseError(respone);
        this._toastr.error(respone.responseMessage);
        this.isSpinnerShown = false;
        this._spinner.hide();
      }

    } catch (error) {
      this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
    } finally {
      this.isSpinnerShown = false;
      this._spinner.hide();
      this.cdr.detectChanges();
      this.addInstituteForm.updateValueAndValidity();

    }

  }



  public async deleteInstitute(): Promise<void> {
    this.isSpinnerShown = true;
    this._spinner.show();
    try {
      const res = await this.__apiCall.PostCallWithToken(null, `Dashboard/DeleteInstitute?id=${this.delId}`).pipe(takeUntil(this.destroy$))
        .toPromise();
      // .subscribe((res) => {
      if (res.responseCode == 200) {
        this._toastr.success(res.responseMessage);
        this.getInstitutes();
        this.modal.dismissAll();
        this.isSpinnerShown = false;
        this._spinner.hide();
      } else {
        this.errorHandlingService.handleResponseError(res);
        this.modal.dismissAll();
      }
    } catch (error) {
      this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
    } finally {
      this.isSpinnerShown = false;
      this._spinner.hide();
      this.cdr.detectChanges();
    }
    // })
  }




  files: File[] = [];
  images: { imageBase64: string }[] = [];

  onSelect(event: NgxDropzoneChangeEvent) {
    const newFiles = event.addedFiles;
    this.files.push(...newFiles);
    // console.log("files", this.files)

    newFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {

        const sizeInKB = Math.round(file.size / 1024);
        const istoDel = this.checkImgeSizeValidity(sizeInKB, file)
        if (!istoDel) {
          const base64String = e.target.result as string;
          this.images.push({ imageBase64: base64String });
        }
      };
      reader.readAsDataURL(file);
    });

  }

  onRemove(event: File) {
    const index = this.files.indexOf(event);
    if (index !== -1) {
      this.files.splice(index, 1);
      this.images.splice(index, 1);
    }
  }
  imageSizeError: boolean = false
  checkImgeSizeValidity(size: number, file: any) {
    if (size > 1024) {
      this.imageSizeError = true;
      this.onRemove(file)
      return true;

    }
    else {
      this.imageSizeError = false;
      return false;
    }
  }


  /////////////////////////////////////////////////////
  //////////// image update case //////////////////////
  //////////////////////////////////////////////////



  ////////////////////////////////////////////
  //////////// code for image /////////////////////
  //////////////////////////////////////////////


  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {

        const sizeInKB = Math.round(file.size / 1024);
        const isToDel = this.checkImgeSizeValidity(sizeInKB, file)
        if (!isToDel) {
          const base64String = reader.result as string;
          this.images.push({ imageBase64: base64String });
        };
      };
    }
  }

  removeFile(file: any): void {

    this.images = this.images.filter((t: any) => t !== file);

  }



  ////////////////////////////////////////////
  //////////// code for image /////////////////////
  //////////////////////////////////////////////



  InstitueTypeList = [
    { id: 1, value: "University" },
    { id: 2, value: "College" },
    { id: 3, value: "School" },
    { id: 4, value: "Academy" }
  ];
  InstitueType: string = "";
  showDepartment: boolean = false;
  showClasses: boolean = false;
  onInstitueTypeSelected(option: any) {
    this.InstitueType = option.value;
    this.addControls();
    if (option.value == "University") {
      this.showDepartment = true;
      this.showClasses = false;
    } else {
      this.showClasses = true;
      this.showDepartment = false;
    }
  }



  ////////////////////////////////////////////////////
  /////////  Country drop down  //////////
  ///////////////////////////////////////////////////// 
  CountrysearchTerm: string = "";
  Country: string = "";
  State: string = '';
  City: string = '';
  CountryList = []
  SearchTermCountry(searchTerm: string) {
    this.CountrysearchTerm = searchTerm;
    this.Country = searchTerm;
  }
  getCountry() {
    this.__apiCall
      .GetCallWithoutToken('DropDown/GetCountryDropDown')
      .subscribe((response: any) => {
        if (response.responseCode === 200) {
          this.CountryList = response?.data;
        }
      })

  }
  instituteTypeError: boolean = false;
  countryTypeError: boolean = false;
  stateError: boolean = false;
  cityError: boolean = false;
  ShowError() {
    this.instituteTypeError = this.InstitueType == "" ? true : false;
    this.countryTypeError = this.Country == "" ? true : false;
    this.stateError = this.State == "" ? true : false;
    this.cityError = this.City == "" ? true : false;
  }

  onCountrySelected(option: any) {
    if (this.Country != option.value) {
      this.State = '';
      this.City = '';
    }
    this.Country = option.value;
    this.getStateList(option.id);

  }
  onCountryValueChange(searchValue: any) {
    this.Country = searchValue
    if (this.Country === '') {
      this.State = '';
      this.City = ''
    }
  }

  ////////////////////////////////////////////////////
  /////////  State drop down data //////////
  ////////////////////////////////////////////////////

  StatesearchTerm: string = "";

  StateList = []
  SearchTermState(searchTerm: string) {
    this.StatesearchTerm = searchTerm;
    this.State = searchTerm;
  }

  onStateSelected(option: any) {
    if (this.State !== option.value) {
      this.City = '';
    }
    this.State = option.value;
    this.getCityList(option.id);

  }
  onStateValueChange(searchValue: any) {
    this.State = searchValue
    if (this.State === '') {
      this.City = ''
    }
  }

  getStateList(countryId: number) {
    this.__apiCall
      .GetCallWithoutToken('DropDown/GetStateDropDown?CountryId=' + countryId)
      .subscribe((response: any) => {
        if (response.responseCode === 200) {
          this.StateList = response?.data;
        }
      });
  }


  ////////////////////////////////////////////////////
  /////////  City drop down data //////////
  ////////////////////////////////////////////////////


  CityList = []
  onCitySelected(option: any): void {
    this.City = option.value;
  }

  getCityList(stateId: number) {
    this.__apiCall.GetCallWithoutToken('DropDown/GetCityDropDown?StateId=' + stateId).subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.CityList = response?.data;
        }
      }
    )
  }




  ////////////////////////////////////////////
  // code for Custom Searchable DropDown for Language
  //////////////////////////////////////////////




  ///////////////////////////////////////////////////////////
  // Function to add COURSE tag //////////////////////////////
  ////////////////////////////////////////////////////////////


  coursestags: any[] = [];

  addCourseTag(): void {

    const tagInput = this.addInstituteForm.get("courses_name")?.value.trim();

    if (
      tagInput &&
      !this.coursestags.some((tag) => tag.courseName === tagInput)
    ) {
      const newTag = { courseName: tagInput };
      this.coursestags.push(newTag);
      this.addInstituteForm.get("courses_name")?.setValue("");
    }
  }

  removeCourseTag(tag: any): void {
    this.coursestags = this.coursestags.filter((t) => t !== tag);
  }

  onEnterKeyPress(event: Event): void {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.addCourseTag();
    }
  }


  ///////////////////////////////////////////////////////////
  // Function to add COURSE tag //////////////////////////////
  ////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////
  // Function to add COURSE tag ////////////////////////////
  ////////////////////////////////////////////////////////////


  classesTags: any[] = [];

  addClassTags(): void {
    const tagInput = this.addInstituteForm.get("classes_name")?.value.trim();

    if (
      tagInput &&
      !this.classesTags.some((tag) => tag.classTitle === tagInput)
    ) {
      const newTag = { classTitle: tagInput };
      this.classesTags.push(newTag);
      this.addInstituteForm.get("classes_name")?.setValue("");
    }
  }

  removeClassTags(tag: any): void {
    this.classesTags = this.classesTags.filter((t) => t !== tag);
  }

  onEnterKey(event: Event): void {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.addClassTags();
    }
  }

  ///////////////////////////////////////////////////////////
  // Function to add COURSE tag ////////////////////////////
  ////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////
  // Function to add Department tag ///////////////////////
  //////////////////////////////////////////////////////////////


  departmentTags: any[] = [];

  adddepartmentTags(): void {
    const tagInput = this.addInstituteForm.get("department")?.value.trim();

    if (tagInput && !this.departmentTags.some((tag) => tag.departmentName === tagInput)) {
      const newTag = { departmentName: tagInput };
      this.departmentTags.push(newTag);
      this.addInstituteForm.get("department")?.setValue("");
    }
  }

  removeDepartmentTags(tag: any): void {
    this.departmentTags = this.departmentTags.filter((t) => t !== tag);
  }

  onEnterDepartment(event: Event): void {
    if (event instanceof KeyboardEvent && event.key === "Enter") {
      event.preventDefault();
      this.adddepartmentTags();
    }
  }


  ///////////////////////////////////////////////////////////
  // Function to add Department tag ///////////////////////
  //////////////////////////////////////////////////////////////


  checkTagsValidity(): boolean {
    if (this.InstitueType === "University") {
      if (this.departmentTags.length < 1 || this.coursestags.length < 1) {
        return true;
      }
    }
    else if (this.InstitueType !== "University") {
      if (this.classesTags.length < 1) {
        return true;
      }
    }
    return false;
  }


  ////////////////////////////////////////////
  // function to clear and add Validations 
  //////////////////////////////////////////////


  removeUmwantedControl() {

    this.addInstituteForm.removeControl('classes_name');
    this.addInstituteForm.removeControl('courses_name');
    this.addInstituteForm.removeControl('department');
    // this.addInstituteForm.removeControl('video_link');
    this.addInstituteForm.updateValueAndValidity();
  }
  addControls() {

    if (this.InstitueType === "University") {
      if (!this.addInstituteForm.contains('courses_name')) {
        this.addInstituteForm.addControl('courses_name', new FormControl("", Validators.required));
      }
      if (!this.addInstituteForm.contains('department')) {
        this.addInstituteForm.addControl('department', new FormControl("", Validators.required));
      }
    } else {
      if (!this.addInstituteForm.contains('classes_name')) {
        this.addInstituteForm.addControl('classes_name', new FormControl("", Validators.required));
      }
    }

    // if (!this.addInstituteForm.contains('video_link')) {
    //   this.addInstituteForm.addControl('video_link', new FormControl(""));
    // }

    this.addInstituteForm.updateValueAndValidity();
  }


  ////////////////////////////////////////////
  // function to clear and add Validations 
  //////////////////////////////////////////////




  ////////////////////////////////////
  // API CALL AND VALIDATIONS CHECK
  ////////////////////////////////////


  vediolink: any;
  public async onSubmit(): Promise<void> {


    this.addInstituteForm.markAllAsTouched();
    const tagsValidity = this.checkTagsValidity();
    this.ShowError();

    this.removeUmwantedControl();

    if (
      this.Country === "" ||
      this.InstitueType === "" ||
      this.City === "" ||
      this.State === "" ||
      this.images.length < 1 ||
      tagsValidity ||
      this.addInstituteForm.invalid
    ) {
      this._toastr.error("Invalid Form");
      this.addControls();
    }
    else {
      this.isSpinnerShown = true;
      this._spinner.show();
      try {
        this.saveCheckedValues();
        const payload = this.createApiPayload();
        const response = await this.__apiCall
          .PostCallWithToken(payload, "Dashboard/SaveInstitute").pipe(takeUntil(this.destroy$))
          .toPromise();
        // .subscribe((response: any) => {
        this.addControls();
        if (response.responseCode == 200) {
          this.isSpinnerShown = false;
          this._spinner.hide();
          this._toastr.success(response.responseMessage);
          this.modal.dismissAll();
          this.getInstitutes();
        } else {
          this.errorHandlingService.handleResponseError(response);

        }
      } catch (error) {
        this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
      } finally {
        this.isSpinnerShown = false;
        this._spinner.hide();
        this.cdr.detectChanges();
      }
      // });
    }

  }
  ///////////////////////////////////////
  ///////   function for API PAYLOAD ////
  /////////////////////////////////////


  createApiPayload() {
    const payload: any = {
      id: this.updateInstituteId,
      videoLink: this.addInstituteForm.get("video_link")?.value,
      instituteName: this.addInstituteForm.get("institute_name")?.value,
      description: this.addInstituteForm.get("description")?.value,
      instituteType: this.InstitueType,
      contactNumber: this.addInstituteForm.get("phone")?.value,
      websiteLink: this.addInstituteForm.get("site_link")?.value,
      email: this.addInstituteForm.get("email_address")?.value,
      country: this.Country,
      state: this.State,
      address: this.addInstituteForm.get("institute_address")?.value,
      locationTitle: this.addInstituteForm.get("institute_addressTitle")?.value,
      locationSrc: this.addInstituteForm.get("institute_addressLink")?.value,
      city: this.City,
      images: this.images,
      additionalFeatures: this.chehckedFeatures
    }


    if (this.InstitueType == "University") {
      payload.courses = this.coursestags;
      payload.departments = this.departmentTags;
      const classControl = this.addInstituteForm.get('classes_name');
      classControl?.clearValidators();
    }
    else {
      payload.classes = this.classesTags;
      const courseControl = this.addInstituteForm.get('courses_name');
      const departmentControl = this.addInstituteForm.get('department');
      courseControl?.clearValidators();
      departmentControl?.clearValidators();
    }

    return payload;

  }

  ///////////////////////////////////////
  ///////   function for API PAYLOAD ////
  /////////////////////////////////////


}
