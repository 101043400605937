import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import { LayoutService } from "../../../../shared/services/layout.service";
import { CommonUtilsServiceService } from "src/app/shared/services/common-utils-service.service";
import { ToastrService } from "ngx-toastr";
import { ApiCallService } from "src/app/shared/services/api-call.service";
import { DataShareService } from "src/app/shared/services/data-share.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "src/app/shared/services/auth.service";
import { NavigationEnd, Router } from "@angular/router";
import { UserInfoComponent } from "src/app/components/pages/user-panel/widgets/user-info/user-info.component";
import { Subscription } from "rxjs";

@Component({
  selector: "app-header-one",
  templateUrl: "./header-one.component.html",
  styleUrls: ["./header-one.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderOneComponent implements OnInit, OnDestroy {
  //inputs
  @Input() headerLogo: string =
    "../../../../../assets/logos/Admission lylo.svg";
  @Input() darkHeaderLogo: string =
    "../../../../../assets/logos/Admission lylo.svg";
  @Input() headerClass?: string =
    "header-1 header-9 inner-page light-header shadow-cls";
  @Input() headerFix?: boolean = false;

  // private readonly _userDetailsService = inject(UserInfoComponent);

  //outputs
  @Output() userDetails = new EventEmitter<void>();

  //varaibles
  userAccount: boolean = false;
  LoggedUserDeatil: any;
  languageOpen: boolean = false;
  public headerFixed: boolean = false;
  private userDetailsLoaded = false;

  //services
  private _utils = inject(CommonUtilsServiceService);
  public _apiService = inject(ApiCallService);
  private _toaster = inject(ToastrService);
  private _modal = inject(DataShareService);
  private modalService = inject(NgbModal);
  private _authService = inject(AuthService);

  profileImage: string = '';
  private subscription: Subscription;

  constructor(public layout: LayoutService, private router: Router) { }

  ngOnInit(): void {
    console.log('HeaderOneComponent initialized');
    const token = this._authService.getToken();
    
    if (token) {
      this.getLoggedInUserDetails();
    }
    this.subscription = this._utils.profileImageUpdated$.subscribe(
      (updated) => {
        // console.log('Header received profile image update:', updated);
        if (updated) {
          this.LoggedUserDeatil.profileBase64 = updated;
          // console.log('Profile image in header updated to:', this.profileImage);
        }
      }
    );
    this.subscription = this._utils.userDetailsUpdated$.subscribe((upd) => {
      if (upd) {
        const storedUserDetails = localStorage.getItem("userDetails");

        if (storedUserDetails) {
          const parsedUserDetails = JSON.parse(storedUserDetails);
          this.LoggedUserDeatil.profileBase64 = parsedUserDetails?.profileBase64;
          this.LoggedUserDeatil.firstName = parsedUserDetails.firstName;
          this.LoggedUserDeatil.lastName = parsedUserDetails.lastName;
        }
      }
    })
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || 0;
    if (number > 400) {
      this.headerFixed = true;
    } else {
      this.headerFixed = false;
    }
  }

  menuItems = [
    {
      title: "Home",
      icon: "",
      type: "link",
      url: "",
      active: false,
      level: 1,
      children: [],
      path: "",
    },
    {
      title: "Services",
      icon: "",
      type: "link",
      active: false,
      level: 1,
      children: [
        {
          title: "Institutes",
          path: "/institutes/all-institutes",
          type: "link",
        },
        {
          title: "Teachers",
          path: "/teacher/teacher-list",
          type: "link",
        },
        {
          title: "Admissions",
          path: "/admissions/all-admissions",
          type: "link",
        },
        {
          title: "Scholarship",
          path: "/scholarships/all-scholarships",
          type: "link",
        },
        {
          title: "Jobs",
          path: "/jobs/all-jobs",
          type: "link",
        },
        {
          title: "Library",
          path: "/books/categories",
          type: "link",
        },
        {
          title: "CV Builder",
          path: "/cv-builder/cv-templates",
          type: "link",
          badge: true,
          badgeText: 'New',
        },
      ],
    },
    {
      title: "About",
      icon: "",
      type: "link",
      active: false,
      level: 1,
      children: [],
      path: "/about-us",
    },
    {
      title: "Contact",
      icon: "",
      type: "link",
      active: false,
      level: 1,
      children: [],
      path: "/contact-us",
    },
  ];

  private async getLoggedInUserDetails() {

    console.log("Attempting to get logged in user details");
    const storedUserDetails = localStorage.getItem("userDetails");

    if (storedUserDetails) {
      console.log("User details found in localStorage");
      const parsedUserDetails = JSON.parse(storedUserDetails);
      this.setUserDetails(parsedUserDetails);
      // Update the profile image here as well
      if (parsedUserDetails.profileBase64) {
        this.profileImage = parsedUserDetails?.profileBase64;
        this._utils.updateProfileImage(this.profileImage);
      }

    } else {
      console.log(
        "No user details found in localStorage. Fetching from API..."
      );
      try {
        const response = await this._apiService
          .GetCallWithToken("AdmissionLeloUsers/GetLoginUserDetails")
          .toPromise();
        if (response && response.responseCode === 200) {
          console.log("API response received:", response);
          this.setUserDetails(response.data.user);
          localStorage.setItem(
            "userDetails",
            JSON.stringify(response.data.user)
          );
          console.log("User details stored in localStorage");
          this._utils.notifyUserDetailsUpdated();
        } else {
          console.error("API call failed:", response);
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    }
  }

  // Make sure this method is called after successful login
  onLoginSuccess() {
    this.getLoggedInUserDetails();
  }

  setUserDetails(user: any) {
    this.LoggedUserDeatil = user;
    if (user?.profileBase64?.length < 20) {
      this.LoggedUserDeatil.profileBase64 =
        this._authService.generateFallbackImage(user?.firstName);
      
    }
      
   

    this.userDetails.emit(user);
    this.userAccount = true;
    let isDashboardExsists = false;
    this.menuItems.push({
      title: "Dashboard",
      icon: "",
      type: "link",
      url: "",
      active: false,
      level: 1,
      children: [],
      path: "/user/user-dashboard",
    });
  }

  openBackDropCustomClass(content: TemplateRef<any>) {
    this._modal.openSignInModal(content);
  }

  onUserLoggedIn() {
    this.modalService.dismissAll();
  }

  logoutUser(): void {
    this._authService.logout();
    this.userAccount = false;
    this.menuItems.pop();
    this.router.navigate(["/"]);
  }

  openLanguage() {
    this.languageOpen = !this.languageOpen;
  }

  clickOutside(): void {
    this.languageOpen = false;
  }
}
