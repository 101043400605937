<app-loader-two [isLoading]="isSpinnerShown"></app-loader-two>
<ng-template #addInstitute>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">
                Add New Institute
            </h5>
            <button type="button" class="btn-close" (click)="modal.dismissAll()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="addInstituteForm">
                <div class="row gx-3">
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-na">Institute Name<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-na" placeholder="Enter Institute Name"
                            formControlName="institute_name">
                        @if(addInstituteForm.get('institute_name')?.hasError('required') &&
                        addInstituteForm.get('institute_name')?.touched){
                        <small class="text-danger">Name is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-n">Institute Type<span class="text-danger">*</span></label>
                        <app-common-dropdowns class="first-dropdown" [defaultText]="'Select Institute Type'"
                            [dataList]="InstitueTypeList" (optionSelected)="onInstitueTypeSelected($event)"
                            [isDisabled]="true" [selectedOption]="InstitueType"
                            [clearThevalue]="InstitueType"></app-common-dropdowns>
                        @if(InstitueType == "" && instituteTypeError){
                        <small class="text-danger">Institute Type is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-n">Country<span class="text-danger">*</span></label>
                        <app-common-dropdowns class="second-dropdown" [defaultText]="'Enter Country Name'"
                            [dataList]="CountryList" (optionSelected)="onCountrySelected($event)"
                            [selectedOption]="Country" [clearThevalue]="Country"
                            (sendTheValue)="onCountryValueChange($event)"></app-common-dropdowns>
                        @if(Country == "" && countryTypeError){
                        <small class="text-danger">Country is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-na">State<span class="text-danger">*</span></label>
                        <app-common-dropdowns class="third-dropdown" [defaultText]="'Enter State Name'"
                            [dataList]="StateList" (optionSelected)="onStateSelected($event)" [selectedOption]="State"
                            [clearThevalue]="State" (sendTheValue)="onStateValueChange($event)"></app-common-dropdowns>
                        @if(State == "" && stateError){
                        <small class="text-danger">State is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-n">City<span class="text-danger">*</span></label>
                        <app-common-dropdowns class="fourth-dropdown" [defaultText]="'Enter City Name'"
                            [dataList]="CityList" (optionSelected)="onCitySelected($event)" [selectedOption]="City"
                            [clearThevalue]="City"></app-common-dropdowns>
                        @if(City == "" && cityError){
                        <small class="text-danger">City is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-n">Address<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-n" placeholder="Enter Address"
                            formControlName="institute_address">
                        @if(addInstituteForm.get('institute_address')?.hasError('required') &&
                        addInstituteForm.get('institute_address')?.touched){
                        <small class="text-danger">Address is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-na">Address title<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-na" placeholder="Enter Location title"
                            formControlName="institute_addressTitle">
                        @if(addInstituteForm.get('institute_addressTitle')?.hasError('required') &&
                        addInstituteForm.get('institute_addressTitle')?.touched){
                        <small class="text-danger">location is Required</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-n">Address link<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-n" placeholder="Enter Location link"
                            formControlName="institute_addressLink">
                        @if(addInstituteForm.get('institute_addressLink')?.hasError('required') &&
                        addInstituteForm.get('institute_addressLink')?.touched){
                        <small class="text-danger">location link is Required</small>
                        }
                        @if(addInstituteForm.get('institute_addressLink')?.touched &&
                        addInstituteForm.get('institute_addressLink')?.hasError('pattern')
                        ){
                        <small class="text-danger"> https://maps.google.com/maps</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-n">Site link<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-n" placeholder="Enter Website link"
                            formControlName="site_link">
                        @if(addInstituteForm.get('site_link')?.hasError('required') &&
                        addInstituteForm.get('site_link')?.touched){
                        <small class="text-danger">Site link is Required</small>
                        }
                        @if(addInstituteForm.get('site_link')?.touched &&
                        addInstituteForm.get('site_link')?.hasError('pattern')
                        ){
                        <small class="text-danger"> https://</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-na">Email<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-na" placeholder="Enter Email"
                            formControlName="email_address">
                        @if(addInstituteForm.get('email_address')?.hasError('required') &&
                        addInstituteForm.get('email_address')?.touched){
                        <small class="text-danger">Email is Required</small>
                        }
                        @if(addInstituteForm.get('email_address')?.touched &&
                        addInstituteForm.get('email_address')?.hasError('pattern')
                        ){
                        <small class="text-danger">Invalid Email</small>
                        }
                    </div>
                    <div class="form-group col-6 col-sm-4">
                        <label for="a-n">Phone Number<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-n" placeholder="Enter Phone Number"
                            formControlName="phone" minlength="10" maxlength="18" min="0">
                        @if(addInstituteForm.get('phone')?.hasError('required') &&
                        addInstituteForm.get('phone')?.touched){
                        <small class="text-danger">Phone is Required</small>
                        }
                        @if(addInstituteForm.get('phone')?.touched && addInstituteForm.get('phone')?.hasError('pattern')
                        ){
                        <small class="text-danger">Phone Number must in between 10-18</small>
                        }
                    </div>
                    @if(showDepartment){
                    <div class="form-group col-12 col-sm-4">
                        @if(addInstituteForm.get('department')){
                        <label for="a-n">Departments<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-n" placeholder="Add Departments"
                            formControlName="department" (keyup.enter)="onEnterDepartment($event)">
                        @if(departmentTags.length == 0 &&
                        addInstituteForm.get('department')?.touched){
                        <small class="text-danger">Add atleast one Department</small>
                        }
                        @for(departmemntTag of departmentTags; track departmemntTag){
                        <div class="tag" (click)="removeDepartmentTags(departmemntTag)">
                            {{ departmemntTag.departmentName }}
                            <span class="close-icon">&times;</span>
                        </div>
                        }
                        }
                    </div>


                    <div class="form-group col-12 col-sm-4">
                        @if(addInstituteForm.get('courses_name')){
                        <label for="a-n">Courses<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-n" placeholder="Add Courses"
                            formControlName="courses_name" (keyup.enter)="onEnterKeyPress($event)">
                        @if(coursestags.length == 0 &&
                        addInstituteForm.get('courses_name')?.touched){
                        <small class="text-danger">Add atleast one Course</small>
                        }
                        @for(courseTag of coursestags; track coursestags){
                        <div class="tag" (click)="removeCourseTag(courseTag)">
                            {{ courseTag.courseName }}
                            <span class="close-icon">&times;</span>
                        </div>
                        }
                        }
                    </div>

                    }
                    @if(showClasses){
                    <div class="form-group col-12 col-sm-4">
                        @if(addInstituteForm.get('classes_name')){
                        <label for="a-n">Classes<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" id="a-n" placeholder="Add Classes"
                            formControlName="classes_name" (keyup.enter)="onEnterKey($event)">
                        @if(classesTags.length == 0 &&
                        addInstituteForm.get('classes_name')?.touched){
                        <small class="text-danger">Add atleast one Class</small>
                        }
                        @for(classTag of classesTags; track classesTags){
                        <div class="tag" (click)="removeClassTags(classTag)">
                            {{ classTag.classTitle }}
                            <span class="close-icon">&times;</span>
                        </div>
                        }
                        }
                    </div>
                    }
                </div>

                <div class="row gx-3">
                    <div class="form-group col-12">
                        <label for="a-na">Description<span class="text-danger">*</span></label>
                        <textarea rows="4" name="" id="" class="form-control" placeholder="Enter Description"
                            formControlName="description" (input)="updateCharCount()"></textarea>
                        <small class="">{{ charCount }}/400</small>
                        @if(addInstituteForm.get('description')?.hasError('required') &&
                        addInstituteForm.get('description')?.touched){
                        <small class="text-danger">Description is Required</small>
                        }
                    </div>
                </div>
                <div class="row gx-3">
                    <div class="form-group col-12">
                        <label for="a-na">Vedio(mp4)</label>
                        <input type="text" class="form-control" id="a-na" placeholder="Enter Youtube link"
                            formControlName="video_link">
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-12 mb-0">
                        <label>Additional features</label>
                        <div class="additional-checkbox">
                            @for(features of AdditionalFeatures; track features){
                            <label for="{{ features.id }}">
                                <input class="checkbox_animated color-4" id="{{ features.id }}" type="checkbox"
                                    (change)="updateCheckedValues(features)" [checked]="features.checked" />
                                {{ features.title }}
                            </label>
                            }
                        </div>
                    </div>
                </div>
            </form>
            <div class="dropzone-admin mb-0">
                <label>Media <span class="font-danger">*</span></label>
                <form class="dropzone" id="multiFileUpload">
                    <ngx-dropzone (change)="onSelect($event)" accept="image/jpeg,image/jpg,image/png"
                        [multiple]="false">
                        <ngx-dropzone-label>
                            <div class="dz-message needsclick">
                                <i class="fas fa-cloud-upload-alt"></i>
                                <h6 class="f-w-700 mb-0">
                                    Drop files here or click to upload.
                                </h6>
                            </div>
                        </ngx-dropzone-label>
                        @for(f of files; track f){
                        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" [removable]="true"
                            (removed)="onRemove(f)" [file]="f">
                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>

                        </ngx-dropzone-image-preview>
                        }
                    </ngx-dropzone>
                </form>




            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-dashed color-2 btn-pill" (click)="modal.dismissAll()">Cancel</button>
            <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="onSubmit()">
                Add Institute
            </button>
        </div>
    </div>

</ng-template>

