<div class="mainContainer">
    <div class="headers">
        <h6>Interests</h6>
        <div></div>
        <div class="addHeadings" (click)="addCard(addInterest)">
            <app-feather-icons class="color" [icon]="'plus'"></app-feather-icons>
            <span> Add Interest </span>
        </div>
    </div>
    @defer {
    <div class="tagsContainer">
        <!-- @if(isLoading){
      <div class="item">
        <ngx-skeleton-loader count="6" [theme]="{
            height: '40px',
            width: '150px',
            'border-radius': '10px',
            'margin-right': '1rem'
          }" />
      </div>
      } @else if(interestDetails?.length > 0){ @for(interest of
      interestDetails;track interest){ -->
        <div class="tag">
            <div class="tagContent">
                <!-- {{ interest?.interestName }} -->
                Sports
                <span class="icons">
                    <!-- <i class="fas fa-edit edit-icon" (click)="editCard(addInterest)"></i> -->
                    <!-- <i class="fas fa-trash delete-icon" (click)="deleteCard(deleteModal)"></i> -->
                </span>
            </div>
        </div>
        <div class="tag">
            <div class="tagContent">
                Creativity
            </div>
        </div>
        <div class="tag">
            <div class="tagContent">
                Learning new things
                <span class="icons">
                    <i class="fas fa-edit edit-icon"></i>
                    <i class="fas fa-trash delete-icon"></i>
                </span>
            </div>
        </div>
        <!-- } -->
        <!-- } -->
    </div>
    }
</div>

<ng-template #addInterest>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">
                @if(FormName == "Add"){ Add Interest }@else{ Update Interest }
            </h5>
            <button type="button" class="btn-close" (click)="modal.dismissAll()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form class="row gx-2 gx-sm-3">
                <div class="form-group col-sm-12">
                    <label for="interest">Interests<span class="text-danger">*</span></label>
                    <app-common-dropdowns class="interest-dropdown" [dataList]="interestList"
                        [defaultText]="'Select Interest*'" (optionSelected)="onInterestSelected($event)"
                        [selectedOption]="interest" [isDisabled]="false" [clearThevalue]="interest">
                    </app-common-dropdowns>
                    @if(interest == "" && interestError){
                    <small class="text-danger">Interest is Required</small>
                    }
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-dashed color-2 btn-pill" (click)="modal.dismissAll()"
                aria-label="Close">
                Cancel
            </button>
            <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="saveInterestDetails()"
                aria-label="Close">
                @if(FormName == "Add"){ Add }@else{ Update }
            </button>
        </div>
    </div>
</ng-template>

<ng-template #deleteModal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" (click)="modal.dismissAll()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="text">Are you sure you want to delete this interest?</div>
            <div class="deleteButtons">
                <button type="button" class="btn btn-dashed color-2 btn-pill" (click)="modal.dismissAll()"
                    aria-label="Close">
                    Cancel
                </button>
                <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="deleteInterest()"
                    aria-label="Delete">
                    Delete
                </button>
            </div>
        </div>
    </div>
</ng-template>