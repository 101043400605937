import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { PropertyService } from '../../../services/property.service';
import { HappyClient, LatestBlog, WhyChooseUsItem, aboutData, agents } from '../../../interface/property';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { MetaService } from 'src/app/shared/services/meta.service';
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AboutUsComponent implements OnInit {
  private readonly metaService: MetaService = inject(MetaService);
  @Input() tagClass: string;
  @Input() sectionClass: string;

  private _utils = inject(CommonUtilsServiceService);
  private _apiService=inject(ApiCallService);

  constructor(private sanitizer: DomSanitizer,
    private propertyService : PropertyService) {}
ngOnInit(){
  window.scrollTo({ top: 0, behavior: 'smooth' });
  this.getTotalRecords();


  this.metaService.updateTitle('About Us | AdmissionLylo');
    this.metaService.updateMetaTags([
      { name: 'description', content: 'Your page description here' },
      { name: 'keywords', content: 'angular, seo, meta tags' },
      { name: 'author', content: 'Your Name' },
      { name: 'robots', content: 'index, follow' }
    ]);
    this.metaService.setCanonicalURL();
    this.metaService.initializeMetaData();

    
}
  public getSafeImageUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  navigateTospecificComponent(route: string) {
    this._utils.navigateTo(route);
  }
  

  public bgImage = 'assets/images/banner/banner.webp';
  public Title = 'About Us';
  public parent = 'Home';
  public child = 'About Us';

  public aboutDesc = 'Our platform offers comprehensive resources for students, covering all educational aspects.';

  public chooseUsDesc = 'We are dedicated to supporting students with a one-stop platform that lists top institutes, open admissions, job opportunities, and more.';

  public happyClientDesc = 'We are proud to have helped thousands of students reach their educational goals. Our users’ success stories inspire us every day.';

  public blogDesc = 'Stay updated with the latest news in education, career opportunities, and expert tips for students.';

 // Dummy Data for FOUNDERS section 
   foundersData = [
    {
      id: 1,
      img: "assets/images/portfolio/arslan.jpeg",
      name: "Arslan Yousaf",
      title: "Owner & Founder",
      email: "arslan@gmail.com",
      desc: "As the Owner and Founder of a software company, Arslan drives innovation and leads a team dedicated to delivering cutting-edge solutions."
    },
    {
      id: 2,
      img: "assets/images/portfolio/ateeq.jpeg",
      name: "Atiq Ur Rehman",
      title: "Owner & Founder",
      email: "atiqurrehman@gmail.com",
      desc: "Atiq, as the Owner and Founder, oversees operations and fosters a culture of excellence to ensure client satisfaction in all projects."
    }
    
  ];

  public founderOptions = {
    loop: true,
    nav: true,
    dots:false,
    margin: 25,
    navText : ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
    responsive: {
      0: {
        items: 1,
      },
      668 : {
        items : 2
      },
    }
  }
  

  // Dummy Data for why CHOOSE US section 
  whyChooseUsData = [
    {
      id: 1,
      title: 'Comprehensive Listings',
      description: 'We offer a wide range of listings including institutes,jobs, scholarships and much more for students.',
      icon: 'list',
    },
    {
      id: 2,
      title: 'Scholarship Finder',
      description: 'We help students discover different scholarships to support their education across various fields. ',
      icon: 'award',
    },
    {
      id: 3,
      title: 'Seamless Admissions',
      description: 'Stay updated with open admissions from leading institutes and apply with ease through our platform.',
      icon: 'briefcase',
    },
    // {
    //   id: 4,
    //   title: 'Expert Guidance',
    //   description: 'Our platform provides tutorials and access to extra study materials guided by top-rated teachers.',
    //   icon: 'book',
    // },
    // {
    //   id: 5,
    //   title: 'Career Building',
    //   description: 'Build your CV with our advanced tools and find the best job opportunities tailored for you.',
    //   icon: 'briefcase',
    // },
  ];

  // Dummy Data for HAPPY CLIENTS section 
  happyClientsData = [
    {
      name: 'Sophia Lee',
      title: 'Student',
      desc: 'Sophia found her dream college through the institute listings on our website and secured a scholarship.',
      email: 'sophia.lee@example.com',
      images: 'assets/images/about/clients/SophiaLee.jpg', 
      
    },
    {
      name: 'James Carter',
      title: 'Tutor',
      desc: 'James uses our platform to connect with students for extra tutorials and helps them succeed academically.',
      email: 'james.carter@example.com',
      images: 'assets/images/about/clients/JamesCarter.jpg', 
     
    },
    {
      name: 'Emma Watson',
      title: 'Job Seeker',
      desc: 'With the help of our CV builder and job listings, Emma successfully landed her first job after graduation.',
      email: 'emma.watson@example.com',
      images: 'assets/images/about/clients/EmmaWatson.jpg', // Path to Emma's image
    },
    {
      name: 'Daniel Thompson',
      title: 'University Admin',
      desc: 'Our platform has helped Daniel streamline admissions for his university and reach more students.',
      email: 'daniel.thompson@example.com',
      images: 'assets/images/about/clients/DanielThompson.jpg', // Path to Daniel's image
    }
  ];

  

  public clientOptions = {
    loop: true,
    nav: true,
    dots: false,
    autoplay: true,
    autoplayTimeOut: 1000,
    navText: [
      "<i class='fa fa-chevron-left'></i>",
      "<i class='fa fa-chevron-right'></i>",
    ],
    responsive: {
      0: {
        items: 1,
      },
    },
  };

  // Dummy Data for LATEST BLOG section 
  latestBlogData = [
    {
      img: 'assets/images/about/blogs/blog1.jpg',
      width: 6000,
      height: 4000,
      date: '01',
      month: 'Sep',
      city: 'New York',
      title: 'How to Choose the Right Institute',
      desc: 'A comprehensive guide for students to find the perfect institute for their career goals and needs.',
    },
    {
      img: 'assets/images/about/blogs/blog2.jpg',
      width: 5760,
      height: 3840,
      date: '15',
      month: 'Oct',
      city: 'San Francisco',
      title: 'Top Scholarships for 2024',
      desc: 'An in-depth look at the best scholarships available for students in 2024 and how to apply for them.',
    },
    {
      img: 'assets/images/about/blogs/blog3.jpg',
      width: 5576,
      height: 3565,
      date: '30',
      month: 'Aug',
      city: 'Los Angeles',
      title: 'CV Building Tips',
      desc: 'Learn how to build a compelling CV that will help you stand out to employers and land the best jobs.',
    },
    {
      img: 'assets/images/about/blogs/blog4.jpg',
      width: 7990,
      height: 5334,
      date: '12',
      month: 'Sep',
      city: 'Chicago',
      title: 'Balancing Studies and Part-Time Jobs',
      desc: 'Tips and strategies for students to balance their academic responsibilities with part-time work.',
    },
    {
      img: 'assets/images/about/blogs/blog5.jpg',
      width: 6048,
      height: 4024,
      date: '05',
      month: 'Nov',
      city: 'Boston',
      title: 'Best Online Courses for Upskilling',
      desc: 'Explore the top online courses to improve your skills and boost your career prospects in 2024 to get high paying jobs.',
    }
];



  public blogOptions = {
    loop: true,
    nav: false,
    dots: false,
    responsive: {
      0: {
        items: 1,
      },
      668: {
        items: 2,
      },
      1240: {
        items: 3
      }
    },
  };
  totalRecords: any = {
    institutes: 0,
    admissions: 0,
    jobs: 0,
    scholarships: 0,
    books: 0,
    teachers:0,
  };

  getTotalRecords() {
    this._apiService.GetCallWithoutToken("HomePage/GetBannerTotalCounts").subscribe((res: any) => {
      if (res && res.data && res.data.length > 0) {
        const data = res.data[0];  
        this.totalRecords.institutes = data.totalInstitutes || 0;
        this.totalRecords.admissions = data.totalAdmissions || 0;
        this.totalRecords.scholarships = data.totalScholarships || 0;
        this.totalRecords.jobs = data.totalJobs || 0;
        this.totalRecords.books = data.totalBooks || 0;
        this.totalRecords.teachers = data.totalTeachers || 0;
      }
    });
  }
  

}




