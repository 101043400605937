import { Component } from '@angular/core';

@Component({
  selector: 'app-hostel-list',
  templateUrl: './hostel-list.component.html',
  styleUrl: './hostel-list.component.scss'
})
export class HostelListComponent {

}
