<div class="dental-tourism-footer-area pt-100">
    <div class="container">
        <div class="row justify-content-center justify-content-space-between wholeFooter">
            <div class="col-xl-3 col-lg-4 col-sm-12">
                <div class="dental-tourism-footer-widget">
                    <!-- <h2>
                            <a> ATG SYSTEM</a>
                        </h2> -->
                    <a href=""><img src="assets/logos/Admission lylo.svg" aria-label="clickable-text" class="img-fluid" alt="logo" /></a>

                    <ul class="quick-links">
                        <li>
                            <app-feather-icons [icon]="'map-pin'"></app-feather-icons>
                            <a href="https://www.google.com/maps/search/?api=1&query=Bahria Town, Lahore" aria-label="clickable-text"
                                target="_blank">
                                Bahria Town, Lahore
                            </a>
                        </li>

                        <li>
                            <app-feather-icons [icon]="'phone'"></app-feather-icons>
                            <a href="tel:+923338828442" aria-label="clickable-text">+92 333 8828442</a>
                        </li>

                        <li>
                            <app-feather-icons [icon]="'mail'"></app-feather-icons>
                            <a href="mailto:atgsystems@gmail.com" aria-label="clickable-text">atgsystems&#64;gmail.com</a>
                        </li>
                    </ul>
                    
                    <ul class="share-link">
                        <li>
                            <span class="follow-us">Follow us</span>
                        </li>
                        <li>
                            <a target="_blank" aria-label="clickable-text">
                                <img src="../../../../assets/images/svg/socialIcons/facebook.png" alt="facebook" />
                            </a>
                        </li>
                        <li>
                            <a target="_blank" aria-label="clickable-text">
                                <img src="../../../../assets/images/svg/socialIcons/twitter.png" alt="twitter" />
                            </a>
                        </li>
                        <li>
                            <a target="_blank" aria-label="clickable-text">
                                <img src="../../../../assets/images/svg/socialIcons/linkdin.svg" alt="linkedin" />
                            </a>
                        </li>
                        <li>
                            <a target="_blank" aria-label="clickable-text">
                                <img src="../../../../assets/images/svg/socialIcons/instagram.svg" alt="instagram" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-xl-2 col-lg-4 col-sm-12">
                <div class="dental-tourism-footer-widget links-section">
                    <h3>Our Services</h3>
                    <ul class="quick-links">
                        <li>
                            <app-feather-icons [icon]="'arrow-right'"></app-feather-icons>
                            <a (click)="navigateTospecificComponent('/institutes/all-institutes')" aria-label="clickable-text">Institutes</a>
                        </li>

                        <li>
                            <app-feather-icons [icon]="'arrow-right'"></app-feather-icons>
                            <a (click)="navigateTospecificComponent('/teacher/teacher-list')" aria-label="clickable-text">Teachers</a>
                        </li>

                        <li>
                            <app-feather-icons [icon]="'arrow-right'"></app-feather-icons>
                            <a (click)="navigateTospecificComponent('/admissions/all-admissions')" aria-label="clickable-text">Admissions</a>
                        </li>
                        
                        <li>
                            <app-feather-icons [icon]="'arrow-right'"></app-feather-icons>
                            <a (click)="navigateTospecificComponent('/scholarships/all-scholarships')" aria-label="clickable-text">Scholarships</a>
                        </li>

                        <li>
                            <app-feather-icons [icon]="'arrow-right'"></app-feather-icons>
                            <a (click)="navigateTospecificComponent('/jobs/all-jobs')" aria-label="clickable-text">Jobs</a>
                        </li>

                        <li>
                            <app-feather-icons [icon]="'arrow-right'"></app-feather-icons>
                            <a (click)="navigateTospecificComponent('/books/categories')" aria-label="clickable-text">Library</a>
                        </li>

                        <!-- <li>
                            <app-feather-icons [icon]="'arrow-right'"></app-feather-icons>
                            <a (click)="navigateTospecificComponent('/teacher/teacher-list')">
                                Teachers</a>
                        </li> -->
                    </ul>
                </div>
            </div>
            <div class="col-xl-2 col-lg-4 col-sm-12">
                <div class="dental-tourism-footer-widget links-section">
                    <h3>Useful Links</h3>
                    <ul class="quick-links">
                        <li>
                            <app-feather-icons [icon]="'arrow-right'"></app-feather-icons>
                            <!-- <a>Web Development</a> -->
                            <a (click)="
                  navigateTospecificComponent(
                    'about-us'
                  )
                " aria-label="clickable-text">About us</a>
                        </li>
                        <li>
                            <app-feather-icons [icon]="'arrow-right'"></app-feather-icons>
                            <!-- <a>BackEnd</a> -->
                            <a (click)="navigateTospecificComponent('contact-us')" aria-label="clickable-text">Contact us</a>
                        </li>
                        <li>
                            <app-feather-icons [icon]="'arrow-right'"></app-feather-icons>
                            <!-- <a>FrontEnd</a> -->
                            <a (click)="navigateTospecificComponent('privacy-policy')" aria-label="clickable-text">Privacy Policy</a>
                        </li>
                        <li>
                            <app-feather-icons [icon]="'arrow-right'"></app-feather-icons>
                            <!-- <a>FrontEnd</a> -->
                            <a (click)="
                            navigateTospecificComponent('terms-conditions')
                          " aria-label="clickable-text">Terms & Conditions</a>
                        </li>
                        <li>
                            <app-feather-icons [icon]="'arrow-right'"></app-feather-icons>
                            <!-- <a>FrontEnd</a> -->
                            <a (click)="navigateTospecificComponent('/faqs')" aria-label="clickable-text">FAQ's</a>
                        </li>
                        <!-- <li>
                                <i class="fa-solid fa-arrow-right"></i>
                             
                                <a href="#">Our Team</a>
                            </li>
                            <li>
                                <i class="fa-solid fa-arrow-right"></i>
                                <a (click)="naviageToOurServices()">Our Services</a>
                            </li> -->
                    </ul>
                </div>
            </div>
            <div class="col-xl-3 col-lg-8 col-sm-12 newsLetter">
                <div class="dental-tourism-footer-widget links-section ">
                    <h3>Newsletter</h3>
                    <form class="newsletter-form" [formGroup]="subscriber_From">
                        <p class="line-height">
                            Make sure to subscribe!
                        </p>
                        <div class="form-group">
                            <input type="email" class="input-newsletter" placeholder="sample@gmail.com" name="email"
                                formControlName="Email" autocomplete="off" />
                            <button aria-label="Button" type="submit" (click)="Subscribe()">
                                <i class="fas fa-paper-plane"></i>
                            </button>
                            <div class="error">
                                @if(subscriber_From.get('Email')?.hasError('required') &&
                                subscriber_From.get('Email')?.touched){
                                <small class="text-danger">Email is Required</small>
                                }@else if(subscriber_From.get('Email')?.hasError('pattern') &&
                                subscriber_From.get('Email')?.touched){
                                <small class="text-danger">Invalid Email</small>
                                }
                            </div>
                        </div>
                        
                    </form>
                </div>
                <!-- google map -->
                <div class="page-section" id="location">
                    <!-- <h3 id="location">Location</h3> -->
                    <iframe class="iframe"
                    title="realestate location"
                        src="https://maps.google.com/maps?q=+221-22+B+Side+Commercial%2C+Tulip+Extension+Tulip+Block+Sector+C+Bahria+Town%2C+Lahore%2C+Punjab+53720&t=&z=13&ie=UTF8&iwloc=&output=embed"
                        frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                    </iframe>

                </div>


            </div>
        </div>
        <div class="dental-tourism-copyright-area">
            <div class="container copyright-area">
                <div class="copyright-area-content">
                    <p class="copyRightText">
                        Copyright © 2024, All Right Reserved
                        <a target="_blank" aria-label="clickable-text">Admission Lylo</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="col-xl-3 col-lg-8 col-sm-12 newsLetter">
        <div class="dental-tourism-footer-widget links-section ">
            <h3>Newsletter</h3>
            <form class="newsletter-form">
                <p class="line-height">
                    Make sure to subscribe!
                </p>
                <div class="form-group">
                    <input type="email" class="input-newsletter" placeholder="sample@gmail.com" name="email" required
                        autocomplete="off" />
                    <button type="submit">
                        <i class="fa-solid fa-paper-plane-top"></i>
                    </button>
                </div>
            </form>
        </div>
        <div class="page-section" id="location">
            <iframe class="iframe"
                src="https://maps.google.com/maps?q=+221-22+B+Side+Commercial%2C+Tulip+Extension+Tulip+Block+Sector+C+Bahria+Town%2C+Lahore%2C+Punjab+53720&t=&z=13&ie=UTF8&iwloc=&output=embed"
                frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
            </iframe>

        </div>


    </div> -->
</div>
<!-- </div>
</div> -->