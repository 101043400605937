import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HostelsRoutingModule } from './hostels-routing.module';
import { HostelListComponent } from './hostel-list/hostel-list.component';


@NgModule({
  declarations: [
    HostelListComponent
  ],
  imports: [
    CommonModule,
    HostelsRoutingModule
  ]
})
export class HostelsModule { }
