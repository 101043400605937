import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-question-template',
  templateUrl: './question-template.component.html',
  styleUrl: './question-template.component.scss'
})
export class QuestionTemplateComponent {
  public bgImage = 'assets/images/banner/banner.webp';
  public title = 'Question';
  public parent = 'Teacher';
  public child = 'Question';

  public isSpinnerShown = false;

  question: any;
  timer: number = 60; // Timer set to 60 seconds for each question
  interval: any;
  selectedAnswer: string = '';
  testStatus: { [key: string]: boolean } = {}; // To track if question is completed

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
   
  ) {}

  ngOnInit(): void {
    const questionId = this.activatedRoute.snapshot.paramMap.get('id');
    if (questionId) {
      // Find the question matching the questionId
      this.question = this.questions.find(q => q.id === questionId);
    }
    this.startTimer();
  }
   
  


  startTimer(): void {
    this.interval = setInterval(() => {
      if (this.timer > 0) {
        this.timer--;
      } else {
        clearInterval(this.interval);
        this.submitAnswer(); // Automatically submit when timer reaches 0
      }
    }, 1000);
  }

  selectAnswer(answer: string): void {
    this.selectedAnswer = answer;
  }

  submitAnswer(): void {
    if (this.selectedAnswer) {
      const isCorrect = this.selectedAnswer === this.question.correctAnswer;
      // Mark the question as completed
      this.testStatus[this.question.id] = true;

      // Optionally, store the answer, or call a backend API to save the answer
      console.log('Answer submitted:', isCorrect ? 'Correct' : 'Incorrect');

      // Navigate back to the dashboard or proceed to the next question
      this.router.navigate(['/dashboard']);
    } else {
      alert('Please select an answer!');
    }
  }

  ngOnDestroy(): void {
    // Clear the timer interval when the component is destroyed
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  questions = [
    {
      id: '1',
      title: 'Math Test',
      description: 'Basic Math questions to test your skills',
      difficulty: 'Easy',
      questionText: 'What is 2 + 2?',
      options: ['3', '4', '5', '6'],
      correctAnswer: '4'
    },
    {
      id: '2',
      title: 'Science Test',
      description: 'Basic Science questions to test your knowledge',
      difficulty: 'Medium',
      questionText: 'What is the chemical symbol for water?',
      options: ['H2O', 'CO2', 'O2', 'N2'],
      correctAnswer: 'H2O'
    },
    {
      id: '3',
      title: 'History Test',
      description: 'Questions based on world history',
      difficulty: 'Hard',
      questionText: 'Who was the first president of the United States?',
      options: ['Abraham Lincoln', 'George Washington', 'Thomas Jefferson', 'John Adams'],
      correctAnswer: 'George Washington'
    },
    {
      id: '4',
      title: 'Geography Test',
      description: 'Questions related to world geography',
      difficulty: 'Easy',
      questionText: 'What is the capital of France?',
      options: ['Berlin', 'Madrid', 'Paris', 'Rome'],
      correctAnswer: 'Paris'
    },
    {
      id: '5',
      title: 'Literature Test',
      description: 'Test your knowledge on famous books and authors',
      difficulty: 'Medium',
      questionText: 'Who wrote "Romeo and Juliet"?',
      options: ['William Shakespeare', 'Charles Dickens', 'Jane Austen', 'Homer'],
      correctAnswer: 'William Shakespeare'
    }
  ];
}
