import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { Router } from '@angular/router';
import { sideMenu, userPanelSideMenu } from '../../../../../shared/data/user-panel';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-user-panel-side-menu',
  templateUrl: './user-panel-side-menu.component.html',
  styleUrls: ['./user-panel-side-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserPanelSideMenuComponent {

  @Input() activeTab: string;


  private _authService = inject(AuthService);

  public userPanelSideMenu = userPanelSideMenu;

  constructor(private router: Router) { }

  getPage(data: sideMenu) {

    if (data.value === 'logout') {
      this._authService.logout();
      this.router.navigate(['']);
    } else {
      this.activeTab = data.value;
      this.router.navigate([data.path]);
    }
  }


  // dropdown: boolean = false;
  // toggleDropdown() {
  //   
  //   this.dropdown = !this.dropdown
  // }
}
