
<app-header-one></app-header-one>
<app-common-banner-1 [imagePath]="bannerImage" [heading1]="'Books'"
  [heading2]="'for Knowlwdge, Education, and Success'" (childEvent)="handleChildEvent($event)"
  (callAPionEmptyFields)="onReset()" [showCountryDropdown]="true" [showStateDropdown]="true" [showCityDropdown]="true"
  [showRangeSlider]="false" [showDegreeDropdown]="false" [showJobTypeDropdown]="false"
  [showScholarshipTypeDropdown]="false" [totalRecords]="totalRecords"></app-common-banner-1>


<div class="container-fluid">
  <!-- <app-corporate-home-slider></app-corporate-home-slider> -->
  <app-grid-panel [heading]="'Books'" [currentPage]="currentPage" [totalRecords]="totalRecords"
    [itemsPerPage]="itemsPerPage" [agency]="true" ></app-grid-panel>

  @if(isDataLoading){
  <div class="item">
    <ngx-skeleton-loader count="8" [theme]="{
      height: '300px',
      width: '300px',
      'border-radius': '10px',
      'margin-right': '1rem'
      }" />
  </div>
  }@else if (noRecordFound) {
    <div class="no-record">
      <img src="../../../../assets/images/svg/Nodata.gif" alt="No records found">
      <p>No Books Found</p>
    </div>
  
  }@else{
    <section class="agent-section property-section">
      <div class="container-fluid teacher-cards">
          <div class="row">
            <div class=" property-grid-3 agent-grids">
              <div class="property-wrapper-grid list-view">
                <app-common-agency [agencyData]="booksData" [type]="'grid-3'"></app-common-agency>
              </div>
            </div>
          </div>
      </div>
    </section>
  }


  <!-- pagination starts here -->
  @if(booksData.length !== 0){
  <div class="row w-100 pagination-container">
    <div class="mt30 d-flex justify-content-center">
      <ngb-pagination [collectionSize]="totalRecords" [(page)]="currentPage" [pageSize]="itemsPerPage"
        (pageChange)="onPageChanged($event)" [maxSize]="maxVisiblePages" [ellipses]="false" [boundaryLinks]="true">
      </ngb-pagination>
    </div>
    <div class="tRecord text-center">
      {{ getDisplayRange() }} of {{ totalRecords }}
    </div>
  </div>
  }
  <!-- pagination ends here -->


</div>


<app-adly-footer></app-adly-footer>