<div class="body">
    <div class="container">
        <div class="left_Side">
            <div class="profileText">
                <div class="imgBx">
                    <img [src]="userData?.profileBase64 || 'https://default-image-url.com/default-profile.png'"
                        class="img-fluid bg-img" alt="profile-image" />
                </div>
                <h2>Emaan Bari<br><span>Computer Scientist</span></h2>
            </div>
            <div class="contactInfo">
                <h3 class="title">Contact Info</h3>
                <ul>
                    <li>
                        <span class="icon"><i class="fa fa-phone" aria-hidden="true"></i></span>
                        <span class="text">0900 786 01</span>
                    </li>
                    <li>
                        <span class="icon"><i class="fa fa-envelope" aria-hidden="true"></i></span>
                        <span class="text">emmigmail.com</span>
                    </li>
                    <li>
                        <span class="icon"><i class="fa fa-globe" aria-hidden="true"></i></span>
                        <span class="text">mywebsite.com</span>
                    </li>
                    <li>
                        <span class="icon"><i class="fa fa-linkedin" aria-hidden="true"></i></span>
                        <span class="text">www.linkedin.com</span>
                    </li>
                    <li>
                        <span class="icon"><i class="fa fa-map-marker" aria-hidden="true"></i></span>
                        <span class="text">56th street,california</span>
                    </li>
                </ul>
            </div>
            <div class="contactInfo education">
                <h3 class="title">EDUCATION</h3>
                <ul>
                    <li>
                        <h5>2017 - 2019</h5>
                        <h4> Matric in Science</h4>
                        <h4>School Name </h4>
                    </li>
                    <li>
                        <h5>2019 - 2021</h5>
                        <h4> Intermediate in Maths</h4>
                        <h4>College Name</h4>
                    </li>
                    <li>
                        <h5>2021 - Now</h5>
                        <h4>Undergraduate in Computer Science</h4>
                        <h4>University Name</h4>
                    </li>
                </ul>
            </div>
            <div class="contactInfo language">
                <h3 class="title">LANGUAGES</h3>
                <ul>
                    <li>
                        <span class="text">English</span>
                        <span class="percent"></span>
                        <div style="width: 90%;"></div>
                    </li>
                    <li>
                        <span class="text">Urdu</span>
                        <span class="percent"></span>
                        <div style="width: 80%;"></div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="right_Side">
            <div class="about">
                <h2 class="title2">Profile</h2>
                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Porro exercitationem nesciunt,<br> tenetur
                    architecto omnis maxime delectus quae quo reprehenderit quas laudantium.Itaque sequi commodi vero
                    suscipit reiciendis ea aspernatur cum.Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Porro exercitationem nesciunt,tenetur architecto omnis maxime delectus quae quo reprehenderit quas
                    laudantium. <br>Itaque sequi commodi vero suscipit reiciendis ea aspernatur cum.</p>
            </div>
            <div class="about">
                <h2 class="title2">Experience</h2>
                <div class="box">
                    <div class="year_company">
                        <h5>2018 - 2019</h5>
                        <h5>Company Name</h5>
                    </div>
                    <div class="text">
                        <h4>Junior Web Developer</h4>
                        <p>Lorem ipsum,dolor sit amet consectetur adipisicing elit. Porro exercitationem
                            nesciunt,tenetur architecto omnis Lorem ipsum,dolor sit amet consectetur adipisicing elit.
                            Porro exercitationem
                            nesciunt,tenetur architecto omnis </p>
                    </div>
                </div>
                <div class="box">
                    <div class="year_company">
                        <h5>2019 - 2021</h5>
                        <h5>Company Name</h5>
                    </div>
                    <div class="text">
                        <h4>Senior Web Developer</h4>
                        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Porro exercitationem nesciunt,
                            tenetur architecto omnis Lorem ipsum,dolor sit amet consectetur adipisicing elit. Porro
                            exercitationem
                            nesciunt,tenetur architecto omnis </p>
                    </div>
                </div>
                <div class="box">
                    <div class="year_company">
                        <h5>2021 - present</h5>
                        <h5>Company Name</h5>
                    </div>
                    <div class="text">
                        <h4>Data Analyist</h4>
                        <p>Lorem ipsum,dolor sit amet consectetur adipisicing elit. Porro exercitationem
                            nesciunt,tenetur architecto omnis Lorem ipsum,dolor sit amet consectetur adipisicing elit.
                            Porro exercitationem
                            nesciunt,tenetur architecto omnis </p>
                    </div>
                </div>
            </div>
            <div class="about skills">
                <h2 class="title2">Professional Skills</h2>
                <div class="box">
                    <h4>Html</h4>
                    <div class="percent">
                        <div style="width:95%;"></div>
                    </div>
                </div>
                <div class="box">
                    <h4>CSS</h4>
                    <div class="percent">
                        <div style="width: 70%;"></div>
                    </div>
                </div>
                <div class="box">
                    <h4>JAVASCRIPT</h4>
                    <div class="percent">
                        <div style="width: 95%;"></div>
                    </div>
                </div>
                <div class="box">
                    <h4>PYTHON</h4>
                    <div class="percent">
                        <div style="width:75%;"></div>
                    </div>
                </div>
            </div>
            <div class="About interest">
                <h2 class="title2">Interest</h2>
                <ul>
                    <li><i class="fa fa-bar-chart" aria-hidden="true"></i>Trading</li>
                    <li><i class="fa fa-laptop" aria-hidden="true"></i>Developing</li>
                    <li><i class="fa fa-gamepad" aria-hidden="true"></i>Gaming</li>
                    <li><i class="fa fa-briefcase" aria-hidden="true"></i>Business</li>
                </ul>
            </div>
        </div>
          
    </div>
</div>