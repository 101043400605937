import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ChartistModule } from 'ng-chartist';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { SharedModule } from '../../../shared/shared.module';
import { UserPanelRoutingModule } from './user-panel-routing.module';

import { AddressComponent } from './create-property/address/address.component';
import { BasicInformationComponent } from './create-property/basic-information/basic-information.component';
import { CreatePropertyComponent } from './create-property/create-property.component';
import { PropertyGalleryComponent } from './create-property/property-gallery/property-gallery.component';
import { MyListingComponent } from './my-listing/my-listing.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { UserDetailsComponent } from './my-profile/user-details/user-details.component';
import { AvailablePropertyComponent } from './user-dashboard/available-property/available-property.component';
import { PropertyOverviewComponent } from './user-dashboard/property-overview/property-overview.component';
import { SalesByAgentsComponent } from './user-dashboard/sales-by-agents/sales-by-agents.component';
import { SalesOverviewComponent } from './user-dashboard/sales-overview/sales-overview.component';
import { TopCommonChartsComponent } from './user-dashboard/top-common-charts/top-common-charts.component';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { UserInfoComponent } from './widgets/user-info/user-info.component';
import { UserPanelSideMenuComponent } from './widgets/user-panel-side-menu/user-panel-side-menu.component';
import { FavouritesComponent } from './favourites/favourites.component';
import { CardsPaymentComponent } from './cards-payment/cards-payment.component';
import { CardsDetailsComponent } from './cards-payment/cards-details/cards-details.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ComparePropertyComponent } from './compare-property/compare-property.component';
import { ComparePropertyDataComponent } from './compare-property/compare-property-data/compare-property-data.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EducationComponent } from './create-property/education/education.component';
import { ExperienceComponent } from './create-property/experience/experience.component';
import { SubjectComponent } from './create-property/subject/subject.component';
import { SkillsComponent } from './create-property/skills/skills.component';
import { CertificateComponent } from './create-property/certificate/certificate.component';
import { SocialLinksComponent } from './create-property/social-links/social-links.component';
import { GridTabComponent } from '../portfolio/widgets/grid-tab/grid-tab.component';
import { PortfolioModule } from '../portfolio/portfolio.module';
import { ServiceCardsComponent } from './widgets/service-cards/service-cards.component';
import { AddInstituteComponent } from './manage-institute/add-institute/add-institute.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AddJobComponent } from './manage-jobs/add-job/add-job.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AddAdmissionComponent } from './manage-admission/add-admission/add-admission.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AddScholarshipComponent } from './manage-scholarship/add-scholarship/add-scholarship.component';
import { AddBooksComponent } from './manage-library/add-books/add-books.component';
import { AwardsComponent } from './create-property/awards/awards.component';
import { LanguagesComponent } from './create-property/languages/languages.component';
import { ManageCvComponent } from './manage-cv\'s/manage-cv/manage-cv.component';
import { CvEditComponent } from './manage-cv\'s/cv-edit/cv-edit.component';
import { Cv1Component } from './manage-cv\'s/cv-design/cv1/cv1.component';
import { Cv2Component } from './manage-cv\'s/cv-design/cv2/cv2.component';
import { Cv3Component } from './manage-cv\'s/cv-design/cv3/cv3.component';
import { Cv4Component } from './manage-cv\'s/cv-design/cv4/cv4.component';
import { Cv5Component } from './manage-cv\'s/cv-design/cv5/cv5.component';
import { AddHostelComponent } from './manage-hostel/add-hostel/add-hostel.component';
import { TeacherTestComponent } from './manage-teacherTest/teacher-test/teacher-test.component';
import { ResultSummaryComponent } from './manage-teacherTest/result-summary/result-summary.component';
import { QuestionTemplateComponent } from './manage-teacherTest/question-template/question-template.component';


@NgModule({
  declarations: [
    UserDashboardComponent,
    UserInfoComponent,
    UserPanelSideMenuComponent,
    MyListingComponent,
    TopCommonChartsComponent,
    SalesOverviewComponent,
    SalesByAgentsComponent,
    AvailablePropertyComponent,
    PropertyOverviewComponent,
    CreatePropertyComponent,
    BasicInformationComponent,
    AddressComponent,
    PropertyGalleryComponent,
    MyProfileComponent,
    UserDetailsComponent,
    FavouritesComponent,
    CardsPaymentComponent,
    CardsDetailsComponent,
    PrivacyComponent,
    ComparePropertyComponent,
    ComparePropertyDataComponent,
    EducationComponent,
    ExperienceComponent,
    SubjectComponent,
    SkillsComponent,
    CertificateComponent,
    SocialLinksComponent,
    ServiceCardsComponent,
    AddInstituteComponent,
    AddAdmissionComponent,
    AddJobComponent,
    AddScholarshipComponent,
    AddBooksComponent,
    AwardsComponent,
    LanguagesComponent,
    ManageCvComponent,
    CvEditComponent,
    Cv1Component,
    Cv2Component,
    Cv3Component,
    Cv4Component,
    Cv5Component,
    AddHostelComponent,
    TeacherTestComponent,
    ResultSummaryComponent,
    QuestionTemplateComponent,
    
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    UserPanelRoutingModule,
    SharedModule,
    ChartistModule,
    NgApexchartsModule,
    NgxDropzoneModule,
    PortfolioModule,
    NgbModule,
    CarouselModule,
    NgxSkeletonLoaderModule.forRoot(),
    NgxSpinnerModule.forRoot({ type: 'ball-spin-clockwise' }),
    // BrowserAnimationsModule
  ],
  exports: [
    UserDashboardComponent,
    TeacherTestComponent,
    QuestionTemplateComponent,
  ]
})
export class UserPanelModule { }
