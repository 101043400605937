<app-loader-two [isLoading]="isSpinnerShown"></app-loader-two>
<app-header-one></app-header-one>
<app-breadcrumb [bgImage]="bgImage" [title]="title" [parent]="parent" [child]="child"></app-breadcrumb>

<section class="user-dashboard small-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="sidebar-user sticky-cls">
                    <div class="user-profile">
                        <app-user-info></app-user-info>
                    </div>
                    <div class="dashboard-list">
                        <app-user-panel-side-menu [activeTab]="'teacher-test'"></app-user-panel-side-menu>
                    </div>
                </div>
            </div>
            <div class="col-lg-9">
                <div class="dashboard-content">
                    <!-- test-dashboard.component.html -->
                    <div class="test-header">
                        <h3>Upgrade Your Level</h3>
                        <p>Complete this test to upgrade your level. Each question must be attempted to see your result.
                        </p>
                    </div>

                    <div class="questions-container">
                        <div *ngFor="let question of questions" class="question-block">
                            <h3>{{ question.title }}</h3>
                            <p>Difficulty: {{ question.difficulty }}</p>
                            <p>{{ question.description }}</p>
                            <button class="btn btn-gradient color-2 btn-pill" [disabled]="testStatus[question.id]" (click)="startTest(question.id)">
                                {{ testStatus[question.id] ? 'Completed' : 'Start Test' }}
                            </button>
                        </div>
                    </div>

                    <!-- Result Summary -->
                    <app-result-summary *ngIf="allQuestionsAttempted()"></app-result-summary>


                </div>
            </div>
        </div>
    </div>
</section>
<app-adly-footer></app-adly-footer>