<app-loader-two [isLoading]="isSpinnerShown"></app-loader-two>
<app-header-one></app-header-one>
<app-breadcrumb [bgImage]="bgImage" [title]="title" [parent]="parent" [child]="child"></app-breadcrumb>

<section class="user-dashboard small-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="sidebar-user sticky-cls">
                    <div class="user-profile">
                        <app-user-info></app-user-info>
                    </div>
                    <div class="dashboard-list">
                        <app-user-panel-side-menu [activeTab]="'add-cv'"></app-user-panel-side-menu>
                    </div>
                </div>
            </div>
            <div class="col-lg-9">
                <div class="dashboard-content">
                    <div class="payment-user" id="payment">
                        <div class="common-card">
                            <div class="common-header">
                                <h5>CV Templates</h5>
                            </div>
                            <div class="row card-payment">
                                @if (isDataLoading) {
                                <div class="item">
                                    <ngx-skeleton-loader count="3" [theme]="{
                                                        height: '22.5rem',
                                                        width: '30%',
                                                        'border-radius': '10px',
                                                        'margin-right': '0.5rem',
                                                        'margin-left': '0.5rem'
                                                        }" />
                                </div>
                                }
                                @else if(!isDataLoading) {
                                <!-- @for(data of institutesData; track data){ -->
                                <!-- <div class="col-xl-4 col-sm-6"> -->
                                <div class="col-lg-12">
                                    <!-- //ultra new cards  -->
                                    <div class="navpill-style2">
                                        <div class="tab-content ha" id="pills-tabContent">
                                            <div class="tab-pane fade fz15 text show active" id="pills-home"
                                                role="tabpanel" aria-labelledby="pills-home-tab">
                                                <div class="row">
                                                    @for(template of templates; track template.templateId){
                                                    <div class="col-sm-4 col-xl-3 allcards position-relative">
                                                        <!-- @if(template.isPaid == true){
                                                        <span class="label badge label-shadow">Paid</span>
                                                        } -->
                                                        <!-- @if(template.isPaid == false){
                                                        <span class="translate-middle badge rounded-pill bg-warning badge">free</span>
                                                        } -->
                                                        <div class="listing-style1 bdrs16">
                                                            <div class="list-thumb">
                                                                <img class="w-100"
                                                                    [src]="sanitizeImageUrl(template.image_url)"
                                                                    alt="" />
                                                                <button class="btn btn-primary overlay-button"
                                                                    (click)="useTemplate(template.templateId)">
                                                                    Use Template
                                                                </button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                }
                                <!-- } -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-adly-footer></app-adly-footer>