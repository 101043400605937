import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, TemplateRef, inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, Meta, SafeResourceUrl, Title } from "@angular/platform-browser";
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';
import { PropertyService } from 'src/app/shared/services/property.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LibraryService } from '../../library.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { GetBookData } from '../../library';
import { Subject, takeUntil } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'books',
  templateUrl: './books.component.html',
  styleUrl: './books.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BooksComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();
  addSectionCss: any = {
    height: '100%',
    width: '100%',
  };
  isAdLoading: boolean = true;
  public tag = 'Library';
  public theme = {
    width: '27.5rem',
    height: '15rem',
    'border-radius': '10px'
  };
  bannerImage: string = 'assets/images/banner/library.webp';

  // Injecting Services
  private utilsService = inject(CommonUtilsServiceService);
  private apiService = inject(ApiCallService);
  private propertyService = inject(PropertyService);
  private cookies = inject(CookieService);
  private dataShare = inject(LibraryService);
  private errorHandlingService = inject(ErrorHandlingService);
  private modalService = inject(NgbModal);

  isContentShown: boolean = false;
  CategoryToGet: string = '';
  bookDataByCategory: GetBookData[] = [];
  payloadData: any;
  isDataLoading: boolean = true;
  noRecordsFound: boolean = false;
  isErrorOccurred: boolean = true;
  showPaginatation: boolean = true;
  isContentLoading: boolean = true;
  public isSpinnerShown = false;

  currentPage: number = 1;
  totalRecords: number = 0;
  itemsPerPage: number = 8;
  maxVisiblePages: number = 5;
  searchValue: string = '';

  adImages: any[] = [
    {
      redirectLink: 'https://youtube.com',
      imageUrl: '../../../../../assets/images/advertisements/institutes/1.jpeg'
    },
    {
      redirectLink: 'https://youtube.com',
      imageUrl: '../../../../../assets/images/advertisements/institutes/2.jpeg'
    },
    {
      redirectLink: 'https://youtube.com',
      imageUrl: '../../../../../assets/images/advertisements/institutes/3.jpeg'
    },
    {
      redirectLink: 'https://youtube.com',
      imageUrl: '../../../../../assets/images/advertisements/institutes/4.jpeg'
    },
  ];


  constructor(
    private sanitizer: DomSanitizer,
    private titleService: Title,
    private metaService: Meta,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private spinner: NgxSpinnerService

  ) {

    const route = this.router.url;
    if (route === "/books/books-by-category") {
      this.isContentShown = true;
      this.intializeBoolListComponent();
    } else if (route === "/") {
      this.isContentLoading = false;
      this.initializeComponent();
    }

  }

  ngOnInit() {
    this.checkScreenSize();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }



  isScreenSmall: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenSize();
  }

  checkScreenSize() {
    const width = window.innerWidth;
    this.isScreenSmall = width < 1200;
    if (width > 1200) {
      this.addSectionCss.height = '100%';
    } else {
      this.addSectionCss.height = '280px';
    }
  }

  setPageTitleAndMetaTags(): void {
    this.titleService.setTitle('Library List | Admission-Lylo');
    this.metaService.addTags([
      { name: 'description', content: 'This is the Job Ads of my Angular app, providing an overview of our services.' },
      { name: 'keywords', content: 'Angular, SEO, Home Page' },
      { name: 'robots', content: 'index, follow' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'author', content: 'John Doe' },
      { property: 'og:title', content: 'Home - My Angular App' },
      { property: 'og:description', content: 'This is the home page of my Angular app, providing an overview of our services.' },
      { property: 'og:image', content: 'https://example.com/image.jpg' },
      { property: 'og:url', content: 'https://example.com/home' },
    ]);
  }


  // CODE FOR TOP BOKKS STARTS HERE 


  private initializeComponent(): void {
    // this.getAdvertisementData();
    this.getTopBooks();
    this.checkScreenSize();
    setInterval(() => {
      this.isAdLoading = false;
    }, 5000)
  }


  private async getTopBooks(): Promise<void> {
    this.isSpinnerShown = true;
    this.spinner.show();

    try {
      const response = await this.apiService.GetCallWithoutToken('HomePage/GetTopBooks')
        .pipe(takeUntil(this.destroy$))
        .toPromise();

      if (response.responseCode === 200) {
        this.bookDataByCategory = response.data;

        // Convert each book's imageBase64 to Blob URL
        this.bookDataByCategory.forEach((book: any) => {
          if (book.imageBase64 && book.imageBase64.includes('base64,')) {
            const base64Data = book.imageBase64.split('base64,')[1];
            book.imageBase64 = this.utilsService.convertBase64ToBlobUrl(base64Data, 'image/jpeg');
            // console.log("Top Book Blob URL:", book.imageBase64);
          } else {
            book.imageBase64 = 'path/to/default/image.png'; // Fallback URL if no valid base64 found
          }
        });

        this.isContentLoading = false;
        this.isDataLoading = false;
        this.isErrorOccurred = false;
        this.noRecordsFound = this.bookDataByCategory == null || this.bookDataByCategory?.length <= 0;
        this.showPaginatation = !this.noRecordsFound;
      } else {
        this.errorHandlingService.handleResponseError(response);
        this.resetDataState();
      }
    } catch (error) {
      this.isDataLoading = false;
      this.isErrorOccurred = true;
      this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
      this.resetDataState();
    } finally {
      this.isSpinnerShown = false;
      this.spinner.hide();
      this.cdr.detectChanges();
    }
  }


  private resetDataState(): void {
    this.isDataLoading = false;
    this.isErrorOccurred = true;
    this.noRecordsFound = false;
    this.showPaginatation = false;
    this.bookDataByCategory = [];
  }


  // ADVERTISMENT
  pauseScroll(): void {
    const adsSection = document.querySelector('.ad-images-wrapper') as HTMLElement;
    if (adsSection) {
      adsSection.style.animationPlayState = 'paused';
    }
  }

  startScroll(): void {
    const adsSection = document.querySelector('.ad-images-wrapper') as HTMLElement;
    if (adsSection) {
      adsSection.style.animationPlayState = 'running';
    }
  }

  private getAdvertisementData(): void {
    this.propertyService.getAds(null, "Ad/GetAdsAdmissionLelo?moduleName=book")
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: any) => {
          if (res.responseCode == 200) {
            this.adImages = res.data;
            this.adImages.forEach((ad: any) => {
              if (ad.imageUrl && ad.imageUrl.includes('base64,')) {
                const base64Data = ad.imageUrl.split('base64,')[1];
                ad.imageUrl = this.utilsService.convertBase64ToBlobUrl(base64Data, 'image/jpeg');
                console.log("Ad Book Blob URL:", ad.imageUrl);
              }
            })
            this.isAdLoading = false;
          } else {
            this.adImages = [];
            this.isAdLoading = false;
          }
          this.cdr.detectChanges();
        }
      );
  }



  // CODE FOR TOP BOOKS END HERE

  // CODE FOR ALL BOOKS START HERE
  private intializeBoolListComponent(): void {
    this.isDataLoading = true;
    this.dataShare.recievedata();
    this.CategoryToGet = this.cookies.get('bookCategory')
    this.getAllBooks();
  }


  private async getAllBooks(): Promise<void> {
    this.isSpinnerShown = false;
    this.spinner.show();

    const payload = {
      bookCategory: this.CategoryToGet,
      pageNumber: this.currentPage,
      pageSize: this.itemsPerPage,
      searchText: this.searchValue,
    };

    this.isDataLoading = true;

    try {
      const response = await this.apiService.PostCallWithToken(payload, 'Book/GetBooks')
        .pipe(takeUntil(this.destroy$))
        .toPromise();

      if (response.responseCode === 200) {
        this.bookDataByCategory = response.data;

        // Convert each book's imageBase64 to Blob URL
        this.bookDataByCategory.forEach((book: any) => {
          if (book.imageBase64 && book.imageBase64.includes('base64,')) {
            const base64Data = book.imageBase64.split('base64,')[1];
            book.imageBase64 = this.utilsService.convertBase64ToBlobUrl(base64Data, 'image/jpeg');
            // console.log("All Book Blob URL:", book.imageBase64);
          } else {
            book.imageBase64 = 'path/to/default/image.png'; // Fallback URL if no valid base64 found
          }
        });

        this.isContentLoading = false;
        this.isDataLoading = false;
        this.isErrorOccurred = false;
        this.totalRecords = this.bookDataByCategory[0]?.totalRecords;
        this.noRecordsFound = this.bookDataByCategory == null || this.bookDataByCategory?.length <= 0;
        this.showPaginatation = !this.noRecordsFound;
      } else {
        this.errorHandlingService.handleResponseError(response);
        this.resetDataState();
      }
    } catch (error) {
      this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
      this.resetDataState();
    } finally {
      this.isSpinnerShown = false;
      this.spinner.hide();
      this.cdr.detectChanges();
    }
  }



  sanitizeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  onPageChanged(page: number): void {
    this.currentPage = page;
    this.getAllBooks();
    this.scrollToBookList();
  }

  getDisplayRange(): string {
    const start = (this.currentPage - 1) * this.itemsPerPage + 1;
    const end = Math.min(start + this.itemsPerPage - 1, this.totalRecords);
    return `${start} – ${end}`;
  }

  scrollToBookList(): void {
    const bookList = document.querySelector('.container-fluid');
    if (bookList) {
      const topOffset = bookList.getBoundingClientRect().top + window.pageYOffset - 95;
      window.scrollTo({ top: topOffset, behavior: 'smooth' });
    }
    // if (bookList) {
    //   bookList.scrollIntoView({ behavior: 'smooth' });
    // } else {
    //   window.scrollTo({ top: 0, behavior: 'smooth' });
    // }
  }

  onSearchValueReceived(value: string) {

    this.searchValue = value;
    // console.log('Search value received:', this.searchValue);
    // this.resetPagination();
  }

  handleSearchData(event: any) {
    if (event === "") {
      this.searchValue = "";
      this.getAllBooks();
      // this.scrollToContentStart();
    }
  }
  resetPagination(): void {
    this.currentPage = 1;
    this.getAllBooks();
    this.scrollToBookList();
  }
  // Ts For Pagination End

  onReset(): void {
    this.searchValue = '';
    this.resetPagination();
  }


  openVerticallyCentered(content: TemplateRef<any>): void {
    this.modalService.open(content, { centered: true });
  }

  handleChildEvent(data: any): void {
    this.currentPage = 1;
    this.payloadData = {
      bookCategory: this.CategoryToGet,
      pageNumber: this.currentPage,
      pageSize: this.itemsPerPage,
      searchText: this.searchValue,
    };
    if (this.payloadData.searchText != "") {
      this.getAllBooks();
    }
  }

  navigateToAllInstitutes(): void {
    this.utilsService.navigateTo('/books/categories');
  }


  navigateToBookDetails(id: number): void {
    this.utilsService.navigateTo('/books/book-details', { bookid: id });
  }

}
