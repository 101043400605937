import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, inject, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';

@Component({
  selector: 'app-cv-language-details',
  templateUrl: './cv-language-details.component.html',
  styleUrl: './cv-language-details.component.scss'
})
export class CvLanguageDetailsComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  public FormName: string = "Add";
  isLoading: boolean = false;

  languageDetails: any = [
    // {
    //   title: "Frontend Developer",
    //   institute: "ATG SYSTEM",
    //   languageLevel: "2022",
    // },
    // {
    //   title: "Backend Developer",
    //   institute: "ATG SYSTEM",
    //   languageLevel: "2022",
    // }
  ];
  delId: number;
  editId: number = 0;

  private readonly _apiCall = inject(ApiCallService);
  private readonly _toaster = inject(ToastrService);
  private readonly _spinner = inject(NgxSpinnerService);
  private readonly errorHandlingService = inject(ErrorHandlingService);
  constructor(public modal: NgbModal, private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.getlanguagesDetails();
    this.getLanguageList();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  addCard(content: TemplateRef<any>) {
    this.language = "";
    this.languageLevel = "";
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Add";
  }
  editCard(content: TemplateRef<any>, tag: any) {
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Edit";
    this.editId = tag?.id;
    this.language = tag?.language;
    this.languageLevel = tag?.languageLevel;


  }
  deleteCard(content: TemplateRef<any>, id: number) {
    this.modal.open(content, { centered: true, size: 'sm' });
    this.delId = id;
  }


  checklanguageExsists(): boolean {
    const payload = this.createApiPayload();
    if (this.languageDetails?.length > 0) {
      if (this.FormName == "Add") {
        const isExsist = this.languageDetails.some((tag: any) => tag.language == payload.language);
        if (isExsist) {
          this._toaster.error("This language Already Exist");
          return true;
        }
      } else {
        let newlanguages = this.languageDetails;
        newlanguages = newlanguages.filter((item: any) => item.id != this.editId);
        const isExsist = newlanguages.some((tag: any) => tag.language == payload.language && tag.id != this.editId);
        if (isExsist) {
          this._toaster.error("This language Already Exist");
          return true;
        }
      }

      return false;
    }
    return false;
  }


  public async savelanguageDetails(): Promise<void> {
    this.ShowError();
    const isAlreadyExsists = this.checklanguageExsists();
    if (!isAlreadyExsists) {
      const payload = this.createApiPayload();
      if (this.languageLevel === "" || this.language === '') {
        this._toaster.error("Invalid Form");
      }
      else {
        this._spinner.show();
        try {
          const response = await this._apiCall.PostCallWithToken(payload, 'AdmissionLeloUsers/SaveTeacherLanguages').pipe(takeUntil(this.destroy$)).toPromise();
          if (response.responseCode == 200) {
            this._toaster.success(response.responseMessage);
            this._spinner.hide();
            this.modal.dismissAll();
            this.getlanguagesDetails();
            this.editId = 0;
            this.languageLevel = "";
            this.language = "";

          } else {
            this.errorHandlingService.handleResponseError(response);
          }
        } catch (error) {
          this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
        } finally {
          this._spinner.hide();
          this.cdr.detectChanges();
        }

      }
    }
  }

  createApiPayload() {
    this.editId = this.FormName == "Add" ? 0 : this.editId
    return {
      id: this.editId,
      languageLevel: this.languageLevel,
      language: this.language
    };
  }


  public async deletelanguage(): Promise<void> {
    this._spinner.show();
    try {
      const res = await this._apiCall.PostCallWithToken(null, `AdmissionLeloUsers/DeleteTeacherLanguage?languageId=${this.delId}`).pipe(takeUntil(this.destroy$))
        .toPromise();
      if (res.responseCode == 200) {
        this._toaster.success(res.responseMessage);
        this.getlanguagesDetails();
        this._spinner.hide();
        this.modal.dismissAll();
      } else {
        this.errorHandlingService.handleResponseError(res);
      }
    }catch (error) {
      this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
    } finally {
      this._spinner.hide();
      this.cdr.detectChanges();
    }
  }


  private async getlanguagesDetails():Promise<void> {
    this.isLoading = true;
    try{
    const response = await this._apiCall.GetCallWithToken("AdmissionLeloUsers/GetTeacherLanguages").pipe(takeUntil(this.destroy$))
    .toPromise();
      if (response.responseCode == 200) {
        this.languageDetails = response.data;
      } else {
        this.languageDetails = [];
        this.errorHandlingService.handleResponseError(response);
      }
    }catch (error) {
      this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
    } finally {
      this._spinner.hide();
      this.isLoading = false;
      this.cdr.detectChanges();
    }
  }


  /////////////////////////////////////////////////
  /////////////errors of dropdowns //////////////////
  ///////////////////////////////////////////////////
  languageLevelError: boolean = false;
  languageError: boolean = false;


  ShowError() {
    this.languageLevelError = this.languageLevel == "" ? true : false;
    this.languageError = this.language == "" ? true : false;

  }


  ////////////////////////////////////////////////////
  /////////  languageLevel drop down data //////////
  ///////////////////////////////////////////////////// 


  languageLevel: string = '';
  languageLevelList = [
    { value: 'Fluent' },
    { value: 'Mid Level' },
    { value: 'Conversational' },
    { value: 'Other' }

  ]

  onlanguageLevelSelected(option: any) {
    this.languageLevel = option.value;
  }


  language: string = '';
  languageList = [
  ]

  onlanguageSelected(option: any) {
    this.language = option.value;
  }

  getLanguageList() {
    this._apiCall
      .GetCallWithoutToken("DropDown/GetLanguageDropDown")
      .subscribe((response) => {
        this.languageList = response?.data;
      });
  }

}
