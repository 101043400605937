import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { lookingForData } from '../../../../data/slider-filter-search';

@Component({
  selector: 'app-looking-for-icons',
  templateUrl: './looking-for-icons.component.html',
  styleUrls: ['./looking-for-icons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LookingForIconsComponent {

  @Input() text : boolean = false;

  @Input () public lookingForData = lookingForData;

}
