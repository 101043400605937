<header class="{{headerClass}}" [ngClass]="{ fixed : headerFixed && true }" style="z-index: 9;">
  <div class="container " [ngClass]="{ notFixed : headerFixed  }">
    <div class="row">
      <div class="col">
        <div class="menu">
          <div class="brand-logo d-flex">
            <a href="javascript:void(0)" [routerLink]="['']">
              @if(layout.config.settings.layout_version == 'dark-layout'){
              <img [src]="darkHeaderLogo" alt="logo" class="img-fluid for-dark" />
              }@else {
              <img [src]="headerLogo" alt="logo" class="img-fluid for-light" />
              }

              <!-- <img src="../../../../../assets/images/icons-logs-admissionLylo/logo.png" alt=""> -->
            </a>
          </div>
          <app-menu [icon]="false" class="nav-collapse" [menuItems]="menuItems" [userAccount]="userAccount"
          [userDetails]="LoggedUserDeatil"
          ></app-menu>

          <ul class="header-right">
            <li class="right-menu color-6">
              <ul class="nav-menu">
                @if(!userAccount){
                <li class="dropdown" (click)="openBackDropCustomClass(signInPage)">
                  <app-profile [userAccount]="userAccount"
                  [userDetails]="LoggedUserDeatil"></app-profile>
                </li>
                }@else{
                  <li class="dropdown" (click)="openLanguage()" (clickOutside)="clickOutside()" >
                    <div class="user-details" >
                      <div class="user-account">
                        {{LoggedUserDeatil?.firstName + " " + LoggedUserDeatil?.lastName}}
                      </div>
                        <div class="user-image">
                          <img class="user-after-login" [src]="LoggedUserDeatil?.profileBase64" alt="User-Profile">
                        </div>
                                
                    </div>
                    <ul class="nav-submenu" [ngClass]="{ open : languageOpen }">
                      <li (click)="logoutUser()">
                        <a href="javascript:void(0)">Log out <app-feather-icons class="log-out-icon" [icon]="'log-out'"></app-feather-icons>
                        </a>
                      </li>
                    </ul>
                  </li>

                }
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</header>


<ng-template #signInPage>
  <div class="modal-content">
    <!-- <app-sign-in (userLoggedIn)="onUserLoggedIn()"></app-sign-in> -->
    <app-log-in-user (userLoggedIn)="onUserLoggedIn()"></app-log-in-user>
  </div>
</ng-template>