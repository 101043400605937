import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, inject, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';

@Component({
  selector: 'app-cv-interest-details',
  templateUrl: './cv-interest-details.component.html',
  styleUrl: './cv-interest-details.component.scss'
})
export class CvInterestDetailsComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  public FormName: string = "Add";
  isLoading: boolean = false;

  interestDetails: any[] = [];
  delId: number;
  editId: number = 0;

  private readonly _apiCall = inject(ApiCallService);
  private readonly _toaster = inject(ToastrService);
  private readonly _spinner = inject(NgxSpinnerService);
  private readonly errorHandlingService = inject(ErrorHandlingService);
  constructor(public modal: NgbModal, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.getInterestDetails();
    this.getInterestList();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  addCard(content: TemplateRef<any>) {
    this.interest = "";
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Add";
  }

  editCard(content: TemplateRef<any>, tag: any) {
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Edit";
    this.editId = tag?.id;
    this.interest = tag?.interestName;
  }

  deleteCard(content: TemplateRef<any>, id: number) {
    this.modal.open(content, { centered: true, size: 'sm' });
    this.delId = id;
  }

  checkInterestExists(): boolean {
    const payload = this.createApiPayload();
    if (this.interestDetails?.length > 0) {
      if (this.FormName === "Add") {
        const isExist = this.interestDetails.some((tag: any) => tag.interestName === payload.interestName);
        if (isExist) {
          this._toaster.error("This interest already exists.");
          return true;
        }
      } else {
        let updatedInterests = this.interestDetails.filter((item: any) => item.id !== this.editId);
        const isExist = updatedInterests.some((tag: any) => tag.interestName === payload.interestName);
        if (isExist) {
          this._toaster.error("This interest already exists.");
          return true;
        }
      }
      return false;
    }
    return false;
  }

  public async saveInterestDetails(): Promise<void> {
    this.showError();
    const isAlreadyExists = this.checkInterestExists();
    if (!isAlreadyExists) {
      const payload = this.createApiPayload();
      if (this.interest === '') {
        this._toaster.error("Invalid form");
      } else {
        this._spinner.show();
        try {
          const response = await this._apiCall.PostCallWithToken(payload, 'User/SaveInterest').pipe(takeUntil(this.destroy$)).toPromise();
          if (response.responseCode === 200) {
            this._toaster.success(response.responseMessage);
            this.modal.dismissAll();
            this.getInterestDetails();
            this.editId = 0;
            this.interest = "";
          } else {
            this.errorHandlingService.handleResponseError(response);
          }
        } catch (error) {
          this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
        } finally {
          this._spinner.hide();
          this.cdr.detectChanges();
        }
      }
    }
  }

  createApiPayload() {
    return {
      id: this.FormName === "Add" ? 0 : this.editId,
      interestName: this.interest
    };
  }

  public async deleteInterest(): Promise<void> {
    this._spinner.show();
    try {
      const response = await this._apiCall.PostCallWithToken(null, `User/DeleteInterest?interestId=${this.delId}`).pipe(takeUntil(this.destroy$)).toPromise();
      if (response.responseCode === 200) {
        this._toaster.success(response.responseMessage);
        this.getInterestDetails();
        this.modal.dismissAll();
      } else {
        this.errorHandlingService.handleResponseError(response);
      }
    } catch (error) {
      this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
    } finally {
      this._spinner.hide();
      this.cdr.detectChanges();
    }
  }

  private async getInterestDetails(): Promise<void> {
    this.isLoading = true;
    try {
      const response = await this._apiCall.GetCallWithToken("User/GetInterests").pipe(takeUntil(this.destroy$)).toPromise();
      if (response.responseCode === 200) {
        this.interestDetails = response.data;
      } else {
        this.interestDetails = [];
        this.errorHandlingService.handleResponseError(response);
      }
    } catch (error) {
      this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
    } finally {
      this._spinner.hide();
      this.isLoading = false;
      this.cdr.detectChanges();
    }
  }

  interest: string = '';
  interestList: any[] = [];

  showError() {
    this.interestError = this.interest === '';
  }

  interestError: boolean = false;

  onInterestSelected(option: any) {
    this.interest = option.value;
  }

  getInterestList() {
    this._apiCall
      .GetCallWithoutToken("DropDown/GetInterestList")
      .subscribe((response) => {
        this.interestList = response?.data;
      });
  }
}

