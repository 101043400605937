<app-loader-two [isLoading]="isSpinnerShown"></app-loader-two>
<app-header-one></app-header-one>
<app-breadcrumb [bgImage]="bgImage" [title]="title" [parent]="parent" [child]="child"></app-breadcrumb>

<section class="user-dashboard full-screen-section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8 col-lg-6">
        <div class="question-content" *ngIf="question">
          <div class="question-header">
            <!-- <h2>{{ question.title }}</h2>
            <p class="description">{{ question.description }}</p>
            <p><strong>Difficulty:</strong> {{ question.difficulty }}</p> -->
            <p><strong>Question:</strong> {{ question.questionText }}</p>
          </div>

          <div class="options">
            <div *ngFor="let option of question.options" class="option">
              <label>
                <input
                  type="radio"
                  name="answer"
                  [value]="option"
                  (change)="selectAnswer(option)"
                  [checked]="selectedAnswer === option"
                />
                {{ option }}
              </label>
            </div>
          </div>

          <div class="timer">
            <p>Time Remaining: {{ timer }} seconds</p>
          </div>

          <div class="actions">
            <button (click)="submitAnswer()">Submit Answer</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Loader or message if question is still loading -->
<div *ngIf="!question" class="loading-message">
  <p>Loading question...</p>
</div>

<app-adly-footer></app-adly-footer>
