import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonUtilsServiceService } from '../../services/common-utils-service.service';
import { ApiCallService } from '../../services/api-call.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-adly-footer',
  templateUrl: './adly-footer.component.html',
  styleUrl: './adly-footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdlyFooterComponent {
  subscriber_From: FormGroup;

  private _utils = inject(CommonUtilsServiceService);
  private readonly _apiService = inject(ApiCallService);
  private readonly _toaster = inject(ToastrService);
  constructor(private fb: FormBuilder) {
    this.subscriber_From = this.fb.group({
      Email: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
    })
  }
  navigateTospecificComponent(route: string) {
    this._utils.navigateTo(route);
  }

  Subscribe() {
    if (this.subscriber_From.invalid) {
      this._toaster.error("Email Required");
    }
    else {
      const payload = { userEmail: this.subscriber_From.get("Email")?.value }
      this._apiService.PostCallWithoutToken(payload, "AdmissionLeloVisitor/AddSubscribers").subscribe(res => {
        if (res.responseCode == 200) {
          this._toaster.success(res.responseMessage);
          this.subscriber_From.reset();
        }
        else {
          this._toaster.error(res.responseMessage);
        }
      })
    }
  }


}
