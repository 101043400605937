import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-teacher-test',
  templateUrl: './teacher-test.component.html',
  styleUrl: './teacher-test.component.scss'
})
export class TeacherTestComponent {
  public bgImage = 'assets/images/banner/banner.webp';
  public title = 'Test Yourself';
  public parent = 'Teacher';
  public child = 'Submit Test';

  public isSpinnerShown = false;

  questions: any[] = [];
  testStatus: { [key: string]: boolean } = {}; // Tracks completed questions

  constructor( private router: Router) {}

  ngOnInit(): void {
    // Dummy questions for testing
    this.questions = [
      {
        id: '1',
        title: 'Math Test',
        description: 'Basic Math questions to test your skills',
        difficulty: 'Easy',
        questionText: 'What is 2 + 2?',
        options: ['3', '4', '5', '6'],
        correctAnswer: '4'
      },
      {
        id: '2',
        title: 'Science Test',
        description: 'Basic Science questions to test your knowledge',
        difficulty: 'Medium',
        questionText: 'What is the chemical symbol for water?',
        options: ['H2O', 'CO2', 'O2', 'N2'],
        correctAnswer: 'H2O'
      },
      {
        id: '3',
        title: 'History Test',
        description: 'Questions based on world history',
        difficulty: 'Hard',
        questionText: 'Who was the first president of the United States?',
        options: ['Abraham Lincoln', 'George Washington', 'Thomas Jefferson', 'John Adams'],
        correctAnswer: 'George Washington'
      },
      {
        id: '4',
        title: 'Geography Test',
        description: 'Questions related to world geography',
        difficulty: 'Easy',
        questionText: 'What is the capital of France?',
        options: ['Berlin', 'Madrid', 'Paris', 'Rome'],
        correctAnswer: 'Paris'
      },
      {
        id: '5',
        title: 'Literature Test',
        description: 'Test your knowledge on famous books and authors',
        difficulty: 'Medium',
        questionText: 'Who wrote "Romeo and Juliet"?',
        options: ['William Shakespeare', 'Charles Dickens', 'Jane Austen', 'Homer'],
        correctAnswer: 'William Shakespeare'
      }
    ];

    // Initialize testStatus with false for each question ID
    this.questions.forEach(question => {
      this.testStatus[question.id] = false;
    });
  }

  markQuestionAsCompleted(questionId: string) {
    this.testStatus[questionId] = true;
  }

  allQuestionsAttempted(): boolean {
    // Check if all questions have been attempted (marked as completed)
    return this.questions.every(question => this.testStatus[question.id]);
  }

  // startTest(questionId: string): void {
  //   // Opens the question in a new tab using Angular's router to navigate to the question component
  //   this.router.navigate(['/question', questionId]).then(() => {
  //     window.open(`/question/${questionId}`, '_blank');
  //   });
  // }

  startTest(questionId: string): void {
    // Open the question page in the current tab using Angular's router
    // this.router.navigate(['/question', questionId]);
  
    // Open the same question page in a new tab with the correct full URL
    window.open(`/user/question/${questionId}`, '_blank');
  }
  
}
