import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { propertyOverviewData } from '../../../../../shared/data/user-panel';

@Component({
  selector: 'app-property-overview',
  templateUrl: './property-overview.component.html',
  styleUrls: ['./property-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertyOverviewComponent {
  @Input() totalRecords: any;

  public propertyOverviewData = propertyOverviewData;

 // Original data
 data = [
  {
    "totalInstitutes": 12,
    "verifiedInstitutes": 8,
    "declinedInstitutes": 2,
    "pendingInstitutes": 2,
    "totalJobs": 10,
    "verifiedJobs": 4,
    "declinedJobs": 3,
    "pendingJobs": 3,
    "totalBooks": 15,
    "verifiedBooks": 8,
    "declinedBooks": 3,
    "pendingBooks": 4,
    "totalScholarships": 11,
    "verifiedScholarships": 9,
    "declinedScholarships": 2,
    "pendingScholarships": 0,
    "totalAdmissions": 14,
    "verifiedAdmissions": 12,
    "declinedAdmissions": 0,
    "pendingAdmissions": 2
  }
];

// Transform data into an array
 transformedData = [
  {
    category: 'Institutes',
    img:"assets/images/icon/Institute-1.svg",
    total: this.data[0].totalInstitutes,
    verified: this.data[0].verifiedInstitutes,
    declined: this.data[0].declinedInstitutes,
    pending: this.data[0].pendingInstitutes
  },
  {
    category: 'Admissions',
    img:"assets/images/icon/Admission-1.svg",
    total: this.data[0].totalAdmissions,
    verified: this.data[0].verifiedAdmissions,
    declined: this.data[0].declinedAdmissions,
    pending: this.data[0].pendingAdmissions
  },
  {
    category: 'Jobs',
    img:"assets/images/icon/Job-1.svg",
    total: this.data[0].totalJobs,
    verified: this.data[0].verifiedJobs,
    declined: this.data[0].declinedJobs,
    pending: this.data[0].pendingJobs
  },
  {
    category: 'Scholarships',
    img:"assets/images/icon/newlogos/Sc.svg",
    total: this.data[0].totalScholarships,
    verified: this.data[0].verifiedScholarships,
    declined: this.data[0].declinedScholarships,
    pending: this.data[0].pendingScholarships
  },
  {
    img:"assets/images/icon/library-2.svg",
    category: 'Books',
    total: this.data[0].totalBooks,
    verified: this.data[0].verifiedBooks,
    declined: this.data[0].declinedBooks,
    pending: this.data[0].pendingBooks
  },
  
];

  
}
