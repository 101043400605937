<app-loader-two [isLoading]="isSpinnerShown"></app-loader-two>
<app-header-one></app-header-one>
<app-common-banner-1 [imagePath]="bannerImage" [heading1]="'CV Templates'"
    [heading2]="'for Innovation, Education, and Success'"></app-common-banner-1>
<!-- <app-title [isLineAdded]="true" [isLabelAdded]="true" [heading]="'CV Templates'" [tag]="tag" [textWhite]="false"
    [type]="'simple'"></app-title> -->
<section class="agent-section property-section">
    <div class="container">
        <div class="row">
            <div class="col">
                <app-title [titleClass]="'title-2 text-center'" [heading]="'Select Your CV Template'"
                    [desc]="'Find the perfect template to showcase your skills and experience professionally and effectively.'"
                    [type]="'basic'">
                </app-title>
            </div>
            <div class="col-lg-12">
                <div class="navpill-style2">
                    <div class="tab-content ha" id="pills-tabContent">
                        <div class="tab-pane fade fz15 text show active" id="pills-home" role="tabpanel"
                            aria-labelledby="pills-home-tab">
                            <div class="row">
                                @for(template of templates; track template.templateId){
                                <div class="col-sm-4 col-xl-3 allcards position-relative">
                                    @if(template.isPaid == true){
                                    <span class="label badge label-shadow">Paid</span>
                                    }
                                    <div class="listing-style1 bdrs16">
                                        <div class="list-thumb">
                                            <img class="w-100" [src]="sanitizeImageUrl(template.image_url)" alt="" />
                                            <button class="btn btn-primary overlay-button" (click)="useTemplate()">Use
                                                Template</button>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>

<app-adly-footer></app-adly-footer>