<app-loader-two [isLoading]="isSpinnerShown"></app-loader-two>
<app-header-one></app-header-one>
<app-common-banner-1 [imagePath]="bannerImage" [heading1]="'CV '"
    [heading2]="'for Innovation, Education, and Success'"></app-common-banner-1>

<section class="user-dashboard small-section">
    <div class="container">
        <div class="row">
            <!-- <div class="col-lg-3">
                <div class="sidebar-user sticky-cls">
                    <div class="user-profile">
                    </div>
                    <div class="dashboard-list">
                    </div>
                </div>
            </div> -->
            <div class="col-lg-12">
                <div class="dashboard-content">
                    <div class="create-tab" id="create-property">
                        <div class="property-wizard common-card">
                            <div class="common-header">
                                <h5>Create CV</h5>
                            </div>
                            <div class="create-property-form">
                                <div class="form-inputs">
                                    <!-- <app-basic-information></app-basic-information> -->
                                    <app-cv-profile-details></app-cv-profile-details>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- languages  -->
                    <div class="create-tab" id="create-property">
                        <div class="property-wizard common-card">
                            <div class="create-property-form">
                                <div class="form-inputs">
                                    <!-- <app-languages></app-languages> -->
                                    <app-cv-language-details></app-cv-language-details>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- subjects  -->
                    <div class="create-tab" id="create-property">
                        <div class="property-wizard common-card">
                            <div class="create-property-form">
                                <div class="form-inputs">
                                    <!-- <app-subject></app-subj -->
                                    <app-cv-education-details></app-cv-education-details>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- skills  -->
                    <div class="create-tab" id="create-property">
                        <div class="property-wizard common-card">
                            <div class="create-property-form">
                                <div class="form-inputs">
                                    <!-- <app-skills></app-skills> -->
                                    <app-cv-experience-details></app-cv-experience-details>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- education  -->
                    <div class="create-tab" id="create-property">
                        <div class="property-wizard common-card">
                            <div class="create-property-form">
                                <div class="form-inputs">
                                    <!-- <app-education></app-education> -->
                                    <app-cv-skill-details></app-cv-skill-details>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- experience  -->
                    <div class="create-tab" id="create-property">
                        <div class="property-wizard common-card">
                            <div class="create-property-form">
                                <div class="form-inputs">
                                    <!-- <app-experience></app-experience> -->
                                    <app-cv-achievement-details></app-cv-achievement-details>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- awards  -->
                    <div class="create-tab" id="create-property">
                        <div class="property-wizard common-card">
                            <div class="create-property-form">
                                <div class="form-inputs">
                                    <!-- <app-awards></app-awards> -->
                                    <app-cv-certificate-details></app-cv-certificate-details>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- certificates  -->
                    <div class="create-tab" id="create-property">
                        <div class="property-wizard common-card">
                            <div class="create-property-form">
                                <div class="form-inputs">
                                    <!-- <app-certificate></app-certificate> -->
                                    <app-cv-interest-details></app-cv-interest-details>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- socail links  -->
                    <!-- <div class="create-tab" id="create-property">
                        <div class="property-wizard common-card">
                            <div class="create-property-form">
                                <div class="form-inputs">
                                    <h6>Social Links</h6>
                                    <app-social-links></app-social-links>
                                </div>
                            </div>
                        </div>
                    </div> -->


                </div>
            </div>
        </div>
    </div>
</section>

<app-adly-footer></app-adly-footer>