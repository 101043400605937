import { Component, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CommonUtilsServiceService } from 'src/app/shared/services/common-utils-service.service';

@Component({
  selector: 'app-edit-user-password-modal',
  templateUrl: './edit-user-password-modal.component.html',
  styleUrls: ['./edit-user-password-modal.component.scss'],
})
export class EditUserPasswordModalComponent {

  //varaibles
  oldPassword: string = "password";
  signUpPassword: string = "password";
  signUpConfirmPassword: string = "password";
  chnage_Password!: FormGroup;

  //services
  private _apiService = inject(ApiCallService);
  private _toaster = inject(ToastrService);
  private _utils = inject(CommonUtilsServiceService);
  private _authService = inject(AuthService);
  private _spinner = inject(NgxSpinnerService)

  constructor(public modal: NgbModal, private fb: FormBuilder) {

    this.chnage_Password = this.fb.group({
      old_password: ['', Validators.required],
      new_password: ['', [Validators.required, this.passwordValidator.bind(this)]],
      confirm_password: ['', Validators.required],
    });

  }


  ChangePassword() {
    if (this.chnage_Password.invalid || !this.passwordsMatching) {
      this._toaster.error("Please enter valid password")
    } else {
      this._spinner.show();
      const payload = {
        oldPassword: this.chnage_Password.get("old_password")?.value,
        newPassword: this.chnage_Password.get("new_password")?.value
      }

      this._apiService.PostCallWithToken(payload, "AdmissionLeloUsers/ChangePassword").subscribe((res) => {
        if (res.responseCode === 200) {
          this._toaster.success(res.responseMessage);
          this._authService.logout();
          this._spinner.hide();
          this.chnage_Password.reset();
          this._utils.navigateTo("");
          this.modal.dismissAll();
          
        } else {
          this._toaster.error(res.responseMessage);
          this._spinner.hide();
        }
      })
    }


  }


  //show and hide passwords 
  showAndHidePassword(fieldName: string) {
    if (fieldName === "signUpPassword") {
      this.signUpPassword = this.signUpPassword === 'password' ? 'text' : 'password';
    }
    if (fieldName === "signUpConfirmPassword") {
      this.signUpConfirmPassword = this.signUpConfirmPassword === 'password' ? 'text' : 'password';
    }
    if (fieldName === "oldPassword") {
      this.oldPassword = this.oldPassword === 'password' ? 'text' : 'password';
    }
  }


  //validations to strong password
  passRequire: boolean = false;
  passMinlength: boolean = false;
  passUpperCase: boolean = false;
  passLowerCase: boolean = false;
  passNumber: boolean = false;
  passSpecialChar: boolean = false;
  passwordValidator(control: FormControl): { [key: string]: boolean } | null {
    this.passRequire = false;
    this.passMinlength = false;
    this.passUpperCase = false;
    this.passLowerCase = false;
    this.passNumber = false;
    this.passSpecialChar = false;
    const value: string = control.value;
    if (value?.length >= 8) {
      this.passMinlength = true;
    }

    if (/[A-Z]/.test(value)) {
      this.passUpperCase = true;
    }

    if (/[a-z]/.test(value)) {
      this.passLowerCase = true;
    }

    if (/\d/.test(value)) {
      this.passNumber = true;
    }

    if (/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
      this.passSpecialChar = true;
    }

    return null;
  }


  //password matching
  passwordsMatching = false;
  isConfirmPasswordDirty = false;
  checkPasswords(pw: string, cpw: string) {
    this.isConfirmPasswordDirty = true;
    if (pw == cpw) {
      this.passwordsMatching = true;
    } else {
      this.passwordsMatching = false;
    }
  }



  closeModal(){
    this.modal.dismissAll();
    this.chnage_Password.reset();
  }



}
