import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable, filter, map } from "rxjs";
import {
  aboutData,
  agencyData,
  agentsData,
  agentsDetails,
  bannerData,
  blogDetailsData,
  brandData,
  currency,
  faqData,
  featuredPropertyData,
  happyClientsData,
  homeSectionData,
  latestBlogData,
  latestForRent,
  latestForRentData,
  latestForSale,
  latestForSaleData,
  newOfferData,
  peopleSayData,
  pricingPlanData,
  privacyData,
  propertyCityData,
  propertyOfDayData,
  providedServicesData,
  sliderData,
  termsData,
} from "../interface/property";
import { ApiCallService } from "./api-call.service";

@Injectable({
  providedIn: "root",
})
export class PropertyService {
  private _apiCall = inject(ApiCallService);

  // Get Currency
  public currencyItem: any = localStorage.getItem("currency");
  public currency: currency = JSON.parse(this.currencyItem);

  public Currency = this.currency || {
    name: "Dollar",
    currency: "USD",
    symbol: "$",
    price: 1,
  };

  constructor(private http: HttpClient) { }

  // Home Slider Data
  homeSliderData(): Observable<homeSectionData> {
    return this.http.get<homeSectionData>(
      "assets/data/home-section-slider.json"
    );
  }

  // Latest For Sale
  propertyData(): Observable<latestForSaleData> {
    return this.http.get<latestForSaleData>("assets/data/latest-for-sale.json");
  }

  // Featured Property Data
  featuredPropertyData(): Observable<featuredPropertyData> {
    return this.http.get<featuredPropertyData>(
      "assets/data/featured-property.json"
    );
  }

  // Latest For Rent
  latestForRentData(): Observable<latestForRentData> {
    return this.http.get<latestForRentData>("assets/data/latest-for-rent.json");
  }

  // Data from GetTopInstittes
  getTopInstitutes(instituteType?: any): Observable<any> {
    // const payload = this.createTopInstitutePayload(instituteType);
    return this._apiCall
      .GetCallWithoutToken("HomePage/GetTopInstitutes?InstituteType=" + instituteType)
      .pipe(
        map((response: any) => {
          return response.data.map((institute: any) => ({
            id: institute.id,
            title: institute.instituteName,
            img: {
              url: institute.imageBase64,
              fileType: "image"
            },
            country: institute.country,
            city: institute.city,
            email: institute.email,
            contactNumber: institute.contactNumber,
            moduleType: "institute",
          }));
        })
      );
  }

  // Data from GetTopInstittes
  getTopTeacher(subjectType?: any): Observable<any> {
    // const payload = this.createTopInstitutePayload(instituteType);
    return this._apiCall
      .GetCallWithoutToken("HomePage/GetTopTeachers?Subject=" + subjectType)
      .pipe(
        map((response: any) => {
          //
          console.log("Teacher Data From API: ", response);
          return response.data.map((institute: any) => ({
            id: institute.id,
            title: institute.teacherName,
            img: institute.imageBase64,
            subTitle: institute.city,
            email: institute.email,
            contactNumber: institute.contactNumber,
            mobile: institute.contactNumber,
            fax: institute?.subject,
            moduleType: "teacher",
          }));
        })
      );
  }





  createTopInstitutePayload(instituteType?: string) {
    let payload: any = {
      pageSize: 0
    };

    if (instituteType) {
      payload.instituteType = instituteType; // Include instituteType if provided
    }

    return payload;
  }

  getRelatedInstitutes(id: number): Observable<any> {
    return this._apiCall
      .GetCallWithoutToken(`Institute/GetRelatedInstitutes?Id=${id}`)
      .pipe(
        map((response) => {
          return response.data.map((institute: any) => ({
            id: institute.id,
            title: institute.instituteName,
            details: institute.description,
            img: {
              url: institute.imageBase64,
              fileType: "image"
            },
            country: institute.country,
            city: institute.city,
            email: institute.email,
            contactNumber: institute.contactNumber,
            moduleType: "institute",
          }));
        })
      );
  }

  // Data from GetAllInstittes
  getInstitutes(
    filters: any = {},
    pageNumber: number = 1,
    pageSize: number = 9
  ): Observable<any> {

    const payload = this.createInstitutePayload(filters, pageNumber, pageSize);
    return this._apiCall
      .PostCallWithoutToken(payload, "Institute/GetInstitutes")
      .pipe(
        map((response) => {
          return {
            data: response.data.map((institute: any) => ({
              id: institute.id,
              title: institute.instituteName,
              details: institute.description,
              img: institute.images.map((image: any) => ({
                url: image.imageBase64,
                fileType: "image",
              })),
              country: institute.country,
              city: institute.city,
              totalRecords: institute.totalRecords,
              email: institute.email,
              contactNumber: institute.contactNumber,
              moduleType: "institute",
            })),
            totalRecords: response.totalRecords || response.data.length,
          };
        })
      );
  }

  createInstitutePayload(
    filters: any = {},
    pageNumber: number = 1,
    pageSize: number = 8
  ): any {
    return {
      countryName: filters.country || "",
      stateName: filters.state || "",
      cityName: filters.city || "",
      searchText: filters.search || "",
      pageNumber: pageNumber,
      pageSize: 8,
      getName: "",
    };
  }


  //api for user cards 

  // Institute/GetUserInstitutesCards


  getUserCards(): Observable<any> {
    // const payload = this.createTopInstitutePayload(instituteType);
    return this._apiCall
      .GetCallWithToken("Institute/GetUserInstitutesCards")
      .pipe(
        map((response) => {
          return response.data.map((institute: any) => ({
            id: institute.id,
            title: institute.instituteName,
            img: {
              url: institute.imageBase64,
              fileType: "image"
            },
            country: institute.country,
            city: institute.city,
            email: institute.email,
            contactNumber: institute.contactNumber,
            moduleType: "institute",
          }));
        })
      );
  }

  // api for user cards ends

  // data for latest admission
  getLatestAdmissions(): Observable<any> {
    // const payload = this.createLatestAdmissionPayload();
    return this._apiCall
      .GetCallWithoutToken("HomePage/GetTopAdmissions")
      .pipe(
        map((response) => {
          return response.data.map((admission: any) => ({
            id: admission.id,
            title: admission.instituteName,
            // details: admission.description,
            /*img: admission.admissionImages.map((image: any) => ({
              url: image.imageBase64,
              fileType: "image",
            })),*/
            img: {
              url: admission.imageBase64,
              fileType: "image"
            },
            country: admission.country,
            city: admission.city,
            email: admission.email,
            contactNumber: admission.contactNumber,
            details: admission.description,
            moduleType: "admission",
          }));
        })
      );
  }

  createLatestAdmissionPayload() {
    return {
      pageSize: 0
    };
  }
  
  // data for related admission
  getRelatedAdmissions(id: number): Observable<any> {
    return this._apiCall
      .GetCallWithoutToken(`Admission/GetRelatedAdmissions?id=${id}`)
      .pipe(
        map((response) => {
          return response.data.map((admission: any) => ({
            id: admission.id,
            title: admission.instituteName,
            // details: admission.description,
            /*img: admission.admissionImages.map((image: any) => ({
              url: image.imageBase64,
              fileType: "image",
            })),*/
            img: {
              url: admission.imageBase64,
              fileType: "image"
            },
            country: admission.country,
            city: admission.city,
            email: admission.email,
            contactNumber: admission.contactNumber,
            details: admission.description,
            moduleType: "admission",
          }));
        })
      );
  }

  // data for all admission
  getAdmissions(
    filters: any = {},
    pageNumber: number = 1,
    pageSize: number = 9
  ): Observable<any> {
    const payload = this.createAdmissionPayload(filters, pageNumber, pageSize);
    return this._apiCall
      .PostCallWithoutToken(payload, "Admission/GetAdmissions")
      .pipe(
        map((response) => {
          return {
            data: response.data.map((admission: any) => ({
              id: admission.id,
              title: admission.instituteName,
              details: admission.description,
              img: admission.admissionImages.map((image: any) => ({
                url: image.imageBase64,
                fileType: "image",
              })),
              country: admission.country,
              city: admission.city,
              totalRecords: admission.totalRecords,
              email: admission.email,
              contactNumber: admission.contactNumber,
              moduleType: "admission",
            })),

            totalRecords: response.totalRecords || response.data.length,
          };
        })
      );
  }

  createAdmissionPayload(
    filters: any = {},
    pageNumber: number = 1,
    pageSize: number = 8
  ): any {
    return {
      countryName: filters.country || "",
      stateName: filters.state || "",
      cityName: filters.city || "",
      degree: filters.degree || "",
      searchText: filters.search || "",
      pageNumber: pageNumber,
      pageSize: 8,
      getName: "",
    };
  }

  // Data for GetlatestJobs
  getLatestJobs(): Observable<any> {
    // const payload = this.createLatestJobsPayload();
    return this._apiCall
      .GetCallWithoutToken("HomePage/GetTopJobs")
      .pipe(
        map((response) => {
          return response.data.map((job: any) => ({
            id: job.id,
            title: job.companyName,
            img: {
              url: job.imageBase64,
              fileType: "image"
            },
            country: job.countryName,
            city: job.cityName,
            email: job.jobType,
            contactNumber: job.contactNumber,
            details: job.description,
            moduleType: "jobs",
          }));
        })
      );
  }
  createLatestJobsPayload() {
    return {
      getname: "Top",
    };
  }

  // Data for GetRelatedJobs
  getRelatedJobs(id: number): Observable<any> {
    return this._apiCall
      .GetCallWithoutToken(`Jobs/GetRelatedJobs?Id=${id}`)
      .pipe(
        map((response) => {
          return response.data.map((job: any) => ({
            id: job.id,
            title: job.companyName,
            img: {
              url: job.imageBase64,
              fileType: "image"
            },
            country: job.countryName,
            city: job.cityName,
            email: job.jobType,
            contactNumber: job.contactNumber,
            details: job.description,
            moduleType: "jobs",
          }));
        })
      );
  }


  // API Service Method to Get All Jobs with Filters
  getAllJobs(
    filters: any = {},
    pageNumber: number = 1,
    pageSize: number = 9
  ): Observable<any> {
    const payload = this.createJobsPayload(filters, pageNumber, pageSize);
    return this._apiCall
      .PostCallWithoutToken(payload, "Jobs/GetJobs")
      .pipe(
        map((response) => {
          return {
            data: response.data.map((job: any) => ({
              id: job.id,
              title: job.companyName,
              details: job.description,
              img: job.imageVMs.map((image: any) => ({
                url: image.imageBase64,
                fileType: "image",
              })),
              country: job.countryName,
              city: job.cityName,
              totalRecords: job.totalRecords,
              email: job.jobType,
              contactNumber: job.contactNumber,
              moduleType: "jobs",
            })),
            totalRecords: response.totalRecords || response.data.length,
          };
        })
      );
  }

  // Method to Create Payload Based on Filters
  createJobsPayload(
    filters: any = {},
    pageNumber: number = 1,
    pageSize: number = 8
  ): any {
    return {
      countryName: filters.country || "",
      stateName: filters.state || "",
      cityName: filters.city || "",
      jobType: filters.jobType || "",
      searchText: filters.search || "",
      pageNumber: pageNumber,
      pageSize: 8,
      getName: "",
      maxValue: filters.maxValue,
      minValue: filters.minValue
    };
  }

  // Get Latest Scholarships 
  getLatestScholarships(): Observable<any> {
    // const payload = this.createLatestScholarshipsPayload();
    return this._apiCall
      .GetCallWithoutToken("HomePage/GetTopScholarships")
      .pipe(
        map((response) => {
          return response.data.map((scholarship: any) => ({
            id: scholarship.id,
            title: scholarship.scholarshipName,
            details: scholarship.description,
            // img: scholarship.images.map((image: any) => ({
            //   url: image.imageBase64,
            //   fileType: "image",
            // })),
            img: {
              url: scholarship.imageBase64,
              fileType: "image"
            },
            country: scholarship.country,
            city: scholarship.city,
            email: scholarship.email,
            contactNumber: scholarship.contactNumber,
            moduleType: "scholarships",
          }));
        })
      );
  }
  createLatestScholarshipsPayload() {
    return {
      getname: "Top",
    };
  }

  // Get Related Scholarships 
  getRelatedScholarships(id: number): Observable<any> {
    return this._apiCall
      .GetCallWithoutToken(`Scholarship/GetRelatedScholarships?Id=${id}`)
      .pipe(
        map((response) => {
          return response.data.map((scholarship: any) => ({
            id: scholarship.id,
            title: scholarship.scholarshipName,
            details: scholarship.description,
            // img: scholarship.images.map((image: any) => ({
            //   url: image.imageBase64,
            //   fileType: "image",
            // })),
            img: {
              url: scholarship.imageBase64,
              fileType: "image"
            },
            country: scholarship.country,
            city: scholarship.city,
            email: scholarship.email,
            contactNumber: scholarship.contactNumber,
            moduleType: "scholarships",
          }));
        })
      );
  }



  // Get All Scholarships 
  getAllScholarships(
    filters: any = {},
    pageNumber: number = 1,
    pageSize: number = 9
  ): Observable<any> {
    const payload = this.createScholarshipsPayload(filters, pageNumber, pageSize);
    return this._apiCall
      .PostCallWithoutToken(payload, "Scholarship/GetScholarships")
      .pipe(
        map((response) => {
          return {
            data: response.data.map((scholarship: any) => ({
              id: scholarship.id,
              title: scholarship.scholarshipName,
              details: scholarship.description,
              img: scholarship.images.map((image: any) => ({
                url: image.imageBase64,
                fileType: "image",
              })),
              country: scholarship.country,
              city: scholarship.city,
              email: scholarship.email,
              contactNumber: scholarship.contactNumber,
              totalRecords: scholarship.totalRecords,
              moduleType: "scholarships",
            })),
            totalRecords: response.totalRecords || response.data.length,
          }
        })
      )
  }
  // Payload for all Scholarships 
  createScholarshipsPayload(
    filters: any = {},
    pageNumber: number = 1,
    pageSize: number = 8
  ): any {
    return {
      countryName: filters.country || "",
      stateName: filters.state || "",
      cityName: filters.city || "",
      scholarshipType: filters.type || "",
      searchText: filters.search || "",
      pageNumber: pageNumber,
      pageSize: 8,
      getName: "",
    };
  }

  // Data for GetlatestJobs
  getLatestBooks(): Observable<any> {
    const payload = this.createLatestBooksPayload();
    return this._apiCall
      .GetCallWithoutToken("HomePage/GetTopBooks")
      .pipe(
        map((response) => {
          return response.data.map((book: any) => ({

            id: book.id,
            title: book.title,
            fax: book.bookDescription,
            img: book.imageBase64,
            subTitle: book.authorName,
            moduleType: "books",
          }));
        })
      );
  }
  createLatestBooksPayload() {
    return {
      pageSize: 0
    };
  }


  // Data from GetAllBooks
  getBooks(
    filters: any = {},
    pageNumber: number = 1,
    pageSize: number = 9
  ): Observable<any> {

    const payload = this.createBooksPayload(filters, pageNumber, pageSize);
    return this._apiCall
      .PostCallWithoutToken(payload, "Book/GetBooks")
      .pipe(
        map((response) => {
          return {
            data: response.data.map((book: any) => ({
              id: book.id,
              title: book.title,
              fax: book.bookDescription,
              img: book.imageBase64,
              subTitle: "Authors Name",
              totalRecords: book.totalRecords,
              moduleType: "books",
            })),
            totalRecords: response.totalRecords || response.data.length,
          };
        })
      );
  }

  createBooksPayload(
    filters: any = {},
    pageNumber: number = 1,
    pageSize: number = 8
  ): any {
    return {
      countryName: filters.country || "",
      stateName: filters.state || "",
      cityName: filters.city || "",
      searchText: filters.search || "",
      pageNumber: pageNumber,
      pageSize: 8,
      getName: "",
    };
  }


  // for intitute Details component
  getInstituteDetails(instituteid: number): Observable<any> {
    return this._apiCall
      .PostCallWithoutToken(
        null,
        `Institute/GetInstituteById?InstituteId=${instituteid}`
      )
      .pipe(
        map((response) => {
          const institueDetail = response.data;
          return {
            id: institueDetail.id,
          };
          // return response.data.map((institute: any) => ({
          //   id: institute.id,
          //   title: institute.instituteName,
          //   details: institute.description,
          //   img: institute.images.map((image: any) => ({
          //     url: image.imageBase64,
          //     fileType: "image",
          //   })),
          //   country: institute.city,
          //   totalRecords: institute.totalRecords,
          // }));
        })
      );
  }




  // for all ads

  getAds(payload: any, route: string): Observable<any> {
    return this._apiCall
      .PostCallWithoutToken(payload, route).pipe(
        map((response) => {
          return response;
        })
      );

  }
  // About Data
  aboutData(): Observable<aboutData> {
    return this.http.get<aboutData>("assets/data/about.json");
  }

  // New Offer Data
  newOfferData(): Observable<newOfferData> {
    return this.http.get<newOfferData>("assets/data/new-offer.json");
  }

  // Property In City
  propertyInCityData(): Observable<propertyCityData> {
    return this.http.get<propertyCityData>("assets/data/property-in-city.json");
  }

  // Banner
  bannerData(): Observable<bannerData> {
    return this.http.get<bannerData>("assets/data/banner.json");
  }

  // Agents Data
  agentsData(): Observable<agentsData> {
    return this.http.get<agentsData>("assets/data/agents.json");
  }

  // Happy Clients Data
  happyClientsData(): Observable<happyClientsData> {
    return this.http.get<happyClientsData>("assets/data/happy-clients.json");
  }

  // Brand Data
  brandData(): Observable<brandData> {
    return this.http.get<brandData>("assets/data/brand.json");
  }

  // Provided Services
  providesServices(): Observable<providedServicesData> {
    return this.http.get<providedServicesData>(
      "assets/data/provided-services.json"
    );
  }

  // Pricing Plan
  pricingPlanData(): Observable<pricingPlanData> {
    return this.http.get<pricingPlanData>("assets/data/pricing-plan.json");
  }

  // Latest Blog
  latestBlogData(): Observable<latestBlogData> {
    return this.http.get<latestBlogData>("assets/data/latest-blog.json");
  }

  // Property Of Day
  propertyOfDayData(): Observable<propertyOfDayData> {
    return this.http.get<propertyOfDayData>("assets/data/property-of-day.json");
  }

  // People Say
  peopleSayData(): Observable<peopleSayData> {
    return this.http.get<peopleSayData>("assets/data/people-say.json");
  }

  // Property Details
  propertyDetailsData(): Observable<any> {
    return this.http.get<any>("assets/data/property-details.json");
  }

  // Blog DetailsData
  blogDetailsData(): Observable<blogDetailsData> {
    return this.http.get<blogDetailsData>("assets/data/blog-details.json");
  }

  // Agency Data
  agencyData(): Observable<agencyData> {
    return this.http.get<agencyData>("assets/data/agency.json");
  }

  // All Teachers Data
  getAllTeachers(
    filters: any = {},
    pageNumber: number,
    pageSize: number
  ): Observable<any> {
    const payload = this.createAllTeachersPayload(
      filters,
      pageNumber,
      pageSize
    );
    return this._apiCall
      .PostCallWithoutToken(payload, "AdmissionLeloUsers/GetAllTeacher")
      .pipe(
        map((response) => {
          return {
            data: response.data.map((teachers: any) => ({
              id: teachers.id,
              img: teachers.imageBase64,
              property: 5,
              title: teachers.teacherName,
              subTitle:
                // teachers.country + ", " + teachers.state + ", " + teachers.city,
                teachers.city,
              mobile: teachers.contactNumber,
              email: teachers.email,
              fax: teachers?.subjects,
              rating: teachers.rating,
              // totalRecords: teachers?.totalRecords, 
              totalRecords: 5, 

            })),
            totalRecords: response.totalRecords || response.data.length,

          }
        })
      );
  }

  getRelatedTeachers(id: number): Observable<any> {
    return this._apiCall
      .GetCallWithoutToken(`AdmissionLeloUsers/GetRelatedTeachers?Id=${id}`)
      .pipe(
        map((response) => {
          // console.log("Teachers Data", response);
          return response.data.map((teachers: any) => ({
            id: teachers.id,
            img: teachers.imageBase64,
            property: 5,
            title: teachers.teacherName,
            subTitle: teachers.city,
            email: teachers.email,
            mobile: teachers.contactNumber,
            fax: teachers?.subject,
            // rating: teachers.rating,
          }));
        })
      );
  }

  createAllTeachersPayload(
    filters: any = {},
    pageNumber: number,
    pageSize: number
  ): any {
    return {
      pageNumber: pageNumber,
      pageSize: pageSize,
      searchText: filters.search || "",
      getName: "",
      subject: filters.subject || "",
      countryName: filters.country || "",
      stateName: filters.state || "",
      cityName: filters.city || "",
    };
  }

  // Top Teachers Data
  // getTopTeachers(): Observable<any> {
  //   const payload = this.createTopTeachersPayload();
  //   return this._apiCall
  //     .PostCallWithoutToken(payload, "AdmissionLeloUsers/GetTeachers")
  //     .pipe(
  //       map((response) => {
  //         // console.log("Teachers Data", response);
  //         return response.data.map((teachers: any) => ({
  //           id: teachers.id,
  //           img: teachers.profileBase64,
  //           property: 5,
  //           title: teachers.firstName + " " + teachers.lastName,
  //           subTitle:
  //             teachers.country + ", " + teachers.state + ", " + teachers.city,
  //           mobile: teachers.phoneNumber,
  //           email: teachers.email,
  //           fax: teachers.subjects[0].subject,
  //         }));
  //       })
  //     );
  // }

  // createTopTeachersPayload() {
  //   return {
  //     getname: "Top",
  //   };
  // }

  // Send Resuest
  sendRequest(payload: {
    userName: string;
    userMail: string;
    userNumber: string;
    userMesg: string;
  }): Observable<any> {
    return this._apiCall.PostCallWithoutToken(payload, "Recepient");
  }

  // FAQ Data
  faqData(): Observable<faqData> {
    return this.http.get<faqData>("assets/data/faq.json");
  }

  // Terms Condition Data
  termsConditionData(): Observable<termsData> {
    return this.http.get<termsData>("assets/data/terms-condition.json");
  }

  // Privacy Policy Data
  privacyPolicyData(): Observable<privacyData> {
    return this.http.get<privacyData>("assets/data/privacy-policy.json");
  }

  // Agents Details Data
  agentsDetailsData(): Observable<agentsDetails> {
    return this.http.get<agentsDetails>("assets/data/agents-data.json");
  }

  // Slider Data For Image Content Page
  public getSliderData(): Observable<sliderData> {
    return this.http.get<sliderData>("assets/data/slider.json");
  }

  // Filter Property
  public filterData: latestForRent;
  public filterPropertyData(filter: any): Observable<any> {
    return this.latestForRentData().pipe(
      map((property) =>
        property.latestForRent.filter((item: latestForRent) => {
          if (!filter.length) {
            if (item.params) {
              return true;
            }
          }
          this.filterData = filter.find((prev: string) => {
            if (item.params) {
              if (item.params.includes(prev)) {
                return prev;
              }
            }
          });
          return this.filterData;
        })
      )
    );
  }

  // For Get Images By ID
  public imageData: latestForSale[];
  public propertyDetail: latestForRent[];
  public getImage(id: any): Observable<any> {
    if (id && id.includes("b")) {
      return this.propertyData().pipe(
        map(
          (property) =>
          (this.imageData = property.latestForSale.filter((data) => {
            return data.id == id;
          }))
        )
      );
    } else {
      return this.latestForRentData().pipe(
        map(
          (property) =>
          (this.propertyDetail = property.latestForRent.filter((data) => {
            return data.id == id;
          }))
        )
      );
    }
  }

  // Get Property Details
  public propertyDetails: latestForRent[];
  public getPropertyDetail(id: number): Observable<latestForRent[]> {
    return this.latestForRentData().pipe(
      map(
        (property) =>
        (this.propertyDetails = property.latestForRent.filter((data) => {
          return data.id == id;
        }))
      )
    );
  }

  // Pagination
  public getPager(
    totalItems: number,
    currentPage: number = 1,
    pageSize: number = 6
  ) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // Paginate Range
    let paginateRange = 3;

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage < paginateRange - 1) {
      startPage = 1;
      endPage = startPage + paginateRange - 1;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      (i) => startPage + i
    );

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }
}
