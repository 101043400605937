import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TutorialsRoutingModule } from './tutorials-routing.module';
import { TutorialListComponent } from './tutorial-list/tutorial-list.component';


@NgModule({
  declarations: [
    TutorialListComponent
  ],
  imports: [
    CommonModule,
    TutorialsRoutingModule
  ]
})
export class TutorialsModule { }
