<app-loader-two [isLoading]="isLoading"></app-loader-two>
<app-header-one></app-header-one>
<app-corporate-home-slider></app-corporate-home-slider>
<app-top-institute></app-top-institute>
<app-top-teacher></app-top-teacher>
<app-latest-admissions></app-latest-admissions>
<app-latest-scholar-ships></app-latest-scholar-ships>
<app-latest-jobs></app-latest-jobs>
<books></books>
<app-technology-partner></app-technology-partner>
<app-contact-us [isOnContactUsPage]="false"></app-contact-us>
<app-slider-filter-happy-client></app-slider-filter-happy-client>
<app-classic-banner></app-classic-banner>
<app-faqs [isOnFaqPage]="false"></app-faqs>
<app-adly-footer></app-adly-footer>