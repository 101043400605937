<div class="mainContainer">
    <div class="headers">
      <h6>Skills</h6>
      <div class="addHeadings" (click)="addCard(addSkill)">
        <app-feather-icons class="color" [icon]="'plus'"></app-feather-icons>
  
        <span> Skill </span>
      </div>
    </div>
    @defer {
    <div class="tagsContainer">
      @if(isLoading){
      <div class="item">
        <ngx-skeleton-loader count="6" [theme]="{
            height: '40px',
            width: '150px',
            'border-radius': '10px',
            'margin-right': '1rem'
          }" />
      </div>
      } @else if(skillsDetails?.length > 0){ @for(skill of skillsDetails;track
      skill){
      <div class="tag">
        <div class="tagContent">
          {{ skill?.skillTitle + " - " + skill?.percentage + "%" }}
          <span class="icons">
            <i class="fas fa-edit edit-icon" (click)="editCard(addSkill, skill)"></i>
            <i class="fas fa-trash delete-icon" (click)="deleteCard(deleteModal, skill?.id)"></i>
          </span>
        </div>
      </div>
      } }
    </div>
    }
  </div>
  
  <ng-template #addSkill>
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          @if(FormName == "Add"){ Add Skill }@else{ Update Skill }
        </h5>
        <button type="button" class="btn-close" (click)="modal.dismissAll()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="row gx-2 gx-sm-3" [formGroup]="skillForm" (ngSubmit)="saveSkillDetails()">
          <div class="form-group col-sm-6">
            <label for="skillTitle">Skill Title<span class="text-danger">*</span></label>
            <input type="text" class="form-control" id="skillTitle" formControlName="skillTitle"
              placeholder="Enter skill title" />
            @if(skillForm.get('skillTitle')?.hasError('required') &&
            skillForm.get('skillTitle')?.touched){
            <small class="text-danger"> Skill Title is Required</small>
            }
          </div>
  
          <div class="form-group col-sm-6">
            <label for="percentage">Percentage<span class="text-danger">*</span></label>
            <input type="number" class="form-control" id="percentage" formControlName="percentage"
              placeholder="Enter percentage" min="0" max="100" step="0.01" />
            @if(skillForm.get('percentage')?.hasError('required') &&
            skillForm.get('percentage')?.touched){
            <small class="text-danger"> Percentage is Required</small>
            } @if(skillForm.get('percentage')?.hasError('min') &&
            skillForm.get('percentage')?.touched){
            <small class="text-danger"> Percentage must be at least 0</small>
            } @if(skillForm.get('percentage')?.hasError('max') &&
            skillForm.get('percentage')?.touched){
            <small class="text-danger"> Percentage cannot be more than 100</small>
            }
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-dashed color-2 btn-pill" (click)="modal.dismissAll()" aria-label="Cancel">
          Cancel
        </button>
        <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="saveSkillDetails()" aria-label="Save">
          @if(FormName == "Add"){ Add }@else{ Update }
        </button>
      </div>
    </div>
  </ng-template>
  
  <ng-template #deleteModal>
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close" (click)="modal.dismissAll()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="text">Are You Sure you want to delete this skill ?</div>
        <div class="deleteButtons">
          <button type="button" class="btn btn-dashed color-2 btn-pill" (click)="modal.dismissAll()" aria-label="Close">
            Cancel
          </button>
          <button type="button" class="btn btn-gradient color-2 btn-pill" (click)="deleteSkill()" aria-label="Delete">
            Delete
          </button>
        </div>
      </div>
    </div>
  </ng-template>