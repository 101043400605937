import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy, OnInit } from "@angular/core";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { Meta, Title } from "@angular/platform-browser";
import { ApiCallService } from "src/app/shared/services/api-call.service";
import { CommonUtilsServiceService } from "src/app/shared/services/common-utils-service.service";
import { LibraryService } from "../../library.service";
import { PropertyService } from "src/app/shared/services/property.service";
import { CookieService } from "ngx-cookie-service";
import { Subject, takeUntil } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { ErrorHandlingService } from "src/app/shared/services/error-handling.service";
import { HttpErrorResponse } from "@angular/common/http";
import { bookCategory } from "../../library";

@Component({
  selector: "books-categories",
  templateUrl: "./categories.component.html",
  styleUrl: "./categories.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BooksCategoriesComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  public theme = {
    width: "27.5rem",
    height: "30rem",
    "border-radius": "10px",
  };
  bannerImage: string = "assets/images/banner/library.webp";

  // Injecting Services
  private readonly apiService = inject(ApiCallService);
  private readonly utilsService = inject(CommonUtilsServiceService);
  private readonly dataShare = inject(LibraryService);
  private readonly propertyService = inject(PropertyService);
  private readonly cookies = inject(CookieService);
  private readonly spinner = inject(NgxSpinnerService);
  private readonly errorHandlingService = inject(ErrorHandlingService);

  receivedData: any;
  isDataLoading: boolean = true;
  noRecordsFound: boolean = false;
  payloadData: any;
  isContentShown: boolean = true;
  public isSpinnerShown = false;

  currentPage: number = 1;
  totalRecords: number = 0;
  itemsPerPage: number = 9;
  maxVisiblePages: number = 5;
  searchValue: string = '';

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.setPageTitleAndMetaTags();
    this.getAllCategories();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  handleChildEvent(data: any) {
    this.receivedData = data;
    this.payloadData = {
      searchText: data?.search,
      bookCategory: data?.category,
      language: data?.language,
    };
    if (this.payloadData.searchText !== "") {
      this.searchValue = this.payloadData?.searchText;
      this.getAllCategories();
    }
  }

  handleSearchData(event: any) {
    if (event === "") {
      this.searchValue = "";
      this.receivedData.search = "";
      this.getAllCategories();
    }
  }

  setPageTitleAndMetaTags() {
    this.titleService.setTitle("Library List | Admission-Lylo ");
    this.metaService.addTags([
      { name: "description", content: "This is the Job Ads of my Angular app, providing an overview of our services." },
      { name: "keywords", content: "Angular, SEO, Home Page" },
      { name: "robots", content: "index, follow" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { name: "author", content: "John Doe" },
      { property: "og:title", content: "Home - My Angular App" },
      { property: "og:description", content: "This is the home page of my Angular app, providing an overview of our services." },
      { property: "og:image", content: "https://example.com/image.jpg" },
      { property: "og:url", content: "https://example.com/home" },
    ]);
  }

  pages: (number | string)[] = [];
  displayedItems: any[] = [];

  onPageChanged(page: number): void {
    this.currentPage = page;
    this.getAllCategories();
    this.scrollToContentStart();
  }

  getDisplayRange(): string {
    const start = (this.currentPage - 1) * this.itemsPerPage + 1;
    const end = Math.min(start + this.itemsPerPage - 1, this.totalRecords);
    return `${start} – ${end}`;
  }

  scrollToContentStart(): void {
    const contentStart = document.getElementById("content-start");
    if (contentStart) {
      const topOffset = contentStart.getBoundingClientRect().top + window.pageYOffset - 95;
      window.scrollTo({ top: topOffset, behavior: 'smooth' });
    }
  }

  resetPagination(): void {
    this.currentPage = 1;
    this.getAllCategories();
    this.scrollToContentStart();
  }

  bookCategory: string = "";
  showBooksByCategory(bookCategory: any) {
    this.cookies.set("bookCategory", bookCategory);
    this.utilsService.navigateTo("books/books-by-category");
  }

  booksData: bookCategory[] = [];
  isErrorOccurred: boolean = false;
  showPaginatation: boolean = true;

  private async getAllCategories(): Promise<void> {
    this.isSpinnerShown = true;
    this.spinner.show();
    this.isDataLoading = true;
    this.payloadData = {
      pageNumber: this.currentPage,
      pageSize: this.itemsPerPage,
      searchText: this.searchValue,
    };
    try {
      const response = await this.apiService
        .PostCallWithToken(this.payloadData, "Book/GetBookCategory")
        .pipe(takeUntil(this.destroy$))
        .toPromise();

      if (response.responseCode === 200) {
        this.booksData = response?.data;

        this.isDataLoading = false;
        if (!this.booksData || this.booksData.length <= 0) {
          this.noRecordsFound = true;
          this.totalRecords = 0;
        } else {
          this.noRecordsFound = false;
          this.totalRecords = this.booksData[0].totalRecords;
        }

        // Convert base64 to Blob URL for each book image
        this.booksData.forEach((book: any) => {
          if (book.imageBase64 && book.imageBase64.includes('base64,')) {
            const base64Data = book.imageBase64.split('base64,')[1];
            book.imageBase64 = this.utilsService.convertBase64ToBlobUrl(base64Data, 'image/jpeg');
            // console.log("book category Blob URL:", book.imageBase64);
          } else {
            book.imageBase64 = 'path/to/default/image.png'; // Fallback image if base64 data not present
          }
        });

        this.showPaginatation = !this.noRecordsFound;
      } else {
        this.errorHandlingService.handleResponseError(response);
        this.resetDataState();
      }
    } catch (error) {
      this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
      this.resetDataState();
    } finally {
      this.isSpinnerShown = false;
      this.spinner.hide();
      this.cdr.detectChanges();
    }
  }

  private resetDataState(): void {
    this.isDataLoading = false;
    this.isErrorOccurred = true;
    this.noRecordsFound = true;
    this.showPaginatation = false;
    this.booksData = [];
  }

  getSearchBooks() {
    this.apiService
      .PostCallWithToken(this.payloadData, "Book/GetBookCategory")
      .subscribe((response) => {
        if (response.responseCode === 200) {
          this.booksData = response?.data;

          // Convert base64 to Blob URL for each book image
          this.booksData.forEach((book: any) => {
            if (book.imageBase64 && book.imageBase64.includes('base64,')) {
              const base64Data = book.imageBase64.split('base64,')[1];
              book.imageBase64 = this.utilsService.convertBase64ToBlobUrl(base64Data, 'image/jpeg');
              // console.log("Book Category Blob URL:", book.imageBase64);
            } else {
              book.imageBase64 = 'path/to/default/image.png'; // Fallback image if base64 data not present
            }
          });

          this.totalRecords = this.booksData[0]?.totalRecords || 0;
          this.isDataLoading = false;
        }
      });
  }

  private createApiPayload() {
    return {
      pageNumber: this.currentPage,
      pageSize: this.itemsPerPage,
    };
  }

  onReset() {
    this.getAllCategories();
  }
}
