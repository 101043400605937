import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CvBuilderRoutingModule } from './cv-builder-routing.module';
import { CvTemplatesComponent } from './cv-templates/cv-templates.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { HomeModule } from '../home/home.module';
import { Cv1Component } from './cv-designs/cv1/cv1.component';
import { Cv2Component } from './cv-designs/cv2/cv2.component';
import { Cv3Component } from './cv-designs/cv3/cv3.component';
import { Cv4Component } from './cv-designs/cv4/cv4.component';
import { Cv5Component } from './cv-designs/cv5/cv5.component';
import { Cv6Component } from './cv-designs/cv6/cv6.component';
import { CvEditsComponent } from './cv-edits/cv-edits.component';
import { CvDataComponent } from './cv-data/cv-data.component';
import { CvDataStepsComponent } from './widgets/cv-data-steps/cv-data-steps.component';
import { CvProfileDetailsComponent } from './widgets/cv-profile-details/cv-profile-details.component';
import { CvLanguageDetailsComponent } from './widgets/cv-language-details/cv-language-details.component';
import { CvInterestDetailsComponent } from './widgets/cv-interest-details/cv-interest-details.component';
import { CvSkillDetailsComponent } from './widgets/cv-skill-details/cv-skill-details.component';
import { CvEducationDetailsComponent } from './widgets/cv-education-details/cv-education-details.component';
import { CvExperienceDetailsComponent } from './widgets/cv-experience-details/cv-experience-details.component';
import { CvCertificateDetailsComponent } from './widgets/cv-certificate-details/cv-certificate-details.component';
import { CvAchievementDetailsComponent } from './widgets/cv-achievement-details/cv-achievement-details.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxSpinnerModule } from 'ngx-spinner';


@NgModule({
  declarations: [
    CvTemplatesComponent,
    Cv1Component,
    Cv2Component,
    Cv3Component,
    Cv4Component,
    Cv5Component,
    Cv6Component,
    CvEditsComponent,
    CvDataComponent,
    CvDataStepsComponent,
    CvProfileDetailsComponent,
    CvLanguageDetailsComponent,
    CvInterestDetailsComponent,
    CvSkillDetailsComponent,
    CvEducationDetailsComponent,
    CvExperienceDetailsComponent,
    CvCertificateDetailsComponent,
    CvAchievementDetailsComponent
  ],
  imports: [
    CommonModule,
    CvBuilderRoutingModule,
    SharedModule,
    HomeModule,
    ReactiveFormsModule,
    NgbModule,
    CarouselModule,
    NgxSkeletonLoaderModule.forRoot(),
    NgxSpinnerModule.forRoot({ type: 'ball-spin-clockwise' }),
  ],

})
export class CvBuilderModule { }
