import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CvDataComponent } from '../cv-data/cv-data.component';

@Component({
  selector: 'app-cv-templates',
  templateUrl: './cv-templates.component.html',
  styleUrl: './cv-templates.component.scss'
})
export class CvTemplatesComponent {
  bannerImage: string = 'assets/images/banner/all-institute-banner.webp';
  public noRecordFound = false;
  public isDataLoading = true;
  isErrorOccurred: boolean = false;
  public isSpinnerShown = false;
  gridType: string = 'grid-3';
  
  @Input() tagClass: string;
  @Input() sectionClass: string;
  public tag: string = 'CV'
  templates = [
    {
      templateId: 1,
      image_url: "../../../../../../assets/images/cv-images/cv1.png",
      isPaid: true

    },
    {
      templateId: 2,
      image_url: "../../../../../../../assets/images/cv-images/cv2.png",
      isPaid: false
    },
    {
      templateId: 3,
      image_url: "../../../../../../../assets/images/cv-images/cv3.png",
      isPaid: true
    },
    {
      templateId: 4,
      image_url: "../../../../../../../assets/images/cv-images/pic2.jpg",
      isPaid: true
    },
    {
      templateId: 5,
      image_url: "../../../../../../../assets/images/cv-images/pic3.jpg",
      isPaid: false
    },

  ]

  constructor(private sanitizer: DomSanitizer, private router: Router) { }
  sanitizeImageUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  useTemplate() {
    this.router.navigate(['cv-data']);
  }






}

