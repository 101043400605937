import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const toastrService = inject(ToastrService);
  const router = inject(Router);

  const authHeader = req.headers.get('Authorization');

  // Function to recursively trim string values in an object
  const trimObjectValues = (obj: any): any => {

    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map(trimObjectValues);
    }

    return Object.keys(obj).reduce((acc, key) => {
      const value = obj[key];
      acc[key] = typeof value === 'string' ? value.trim() : trimObjectValues(value);
      return acc;
    }, {} as any);
  };

  // Clone the request and trim its body's string values
  if (req.body) {
    const trimmedBody = trimObjectValues(req.body);
    req = req.clone({ body: trimmedBody });
    // console.log('New request body:', JSON.stringify(trimmedBody, null, 2));
  }

  // The modified request with trimmed body will be sent to the API


  if (authHeader && authHeader !== 'Bearer null') {
    const token = authHeader.split(' ')[1];

    if (authService.isTokenExpired(token)) {

      authService.logout();
      toastrService.error('Your session has expired. Please log in again.');
      router.navigate(['/']);
      return throwError(() => new Error('Token expired'));
    }
  }

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      const logData = {
        method: req.method,
        url: req.url,
        status: error.status,
        statusText: error.statusText,
        message: error.message,
        timestamp: new Date().toISOString()
      };

      console.error('HTTP Interceptor Error:', logData);

      switch (error.status) {
        case 401:
          authService.logout();
          toastrService.error('Your session has expired. Please log in again.');
          router.navigate(['/login']);
          return throwError(() => new Error('Unauthorized access'));
        case 403:
          toastrService.error('Access forbidden. You do not have permission to perform this action.');
          break;
        case 404:
          toastrService.error('Resource not found.');
          break;
        case 500:
          toastrService.error('An unexpected server error occurred. Please try again later.');
          break;
        default:
          toastrService.error('An error occurred. Please try again.');
      }
      return throwError(() => error);
    })
  );
};
