import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, inject, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { ApiCallService } from 'src/app/shared/services/api-call.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';

@Component({
  selector: 'app-cv-achievement-details',
  templateUrl: './cv-achievement-details.component.html',
  styleUrl: './cv-achievement-details.component.scss'
})
export class CvAchievementDetailsComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  AwardsForm: FormGroup;
  public FormName: string = "Add";
  isLoading : boolean = true;
  AwardsDetails: any = [
    //   {
    //   position: "Frontend Developer",
    //   company: "ATG SYSTEM",
    //   startDate: "2005-07-21T00:00:00",
    //   endDate: "2005-07-21T00:00:00",
    //   description: "As a frontend developer"
    // },{
    //   position: "backend Developer",
    //   company: "ATG SYSTEM",
    //   startDate: "2005-07-21T00:00:00",
    //   endDate: "2005-07-21T00:00:00",
    //   description: "As a frontend developer"
    // }
  ];
  delId: number;
  editId: number = 0;

  private readonly _apiCall = inject(ApiCallService);
  private readonly _toaster = inject(ToastrService);
  private readonly _spinner = inject(NgxSpinnerService);
  private readonly errorHandlingService = inject(ErrorHandlingService);

  constructor(private fb: FormBuilder, public modal: NgbModal,private cdr: ChangeDetectorRef) {
    this.AwardsForm = this.fb.group({
      position: ['', Validators.required],
      company: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      description: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.getAwardsDetails();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  addCard(content: TemplateRef<any>) {
    this.AwardsForm.reset();
    this.isPresent = false;
    if(!this.isPresent){
      this.AwardsForm.get('endDate')?.enable();
    }
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Add";
  }

  editCard(content: TemplateRef<any>, tag: any) {
    this.AwardsForm.reset();
    this.isPresent = false;
    this.modal.open(content, { centered: true, size: 'lg' });
    this.FormName = "Edit";
    this.editId = tag?.id;
    this.AwardsForm.get("position")?.setValue(tag?.position);
    this.AwardsForm.get("company")?.setValue(tag?.company);
    this.AwardsForm.get("startDate")?.setValue(tag?.startDate?.substring(0, 10));
    this.AwardsForm.get("endDate")?.setValue(tag?.endDate?.substring(0, 10));
    this.AwardsForm.get("description")?.setValue(tag?.description);
    this.isPresent = tag?.isPresent;
    if(this.isPresent){
      this.AwardsForm.get('endDate')?.disable();
    }else{
      this.AwardsForm.get('endDate')?.enable();
    }

  }

  deleteCard(content: TemplateRef<any>, id: number) {
    this.modal.open(content, { centered: true, size: 'sm' });
    this.delId = id;
  }


  checkAwardsExsists(): boolean {
    const payload = this.createApiPayload();
    if (this.AwardsDetails?.length > 0) {
      if (this.FormName == "Add") {
        const isExsist = this.AwardsDetails.some((tag: any) => tag.position == payload.position);
        if (isExsist) {
          this._toaster.error("This Awards Already Exist");
          return true;
        }
      } else {
        let newAwards = this.AwardsDetails;
        console.log("newexp before", newAwards)
        newAwards = newAwards.filter((item: any) => item.id != this.editId);
        console.log("newexp after", newAwards)
        const isExsist = newAwards.some((tag: any) => tag.position == payload.position && tag.id != this.editId);
        if (isExsist) {
          this._toaster.error("This Awards Already Exist");
          return true;
        }
      }

      return false;
    }
    return false;
  }
  public async saveAwardsDetails(): Promise<void> {
    this.AwardsForm.markAllAsTouched();
    const isAlreadyExsists = this.checkAwardsExsists();
    if (!isAlreadyExsists) {
      const payload = this.createApiPayload();
      if (this.AwardsForm.invalid) {
        this._toaster.error("Invalid Form");
      }
      else {
        this._spinner.show();
        try {
          const response = await this._apiCall.PostCallWithToken(payload, 'AdmissionLeloUsers/SaveTeacherAwards').pipe(takeUntil(this.destroy$))
          .toPromise();
            if (response.responseCode == 200) {
              this._toaster.success(response.responseMessage);
              this.editId = 0;
              this._spinner.hide();
              this.AwardsForm.reset();
              this.modal.dismissAll();
              this.getAwardsDetails();
            } else {
              this.errorHandlingService.handleResponseError(response);
            }
          }
          catch (error) {
            this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
          } finally {
            this._spinner.hide();
            this.cdr.detectChanges();
          }


      }
    }
  }

  // Function to create the payload
  createApiPayload() {
    const date = new Date().toJSON();
    this.editId = this.FormName == "Add" ? 0 : this.editId
    return {
      id: this.editId,
      position: this.AwardsForm.get('position')?.value,
      company: this.AwardsForm.get('company')?.value,
      startDate: this.AwardsForm.get('startDate')?.value || date,
      endDate: this.AwardsForm.get('endDate')?.value || date,
      description: this.AwardsForm.get('description')?.value,
      IsPresent : this.isPresent
    };
  }



  public async deleteAwards(): Promise<void> {
    this._spinner.show();
    try {
      const res = await this._apiCall.PostCallWithToken(null, `AdmissionLeloUsers/DeleteTeacherAward?AwardId=${this.delId}`).pipe(takeUntil(this.destroy$))
      .toPromise();
      if (res.responseCode == 200) {
        this._toaster.success(res.responseMessage);
        this.getAwardsDetails();
        this._spinner.hide();
        this.modal.dismissAll();
      } else {
        this.errorHandlingService.handleResponseError(res);
      }
    }catch (error) {
      this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
    } finally {
      this._spinner.hide();
      this.cdr.detectChanges();
    }
  }



  // FUNCTION TO GET Awards DETAILS

  public async getAwardsDetails(): Promise<void> {
    this.isLoading = true;
    try {
      const response = await this._apiCall.GetCallWithToken("AdmissionLeloUsers/GetTeacherAwards").pipe(takeUntil(this.destroy$))
      .toPromise();
      if (response.responseCode == 200) {
        this.AwardsDetails = response.data;
      } else {
        this.AwardsDetails = [];
        this.errorHandlingService.handleResponseError(response);
      }
    }catch (error) {
      this.errorHandlingService.handleHttpError(error as HttpErrorResponse);
    } finally {
      this._spinner.hide();
      this.isLoading = false;

      this.cdr.detectChanges();
    }
  }



  /////////////////////////////////////////////////////////////////
  //////// Functions to handle dates //////////////////////////////
  ///////////////////////////////////////////////////////////////

  getMaxDate(): string {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    return formattedDate;
  }

  minmumDate: string;

  getMinDate() {
    const startDate = this.AwardsForm.get('startDate')?.value;
    this.AwardsForm.get('endDate')?.reset();
    this.minmumDate = startDate;
  }



  // PRESENT BUTTON LOGISTICS
  isPresent: boolean = false;
  getCheckboxValue() {
    console.log(this.isPresent);
    if (this.isPresent) {
      this.AwardsForm.get('endDate')?.disable();
    } else {
      this.AwardsForm.get('endDate')?.enable();
    }
  }

}
